import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UIStateService {
	private isMenuCollapsedSubject = new BehaviorSubject<boolean>(true);
	isMenuCollapsed = this.isMenuCollapsedSubject.asObservable();
	constructor() {}
	updateMenuCollapsed(isCollapsed: any) {
		this.isMenuCollapsedSubject.next(isCollapsed);
	}
}
