import { Injectable } from '@angular/core';
import swal from 'sweetalert2';

@Injectable({
	providedIn: 'root'
})
export class SweetAlertService {
	constructor() {}

	sweetWarningMessage(message: string) {
		swal({
			text: message,
			type: 'warning',
			showCancelButton: false,
			showConfirmButton: false
		});
	}
}
