import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot
} from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthService } from '../services/auth.service';
import { UserRolesEnum } from '@core/models/userRoles.enum';
import { AppRoutesEnum } from '@core/models/appRoutes.enum';
import { EddressFacadeService } from '@core/services/eddressFacade.service';

@Injectable()
export class DashboardRedirectGuard implements CanActivate {
	isAuthenticated = false;

	constructor(
		private router: Router,
		private authService: AuthService,
		private permissionsService: NgxPermissionsService,
		public eddress: EddressFacadeService
	) {}

	public canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		this.authService.isAuthenticated.subscribe(
			(data) => (this.isAuthenticated = data)
		);
		if (this.isAuthenticated) {
			this.permissionsService.permissions$.subscribe((per) => {
				const redirectUrl = this.eddress.utilityService.getRedirectUrl(per);
				if (redirectUrl) {
					this.router.navigateByUrl(redirectUrl);
				}
			});
		}
		return true;
	}
}
