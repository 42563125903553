import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { Observable } from 'rxjs/Observable';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable()
export class AuthenticateURLGaurd implements CanActivate {
	isAuthenticated = false;

	constructor(
		private router: Router,
		private authService: AuthService,
		private permissionsService: NgxPermissionsService
	) {}

	public canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		return this._hasAuthenticatedUser(route.children[0].data.role);
	}

	private _hasAuthenticatedUser(role): Observable<boolean> {
		this.permissionsService.permissions$.subscribe((per) => {
			if (!per.hasOwnProperty(role)) {
				this.authService.isAuthenticatedSubject.next(false);
			} else {
				this.authService.isAuthenticatedSubject.next(true);
			}
		});

		return this.authService.isAuthenticated;
	}
}
