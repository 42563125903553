import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import * as _ from "lodash";
export interface ICommonProperties<T> {
  id : T
  orderUid : T
  length : number
}

export interface StateManagement<T> {
  list: T[];
  recordsPerPage: number;
  totalPages: number;
  totalRecords: number;
  isLoaded: Boolean;
  pageNumber: number;
  headers ? : T[];
  other?: any;
}

export interface FilterState {
  searchString: string;
  createdOnAfter: string;
  createdOnBefore: string;
  assignedOnAfter: string;
  assignedOnBefore: string;
  createdAfter: string;
  createdBefore: string;
  completedAfter?: string;
  completedBefore?: string;
  scheduledAfter?: string;
  scheduledBefore?: string;
  dispatcher?: string;
  promoType?: string;
  promoCode?: string
  worker?: string;
  orderSequenceNumber?: string
  taskType?: string
  app: boolean;
  customer?: string;
  hasDriverTips?: boolean
  recordsPerPage: number;
  statusIn: string,
  merchantUid? : string
  serviceUid?: string
  entityType?: string;
  questionnaireIds?: string[];
  questionnaireType?: string;
  storeIds?: string[];
  workerUids?: string
  branchIds?: string[];
}

@Injectable({
  providedIn: 'root'
})
export abstract class GenericState <T> {
  // public _data: Store<StateManagement<T>> = new Store<StateManagement<T>>(this.initialState())
  // public _filterState: Store<FilterState> = new Store<FilterState>(this.initialFilterState())
  public _data : BehaviorSubject<StateManagement<T>> = <
    BehaviorSubject<StateManagement<T>>
    >new BehaviorSubject(this.initialState());
  public _filterState : BehaviorSubject<FilterState> = <
    BehaviorSubject<FilterState>
    >new BehaviorSubject(this.initialFilterState());


  constructor(){}

  public initialState(){
    return {
        list:  [],
        recordsPerPage: 0,
        totalPages: 0,
        totalRecords: 0,
        isLoaded: false,
        pageNumber: 1
    }
  }

  public initialFilterState(){
    return {
      searchString: '',
      createdOnAfter: '',
      createdOnBefore: '',
      createdAfter: '',
      createdBefore: '',
      completedAfter: '',
      completedBefore: '',
      scheduledAfter: '',
      scheduledBefore: '',
      dispatcher: null,
      promoType: null,
      promoCode: null,
      customer: '',
      statusIn: '',
      serviceUid: null,
      merchantUid: null,
      hasDriverTips: null,
      entityType: null,
      questionnaireIds: [],
      questionnaireType: null,
      storeIds: [],
      workerUids: null,
      branchIds: []

    }
  }

  // public loadPostData(stateValue, key: string, URL, params?): void {
  //    let state: StateManagement<T> = stateValue;
  //
  //   if (state.isLoaded) {
  //     return;
  //   }
  //   this.genericService
  //   	.getPostData(URL, params)
  //   	.pipe(
  //   		map((data) => {
  //   			state = {
  //   				...state,
  //   				list: data.items,
  //   				recordsPerPage: data.recordsPerPage,
  //   				totalPages: data.totalPages,
  //   				totalRecords: data.totalRecords,
  //   				pageNumber: params.pageNumber,
  //   				isLoaded: true
  //   			};
  //         state.list = Object.values(state.list);
  //         state.list.sort((o2, o1) => o2[key] - o1[key]);
  //   		}),
  //   		tap(() => {
  //   		  this.setState(state)
  //   		})
  //   	)
  //   	.subscribe();
  // }
  // public loadGetData(stateValue, key: string, URL, params?): void {
  //   let state: StateManagement<T> = stateValue;
  //
  //   if (state.isLoaded) {
  //     return;
  //   }
  //   this.genericService
  //     .getData(URL, params)
  //     .pipe(
  //       map((data) => {
  //         state = {
  //           ...state,
  //           list: data.items,
  //           recordsPerPage: data.recordsPerPage,
  //           totalPages: data.totalPages,
  //           totalRecords: data.totalRecords,
  //           pageNumber: params.pageNumber,
  //           isLoaded: true
  //         };
  //         state.list = Object.values(state.list);
  //         state.list.sort((o2, o1) => o2[key] - o1[key]);
  //       }),
  //       tap(() => {
  //         this.setState(state)
  //       })
  //     )
  //     .subscribe();
  // }
  public updateState<T extends ICommonProperties<T>>(stateValue, datas: T, key : string, type: boolean) {
    const state: StateManagement<T> = stateValue;
    if (state.pageNumber === 1) {
      for (let i = 0; i < datas.length; i++) {
        const data = datas[i];
        const isOrder = state.list.find((val) => val.id === data.id);

        if (isOrder) {
          this.removeFromState(state, data);
          this.addToState(data, key, type);
        } else {
          this.addToState(data, key, type);
          if (state.list.length === state.recordsPerPage) {
            this.removeFromStateBottom();
          }
        }

      }
    } else {
      for (let i = 0; i < datas.length; i++) {
        const data = datas[i];
        const isOrder = state.list.find((val) => val.id === data.id);
        if (isOrder) {
          this.removeFromState(state, data);
          this.addToState(data, key, type);
        }
      }
    }
  }

  public IsLoadedState(value): void {
    const state: StateManagement<T> = value;
    state.isLoaded = false;
    this.setState(state);
  }

  public addToState(data: T, key : string, type: boolean) {
    const state: StateManagement<T> = this.getValue();
    state.list = [...state.list, data];
    state.list = Object.values(state.list);
    // state.list = state.list.sort((o2, o1) => o2[key] - o1[key]);
    const list: any = _.orderBy(state.list, o => o[key], type)
    state.list = list;
    state.isLoaded = true;
    if (state.pageNumber === 1) {
      this.setState(state);
    }
  }

  public removeFromState<T extends ICommonProperties<T> >(stateValue, data: T) {
    const state: StateManagement<T> = stateValue;
    if (state.list) {
      const index = state.list.findIndex((val) => val.id === data.id);
      if (index !== -1) {
        state.list = [
          ...state.list.slice(0, index),
          ...state.list.slice(index + 1)
        ];
        this.setState(state)
        // this.updateRemoveFromState(state)
      }
    }
  }

  public removeExistingData<T extends ICommonProperties<T>>(stateValue, ids : T) {
    const state: StateManagement<T> = stateValue;
    const datas = state.list.filter((data) => {
      return ids === data.id;
    } );
    datas.forEach((data) => {
      this.removeFromState(state, data);
    });
  }

  public removeFromStateBottom() {
    const state: StateManagement<T> = this.getValue();
    if (state.list) {
      state.list = [
        ...state.list.slice(0, state.list.length - 1),
        ...state.list.slice(state.list.length - 1 + 1)
      ];
      this.setState(state);
    }
  }

  private updateRemoveFromState(value) {
    this.setState(value);
  }

  public setState(state) {
    this.dispatch(state);
  }
  public setFilterState(state) {
    this.dispatchFilter(state);
  }

  public getValue(){
    return this._data.getValue()
  }

  public resetState(){
    this.reset(this.initialState())
  }

  public resetFilterState() {
    this.resetFilter(_.cloneDeep(this.initialFilterState()));
  }

  public getState(): Observable<StateManagement<T>> {
    return this._data.asObservable();
  }

  public getFilterState(): Observable<FilterState>  {
    return this._filterState.asObservable();
  }
  private dispatch(state) {
    this._data.next(state);
  }
  private dispatchFilter(state) {
    this._filterState.next(state);
  }
  public reset(initialState) {
    this.setState(initialState);
  }
  public resetFilter(initialState) {
    this.setFilterState(initialState);
  }
}
