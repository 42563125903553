// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	PUSHER_API_KEY: '6d18614f3162d08e0fdd',
	PUSHER_API_CLUSTER: 'eu',
	eddressAppConfig: {
		apiBaseUrl: 'https://eddress-staging-api.eddress.co/api/',
		graphqlBaseUrl: 'https://eddress-staging-api.eddress.co/graphql/',
		mainPortalUrl: 'https://portal-dev.eddress.co/portal#!/',
		portalUrl: 'https://dash-dev.eddress.co/#/',

		// apiBaseUrl: 'http://localhost:8080/api/',
		// graphqlBaseUrl: 'http://localhost:8080/graphql/',
		// mainPortalUrl: 'http://localhost:8080/portal#!/'
	},
  recaptcha: {
    siteKey: '6LdZVr8pAAAAALYBGN63Om14g-1p47PM5dglj4z7',
  },
};
