import { AppRoutesEnum } from '@core/models/appRoutes.enum';

export enum UserRolesEnumExist {
	CUSTOMER_BILLING = 'CUSTOMER_BILLING',
	AGENT_BILLING = 'AGENT_BILLING',
	LAST_MILE = 'LAST_MILE',
	LAST_MILE_TEAM = 'LAST_MILE_TEAM',
	MARKETPLACE = 'MARKETPLACE',
  MARKETPLACE_API = 'MARKETPLACE_API',
	USER_LIST = 'USER_LIST',
	ROLE_LIST = 'ROLE_LIST',
	CLIENT_VIEW_ORDER = 'CLIENT_VIEW_ORDER',
	CLIENT_EXPRESS_VIEW = 'CLIENT_EXPRESS_VIEW',
  CLIENT_VIEW_EXPRESS = 'CLIENT_VIEW_EXPRESS',
  CLIENT_VIEW = 'CLIENT_VIEW',
	DISPATCHER = 'DISPATCHER',
	INTERIM = 'INTERIM',
	ORDER_LIST = 'ORDER_LIST',
  MAP_VIEW_ORDERS = 'MAP_VIEW_ORDERS',
	REFERRALS_VIEW = 'REFERRALS_VIEW',
	MERCHANT = 'MERCHANT',
  COURIER = 'COURIER',
  COURIER_EXPRESS = 'COURIER_EXPRESS',
  WORKFORCE = 'WORKFORCE',
  API_USER = 'API_USER',
  CALL_CENTER_ORDERS = 'CALL_CENTER_ORDERS',
  CALL_CENTER_SCREEN = 'CALL_CENTER_SCREEN',
	MERCHANT_WAREHOUSE = 'MERCHANT_WAREHOUSE',
	INVENTORY_LIST = 'INVENTORY_LIST',
  INVENTORY_EDIT = 'INVENTORY_EDIT',
	INVENTORY_EDIT_ZONE = 'INVENTORY_EDIT_ZONE',
	INVENTORY_EDIT_QUANTITY = 'INVENTORY_EDIT_QUANTITY',
	INVENTORY_EDIT_PRICE = 'INVENTORY_EDIT_PRICE',
	INVENTORY_EDIT_PUBLISH = 'INVENTORY_EDIT_PUBLISH',
	INVENTORY_EDIT_THRESHOLD = 'INVENTORY_EDIT_THRESHOLD',
	TICKET_LIST = 'TICKET_LIST',
	TICKET_EDIT = 'TICKET_EDIT',
	CUSTOMER_LIST = 'CUSTOMER_LIST',
	CLIENT_VIEW_PROJECT = 'CLIENT_VIEW_PROJECT',
	CLIENT_VIEW_CUSTOMER = 'CLIENT_VIEW_CUSTOMER',
	ALERT_LISTING = 'ALERT_LISTING',
  TICKET_CONFIG_EDIT = 'TICKET_CONFIG_EDIT',
  RATING_LIST = 'RATING_LIST',
  TAGS_LIST = 'TAGS_LIST',
  PORTAL_USER = 'PORTAL_USER',
  RATINGS_VIEW = 'RATINGS_VIEW',
  APP_SETTING_VIEW = 'APP_SETTING_VIEW',
}
export enum MerchantsRolesEnum {
  MERCHANT_LIST_VIEW = 'MERCHANT_LIST_VIEW',
  MERCHANT_VIEW = 'MERCHANT_VIEW',
  MERCHANT_EDIT = 'MERCHANT_EDIT',
  MERCHANT_ADD_REMOVE = 'MERCHANT_ADD_REMOVE',
  MERCHANT_ARCHIVE = 'MERCHANT_ARCHIVE',
  MERCHANT_CLOSE_ALL = 'MERCHANT_COSE_ALL',

  MERCHANT_INFORMATION_VIEW = 'MERCHANT_INFORMATION_VIEW',
  MERCHANT_INFORMATION_CONTACT_EDIT = 'MERCHANT_INFORMATION_CONTACT_EDIT',
  MERCHANT_INFORMATION_CONTACT_ADD_REMOVE = 'MERCHANT_INFORMATION_CONTACT_ADD_REMOVE',
  MERCHANT_INFORMATION_CONTACT_REGISTER_EMAIL = 'MERCHANT_INFORMATION_CONTACT_REGISTER_EMAIL',

  MERCHANT_PRODUCT_LIST_VIEW = 'MERCHANT_PRODUCT_LIST_VIEW',
  MERCHANT_PRODUCT_VIEW = 'MERCHANT_PRODUCT_VIEW',
  MERCHANT_PRODUCT_EDIT = 'MERCHANT_PRODUCT_EDIT',
  MERCHANT_PRODUCT_ADD_REMOVE = 'MERCHANT_PRODUCT_ADD_REMOVE',
  MERCHANT_PRODUCT_DISCOUNT_ADD = 'MERCHANT_PRODUCT_DISCOUNT_ADD',
  MERCHANT_PRODUCT_RECOMMEND = 'MERCHANT_PRODUCT_RECOMMEND',
  MERCHANT_PRODUCT_PUBLISH = 'MERCHANT_PRODUCT_PUBLISH',
  MERCHANT_PRODUCT_VAT = 'MERCHANT_PRODUCT_VAT',
  MERCHANT_PRODUCT_RATING = 'MERCHANT_PRODUCT_RATING',
  MERCHANT_PRODUCT_CUSTOMIZATIONS = 'MERCHANT_PRODUCT_CUSTOMIZATIONS',
  MERCHANT_PRODUCT_CETEGORIES = 'MERCHANT_PRODUCT_CETEGORIES',
  MERCHANT_PRODUCT_BULK_PRODUCTS_ADD = 'MERCHANT_PRODUCT_BULK_PRODUCTS_ADD',
  MERCHANT_PRODUCT_BULK_ADD_TO_COLLECTIONS = 'MERCHANT_PRODUCT_BULK_ADD_TO_COLLECTIONS',
  MERCHANT_PRODUCT_EXPORT_ALL = 'MERCHANT_PRODUCT_EXPORT_ALL',

  MERCHANT_COLLECTIONS_VIEW = 'MERCHANT_COLLECTIONS_VIEW',
  MERCHANT_COLLECTION_EDIT = 'MERCHANT_COLLECTION_EDIT',
  MERCHANT_COLLECTION_ADD_REMOVE = 'MERCHANT_COLLECTION_ADD_REMOVE',
  MERCHANT_COLLECTION_ACTIVATE = 'MERCHANT_COLLECTION_ACTIVATE',

  MERCHANT_STORES_VIEW = 'MERCHANT_STORES_VIEW',
  MERCHANT_STORE_EDIT = 'MERCHANT_STORE_EDIT',
  MERCHANT_STORE_ADD_REMOVE = 'MERCHANT_STORE_ADD_REMOVE',
  MERCHANT_STORE_ACTIVATE = 'MERCHANT_STORE_ACTIVATE',

  MERCHANT_BRANDS_VIEW = 'MERCHANT_BRANDS_VIEW',
  MERCHANT_BRAND_EDIT = 'MERCHANT_BRAND_EDIT',
  MERCHANT_BRAND_ADD_REMOVE = 'MERCHANT_BRAND_ADD_REMOVE',

  MERCHANT_ADVANCED_VIEW = 'MERCHANT_ADVANCED_VIEW',
}
export enum TeamsRolesEnum {
  TEAM_LIST = 'TEAM_LIST',
  TEAM_EDIT = 'TEAM_EDIT',
  TEAM_DELETE = 'TEAM_DELETE',
  RIDER_LIST = 'RIDER_LIST',
  RIDER_EDIT = 'RIDER_EDIT',
  RIDER_DELETE = 'RIDER_DELETE',
}
export enum HomePageItemRolesEnum {
  HOME_PAGES_VIEW = 'HOME_PAGES_VIEW',
  HOME_PAGE_ADD_REMOVE = 'HOME_PAGE_ADD_REMOVE',
  HOME_PAGE_SECTIONS_VIEW = 'HOME_PAGE_SECTIONS_VIEW',
  HOME_PAGE_SECTION_ACTIVATE = 'HOME_PAGE_SECTION_ACTIVATE',
  HOME_PAGE_SECTION_ADD_REMOVE = 'HOME_PAGE_SECTION_ADD_REMOVE',
  HOME_PAGE_SECTION_EDIT = 'HOME_PAGE_SECTION_EDIT',
}
export enum TagsRolesEnum {
  TAGS_PRODUCTS_VIEW = 'TAGS_PRODUCTS_VIEW',
  TAGS_STORES_VIEW = 'TAGS_STORES_VIEW',
  TAGS_PRODUCT_VIEW = 'TAGS_PRODUCT_VIEW',
  TAGS_PRODUCT_EDIT = 'TAGS_PRODUCT_EDIT',
  TAGS_PRODUCT_ADD_REMOVE = 'TAGS_PRODUCT_ADD_REMOVE',
  TAGS_PRODUCT_RELATIONS = 'TAGS_PRODUCT_RELATIONS',
  TAGS_STORE_EDIT = 'TAGS_STORE_EDIT',
  TAGS_STORE_ADD_REMOVE = 'TAGS_STORE_ADD_REMOVE',
}
export enum AreaRolesEnum {
  AREA_VIEW = 'AREA_VIEW',
  AREA_ADD_REMOVE = 'AREA_ADD_REMOVE',
  AREA_EDIT = 'AREA_EDIT'
}
export enum AlertsRolesEnum {
  ALERTS = 'ALERTS',
  ALERT_EDIT = 'ALERT_EDIT',
  ALERT_ACTIVATE = 'ALERT_ACTIVATE',
  ALERT_ADD_REMOVE = 'ALERT_ADD_REMOVE',
}
export enum ReportsRolesEnum {
  REPORT_APP_ORDERS_VIEW = 'REPORT_APP_ORDERS_VIEW',
  REPORT_REFERRAL_VIEW = 'REPORT_REFERRAL_VIEW',
  REPORT_INCOMING_ORDERS_VIEW = 'REPORT_INCOMING_ORDERS_VIEW',
  REPORT_ORDERS_VIEW = 'REPORT_ORDERS_VIEW',
  REPORT_TASKS_VIEW = 'REPORT_TASKS_VIEW',
  REPORT_WORKERS_CASH_VIEW = 'REPORT_WORKERS_CASH_VIEW',
  REPORT_WORKERS_TASK_VIEW = 'REPORT_WORKERS_TASK_VIEW',
  REPORT_WORKERS_TIME_SHEET_VIEW = 'REPORT_WORKERS_TIME_SHEET_VIEW',
  REPORT_WORKERS_FEEDBACK_VIEW = 'REPORT_WORKERS_FEEDBACK_VIEW',
  REPORT_CUSTOMER_ORDERS_VIEW = 'REPORT_CUSTOMER_ORDERS_VIEW',
  REPORT_CUSTOMER_CASH_VIEW = 'REPORT_CUSTOMER_CASH_VIEW',
  REPORT_CUSTOMER_ORDER_TREND_VIEW = 'REPORT_CUSTOMER_ORDER_TREND_VIEW',
  REPORT_WORKERS_TASKS_TREND_VIEW = 'REPORT_WORKERS_TASKS_TREND_VIEW',
}
export enum ClientViewCustomerRolesEnum {
  CUSTOMERS = 'CUSTOMERS',
  CUSTOMER_VIEW = 'CUSTOMER_VIEW',
  CUSTOMER_EDIT = 'CUSTOMER_EDIT',
  CUSTOMERS_EXPORT_ALL = 'CUSTOMERS_EXPORT_ALL',
  CUSTOMER_MANAGE_WALLET = 'CUSTOMER_MANAGE_WALLET',
  CUSTOMER_WALLET_HISTORY = 'CUSTOMER_WALLET_HISTORY',
  CUSTOMER_BLOCK_USER_VIEW = 'CUSTOMER_BLOCK_USER_VIEW',
  CUSTOMER_BLOCK_USER_EDIT = 'CUSTOMER_BLOCK_USER_EDIT',
  CUSTOMER_BLOCK_USER_ADD_REMOVE = 'CUSTOMER_BLOCK_USER_ADD_REMOVE',
}
export enum PromotionsRolesEnum {
  PROMOTIONS_PROMO_LIST_VIEW = 'PROMOTIONS_PROMO_LIST_VIEW',
  PROMOTIONS_CAMPAIGN_LIST_VIEW = 'PROMOTIONS_CAMPAIGN_LIST_VIEW',
  PROMOTIONS_BANNER_LIST_VIEW = 'PROMOTIONS_BANNER_LIST_VIEW',
  PROMOTIONS_CASHBACK_LIST_VIEW = 'PROMOTIONS_CASHBACK_LIST_VIEW',

  PROMOTIONS_PROMO_VIEW = 'PROMOTIONS_PROMO_VIEW',
  PROMOTIONS_PROMO_EDIT = 'PROMOTIONS_PROMO_EDIT',
  PROMOTIONS_PROMO_ACTIVATE = 'PROMOTIONS_PROMO_ACTIVATE',
  PROMOTIONS_PROMO_ADD_REMOVE = 'PROMOTIONS_PROMO_ADD_REMOVE',

  PROMOTIONS_CAMPAIGN_VIEW = 'PROMOTIONS_CAMPAIGN_VIEW',
  PROMOTIONS_CAMPAIGN_EDIT = 'PROMOTIONS_CAMPAIGN_EDIT',
  PROMOTIONS_CAMPAIGN_ADD_REMOVE = 'PROMOTIONS_CAMPAIGN_ADD_REMOVE',
  PROMOTIONS_CAMPAIGN_ACTIVATE = 'PROMOTIONS_CAMPAIGN_ACTIVATE',

  PROMOTIONS_BANNER_VIEW = 'PROMOTIONS_BANNER_VIEW',
  PROMOTIONS_BANNER_EDIT = 'PROMOTIONS_BANNER_EDIT',
  PROMOTIONS_BANNER_ADD_REMOVE = 'PROMOTIONS_BANNER_ADD_REMOVE',
  PROMOTIONS_BANNER_ACTIVATE = 'PROMOTIONS_BANNER_ACTIVATE',

  PROMOTIONS_CASHBACK_VIEW = 'PROMOTIONS_CASHBACK_VIEW',
  PROMOTIONS_CASHBACK_EDIT = 'PROMOTIONS_CASHBACK_EDIT',
  PROMOTIONS_CASHBACK_ADD_REMOVE = 'PROMOTIONS_CASHBACK_ADD_REMOVE',
  PROMOTIONS_CASHBACK_ACTIVATE = 'PROMOTIONS_CASHBACK_ACTIVATE',
}

export enum CourierOrdersRolesEnum {
  INCOMING_ORDERS_LIST = 'INCOMING_ORDERS_LIST',
  INCOMING_ORDERS_NEW_ORDER = 'INCOMING_ORDERS_NEW_ORDER',
  INCOMING_ORDERS_ASSIGN_WORKER = 'INCOMING_ORDERS_ASSIGN_WORKER',
  INCOMING_ORDERS_VIEW = 'INCOMING_ORDERS_VIEW',
  INCOMING_ORDERS_BULK_CLOSE = 'INCOMING_ORDERS_BULK_CLOSE',
  INCOMING_ORDERS_PRINT_WAYBILL = 'INCOMING_ORDERS_PRINT_WAYBILL',
  INCOMING_ORDERS_LIVE_TRACKING = 'INCOMING_ORDERS_LIVE_TRACKING',
  INCOMING_ORDERS_VIEW_MAP = 'INCOMING_ORDERS_VIEW_MAP',
  INCOMING_ORDERS_ORDER_CLOSE = 'INCOMING_ORDERS_ORDER_CLOSE',
  INCOMING_ORDERS_ORDER_CANCEL = 'INCOMING_ORDERS_ORDER_CANCEL',
  INCOMING_ORDERS_TAG_ADD = 'INCOMING_ORDERS_TAG_ADD',
  INCOMING_ORDERS_COMMENTS_ADD = 'INCOMING_ORDERS_COMMENTS_ADD',
  INCOMING_ORDERS_ACCOUNTING_NOTE_ADD = 'INCOMING_ORDERS_ACCOUNTING_NOTE_ADD',
}
export enum CourierMapRolesEnum {
  MAP_VIEW = 'MAP_VIEW'
}
export enum LocationRolesEnum {
  MY_LOCATIONS_VIEW = 'MY_LOCATIONS_VIEW'
}
export enum CourierReturnsRolesEnum {
  RETURNS_VIEW = 'RETURNS_VIEW',
  RETURNS_BULK_CREATE_RETURN_ORDER = 'RETURNS_BULK_CREATE_RETURN_ORDER',
  RETURNS_BULK_RETURN_ORDERS = 'RETURNS_BULK_RETURN_ORDERS',
  RETURNS_EXPORT_ALL = 'RETURNS_EXPORT_ALL',
  RETURNS_BULK_CLOSE = 'RETURNS_BULK_CLOSE',
  RETURNS_BULK_PRINT_WAYBILL = 'RETURNS_BULK_PRINT_WAYBILL',
}
export enum AgencyRolesEnum {
  AGENCY_LIST = 'AGENCY_LIST',
  AGENCY_USER_ADD = 'AGENCY_USER_ADD',
  MERCHANT_INFORMATION_CONTACT_REGISTER_EMAIL = 'MERCHANT_INFORMATION_CONTACT_REGISTER_EMAIL',
}
export enum CourierCustomerRolesEnum {
  CUSTOMERS_LIST = 'CUSTOMERS_LIST',
  CUSTOMER_ADD = 'CUSTOMER_ADD',
  CUSTOMER_NEW_ORDERS = 'CUSTOMER_NEW_ORDERS',
  CUSTOMER_ORDERS = 'CUSTOMER_ORDERS',
  CUSTOMER_BILLING = 'CUSTOMER_BILLING',
  CUSTOMER_COD = 'CUSTOMER_COD',
  CUSTOMER_RETURNS = 'CUSTOMER_RETURNS',
  CUSTOMER_BRANCHES = 'CUSTOMER_BRANCHES',
  CUSTOMER_SETTINGS = 'CUSTOMER_SETTINGS',

  CUSTOMER_NEW_ORDER_CANCEL = 'CUSTOMER_NEW_ORDER_CANCEL',
  CUSTOMER_NEW_ORDER_TAG_ADD = 'CUSTOMER_NEW_ORDER_TAG_ADD',
  CUSTOMER_NEW_ORDER_COMMENTS_ADD = 'CUSTOMER_NEW_ORDER_COMMENTS_ADD',
  CUSTOMER_NEW_ORDER_CONFIRM = 'CUSTOMER_NEW_ORDER_CONFIRM',
  CUSTOMER_NEW_ORDER_BULK_CONFIRM = 'CUSTOMER_NEW_ORDER_BULK_CONFIRM',

  CUSTOMER_ORDER_ASSIGN_WORKER = 'CUSTOMER_ORDER_ASSIGN_WORKER',
  CUSTOMER_ORDER_VIEW = 'CUSTOMER_ORDER_VIEW',
  CUSTOMER_ORDER_BULK_MOVE_TO_OTHER = 'CUSTOMER_ORDER_BULK_MOVE_TO_OTHER',
  CUSTOMER_ORDERS_BULK_CLOSE = 'CUSTOMER_ORDERS_BULK_CLOSE',
  CUSTOMER_ORDERS_BULK_PRINT_WAYBILL = 'CUSTOMER_ORDERS_BULK_PRINT_WAYBILL',
  CUSTOMER_ORDER_PRINT_WAYBILL = 'CUSTOMER_ORDER_PRINT_WAYBILL',
  CUSTOMER_ORDERS_ORDER_CLOSES = 'CUSTOMER_ORDERS_ORDER_CLOSES',
  CUSTOMER_ORDERS_ORDER_CANCEL = 'CUSTOMER_ORDERS_ORDER_CANCEL',
  CUSTOMER_ORDER_TAG_ADD = 'CUSTOMER_ORDER_TAG_ADD',
  CUSTOMER_ORDER_COMMENTS_ADD = 'CUSTOMER_ORDER_COMMENTS_ADD',
  CUSTOMER_ORDER_ACCOUNTING_NOTE_ADD = 'CUSTOMER_ORDER_ACCOUNTING_NOTE_ADD',
  CUSTOMER_ORDER_RETURN_TO_CUSTOMER = 'CUSTOMER_ORDER_RETURN_TO_CUSTOMER',
  CUSTOMER_ORDER_RETURN_ORDER = 'CUSTOMER_ORDER_RETURN_ORDER',

  CUSTOMER_BILLING_CREATE_INVOICE = 'CUSTOMER_BILLING_CREATE_INVOICE',
  CUSTOMER_BILLING_ORDER_VIEW = 'CUSTOMER_BILLING_ORDER_VIEW',

  CUSTOMER_COD_CREATE_RECEIPT = 'CUSTOMER_COD_CREATE_RECEIPT',
  CUSTOMER_COD_ORDER_VIEW = 'CUSTOMER_COD_ORDER_VIEW',

  CUSTOMER_RETURNS_ORDER_VIEW = 'CUSTOMER_RETURNS_ORDER_VIEW',
  CUSTOMER_RETURNS_BULK_MOVE_TO_OTHER = 'CUSTOMER_RETURNS_BULK_MOVE_TO_OTHER',
  CUSTOMER_RETURNS_BULK_CREATE_RETURN = 'CUSTOMER_RETURNS_BULK_CREATE_RETURN',
  CUSTOMER_RETURNS_BULK_RETURN_ORDERS = 'CUSTOMER_RETURNS_BULK_RETURN_ORDERS',
  CUSTOMER_RETURNS_BULK_CLOSE = 'CUSTOMER_RETURNS_BULK_CLOSE',
  CUSTOMER_RETURNS_BULK_PRINT_WAYBILL = 'CUSTOMER_RETURNS_BULK_PRINT_WAYBILL',
  CUSTOMER_RETURNS_PRINT_WAYBILL = 'CUSTOMER_RETURNS_PRINT_WAYBILL',
  CUSTOMER_RETURNS_ORDER_CLOSES = 'CUSTOMER_RETURNS_ORDER_CLOSES',
  CUSTOMER_RETURNS_ORDER_CANCEL = 'CUSTOMER_RETURNS_ORDER_CANCEL',
  CUSTOMER_RETURNS_TAG_ADD = 'CUSTOMER_RETURNS_TAG_ADD',
  CUSTOMER_RETURNS_COMMENTS_ADD = 'CUSTOMER_RETURNS_COMMENTS_ADD',
  CUSTOMER_RETURNS_RETURN_ORDER = 'CUSTOMER_RETURNS_RETURN_ORDER',

  CUSTOMER_BRANCHES_MANAGE = 'CUSTOMER_BRANCHES_MANAGE',
  CUSTOMER_BRANCHES_EDIT = 'CUSTOMER_BRANCHES_EDIT',
  CUSTOMER_BRANCHES_ACTIVATE = 'CUSTOMER_BRANCHES_ACTIVATE',

  CUSTOMER_SETTINGS_EDIT = 'CUSTOMER_SETTINGS_EDIT',
  CUSTOMER_SETTINGS_CUSTOMER_DELETE = 'CUSTOMER_SETTINGS_CUSTOMER_DELETE',
  CUSTOMER_SETTINGS_CONTACT_MANAGE = 'CUSTOMER_SETTINGS_CONTACT_MANAGE',
  CUSTOMER_SETTINGS_CONTACT_EDIT = 'CUSTOMER_SETTINGS_CONTACT_EDIT',
  CUSTOMER_SETTINGS_SEND_REG_EMAIL = 'CUSTOMER_SETTINGS_SEND_REG_EMAIL',
}
export enum CourierWorkersRolesEnum {
  WORKERS = 'WORKERS',
  WORKER_EDIT = 'WORKER_EDIT',
  WORKER_ADD = 'WORKER_ADD',
  WORKER_ZONES = 'WORKER_ZONES',
  WORKER_TEAMS = 'WORKER_TEAMS',

  WORKER_ZONE_EDIT = 'WORKER_ZONE_EDIT',
  WORKER_ZONE_MANAGE = 'WORKER_ZONE_MANAGE',

  WORKER_TEAM_EDIT = 'WORKER_TEAM_EDIT',
  WORKER_TEAM_ADD = 'WORKER_TEAM_ADD',
  WORKER_TEAM_EDIT_ACTIVATE = 'WORKER_TEAM_EDIT_ACTIVATE',
}

export enum MerchantReportsEnum {
    MERCHANT_ORDER_REPORT_VIEW = 'MERCHANT_ORDER_REPORT_VIEW'
}
export enum MerchantIncomingOrdersEnum {
  MERCHANT_INCOMING_ORDERS_VIEW = 'MERCHANT_INCOMING_ORDERS_VIEW'
}
export enum MerchantProductsEnum {
  MERCHANT_STORE_PRODUCTS_VIEW = 'MERCHANT_STORE_PRODUCTS_VIEW'
}

export const UserRolesEnum = <const>{
  ...UserRolesEnumExist,
  ...MerchantsRolesEnum,
  ...TeamsRolesEnum,
  ...HomePageItemRolesEnum,
  ...TagsRolesEnum,
  ...AreaRolesEnum,
  ...AlertsRolesEnum,
  ...ReportsRolesEnum,
  ...ClientViewCustomerRolesEnum,
  ...PromotionsRolesEnum,
  ...CourierOrdersRolesEnum,
  ...CourierMapRolesEnum,
  ...CourierReturnsRolesEnum,
  ...AgencyRolesEnum,
  ...LocationRolesEnum,
  ...CourierCustomerRolesEnum,
  ...CourierWorkersRolesEnum,
  ...MerchantReportsEnum,
  ...MerchantIncomingOrdersEnum,
  ...MerchantProductsEnum,
}

export const RedirectRoutes = {
	CLIENT_VIEW_ORDER: {
		url: AppRoutesEnum.CLIENT_VIEW_DASHBOARD_URL,
		redirectPriority: 1
	},
	DISPATCHER: { url: AppRoutesEnum.DISPATCHER_TEAMS_URL, redirectPriority: 2 },
  INCOMING_ORDERS_LIST: {
    url: AppRoutesEnum.COURIER_INCOMING_ORDERS_URL,
    redirectPriorty: 3
  },
	AGENT_BILLING: { url: AppRoutesEnum.BILLING_AGENT_URL, redirectPriority: 4 },
	MERCHANT: {
		url: AppRoutesEnum.MARKETPLACE_WAREHOUSE_DASHBOARD_URL,
		redirectPriority: 5
	},
	MERCHANT_WAREHOUSE: {
		url: AppRoutesEnum.MARKETPLACE_WAREHOUSE_DASHBOARD_URL,
		redirectPriority: 6
	},
	INTERIM: { url: AppRoutesEnum.INTERIM_ORDERS_URL, redirectPriority: 7 },
	CLIENT_EXPRESS_VIEW: {
		url: AppRoutesEnum.CLIENT_EXPRESS_VIEW_BRANCHES_URL,
		redirectPriority: 8
	},
	// MARKETPLACE: {
	// 	url: AppRoutesEnum.MARKETPLACE_ORDERS_URL,
	// 	redirectPriority: 9
	// },
  ORDER_LIST: {
    url: AppRoutesEnum.MARKETPLACE_ORDERS_URL,
    redirectPriority: 10
  },
  TEAM_LIST: {
    url: AppRoutesEnum.MARKETPLACE_TEAMS_URL,
    redirectPriority: 11
  },
  TICKET_LIST: {
    url: AppRoutesEnum.MARKETPLACE_INCIDENTS_URL,
    redirectPriority: 12
  },
  INVENTORY_LIST: {
    url: AppRoutesEnum.MARKETPLACE_INVENTORY_URL,
    redirectPriority: 13
  },
  // REFERRALS_VIEW: {
  //   url: AppRoutesEnum.MARKETPLACE_REFERRALS_OVERVIEW_URL,
  //   redirectPriority: 14
  // },
  MERCHANT_LIST_VIEW: {
		url: AppRoutesEnum.MARKETPLACE_MERCHANTS_URL,
		redirectPriority: 15
	},
  HOME_PAGES_VIEW: {
		url: AppRoutesEnum.MARKETPLACE_HOME_PAGE_ITEMS_URL,
		redirectPriority: 16
	},
  TAGS_PRODUCTS_VIEW: {
		url: AppRoutesEnum.MARKETPLACE_TAGS_URL,
		redirectPriority: 17
	},
  TAGS_STORES_VIEW: {
    url: AppRoutesEnum.MARKETPLACE_TAGS_URL,
    redirectPriority: 18
  },
  CUSTOMERS: {
    url: AppRoutesEnum.MARKETPLACE_CUSTOMERS_URL,
    redirectPriority: 19
  },
  PROMOTIONS_PROMO_LIST_VIEW: {
    url: AppRoutesEnum.MARKETPLACE_PROMO_CODE_URL,
    redirectPriority: 20
  },
  PROMOTIONS_CAMPAIGN_LIST_VIEW: {
    url: AppRoutesEnum.MARKETPLACE_CAMPAIGN_URL,
    redirectPriority: 21
  },
  PROMOTIONS_BANNER_LIST_VIEW: {
    url: AppRoutesEnum.MARKETPLACE_BANNER_URL,
    redirectPriority: 22
  },
  PROMOTIONS_CASHBACK_LIST_VIEW: {
    url: AppRoutesEnum.MARKETPLACE_CASH_BACK_URL,
    redirectPriority: 23
  },
  AREA_VIEW: {
    url: AppRoutesEnum.MARKETPLACE_AREA_URL,
    redirectPriority: 24
  },
  RATINGS_VIEW: {
    url: AppRoutesEnum.MARKETPLACE_RATING_URL,
    redirectPriority: 25
  },
  ALERTS: {
    url: AppRoutesEnum.MARKETPLACE_ALERT_URL,
    redirectPriority: 26
  },
  CUSTOMER_BILLING: {
    url: AppRoutesEnum.BILLING_CUSTOMER_URL,
    redirectPriority: 27
  },
  REPORT_APP_ORDERS_VIEW: {
    url: AppRoutesEnum.BOARD_AGENCY_REPORTS_URL,
    redirectPriority: 28
  },
  REPORT_REFERRAL_VIEW: {
    url: AppRoutesEnum.BOARD_AGENCY_REPORTS_URL,
    redirectPriority: 29
  },
	USER_LIST: { url: AppRoutesEnum.MARKETPLACE_USERS_URL, redirectPriority: 30 },
	ROLE_LIST: { url: AppRoutesEnum.MARKETPLACE_ROLES_URL, redirectPriority: 31 },
  INCIDENT_CONFIGURATION_LIST: {
    url: AppRoutesEnum.MARKETPLACE_INCIDENT_CONFIG_URL,
    redirectPriorty: 32
  },
  APP_SETTING_VIEW: {
    url: AppRoutesEnum.MARKETPLACE_APP_SETTING_URL,
    redirectPriorty: 33
  },
  // PORTAL_USER: {
  //   url: AppRoutesEnum.MARKETPLACE_INCIDENT_CONFIG_URL,
  //   redirectPriorty: 18
  // },

};
