import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { Observable } from 'rxjs/Observable';
import { AppRoutesEnum } from '@core/models/appRoutes.enum';

@Injectable()
export class AuthenticationGuard implements CanActivate {
	isAuthenticated = false;

	constructor(private router: Router, private authService: AuthService) {}

	public canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		return this._hasAuthenticatedUser();
	}

	private _hasAuthenticatedUser(): Observable<boolean> {
		this.authService.isAuthenticated.subscribe(
			(data) => (this.isAuthenticated = data)
		);

		if (!this.isAuthenticated) {
			this.router.navigateByUrl(AppRoutesEnum.LOGIN_URL);
		}

		return this.authService.isAuthenticated;
	}
}
