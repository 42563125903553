import {Injectable} from '@angular/core';
import {ToastyService, ToastyConfig} from 'ng2-toasty';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private toastyService: ToastyService, private toastyConfig: ToastyConfig, private toastr: ToastrService) {
  }

  errorNotification(message: string) {
    this.toastyService.error({
      title: '',
      msg: message,
      showClose: true,
      timeout: 5000,
      theme: 'default'
    });
  }

  successNotification(message: string) {
    this.toastyService.success({
      title: '',
      msg: message,
      showClose: true,
      timeout: 5000,
      theme: 'default'
    });
  }

  infoNotification(message: string) {
    this.toastyService.info({
      title: '',
      msg: message,
      showClose: true,
      timeout: 5000,
      theme: 'default'
    });
  }

  waitNotification(message: string) {
    this.toastyService.wait({
      title: '',
      msg: message,
      showClose: true,
      timeout: 5000,
      theme: 'default'
    });
  }

  warningNotification(message: string) {
    this.toastyService.warning({
      title: '',
      msg: message,
      showClose: true,
      timeout: 5000,
      theme: 'default'
    });
  }

  announcementNotification(message: string) {
    this.toastr.show(`<div class="row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-1 pdd-right-0"> <span class="font-size-40 fa fa-bell-slash"></span> </div>
                                    <div class="col-md-11 padd-left-0"> <span>${message}</span> </div></div>
                                </div>
                                </div>
                                <div class="col-md-2"></div>
                                </div>`, '', {
      enableHtml: true,
      closeButton: true,
      positionClass: 'toast-top-center',
      timeOut: 10000000,
      extendedTimeOut: 10000000,
      toastClass: 'toast-announcemnet-background'
    });
  }

  playSound() {
    const audio = new Audio();
    audio.src = '../../../assets/audio/success.mp3';
    audio.load();
    const playPromise = audio.play();
    if (playPromise !== null) {
      playPromise.catch(() => {
        audio.play();
      });
    }
  }

  playWarningSound() {
    const audio = new Audio();
    audio.src = '../../../assets/audio/warning.wav';
    audio.load();
    audio.play();
  }
}
