import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
	providedIn: 'root'
})
export class MessagesService {
	private internalServerErrorMessage = new BehaviorSubject<string>(
		'Uh Sorry, something went'
	);
	serverErrorMessage = this.internalServerErrorMessage.asObservable();

	constructor() {}

	changeServerErrorMessage(message: string) {
		this.internalServerErrorMessage.next(message);
	}
}
