import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'app-text-editor-modal',
  templateUrl: './text-editor-modal.component.html',
  styleUrls: ['./text-editor-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TextEditorModalComponent implements OnInit {
  @Input() htmlContent
  @Output() result: EventEmitter<any> = new EventEmitter();
  addHtml = false

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }
  onToggle() {
    this.addHtml = !this.addHtml;
  }

  onSave() {
    this.activeModal.close()
    this.result.emit(this.htmlContent);
  }
  htmlToPlaintext(event: ClipboardEvent) {
    const clipboardData = event.clipboardData
    let pastedText = clipboardData.getData('text');
    pastedText = pastedText.replace(/<[^>]+>/gm, '');
    setTimeout(() => {
      this.htmlContent = pastedText.replace(/&lt;br&gt;/g, '')
    }, 500)

  }
}
