import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APPREFERALCODEURLS, APPURLS } from './appsUrl-constants';

@Component({
  selector: 'app-app-link',
  template: ``,
})
export class AppLinkComponent implements OnInit {
  storeId;
  productId;
  referalCode;
  appUrls = APPURLS;
  appReferalCodeUrls = APPREFERALCODEURLS;
  androidAppUrl = '';
  iosAppUrl = '';
  windowsAppUrl = '';
  appName;
  constructor(public route: ActivatedRoute) {
    this.route.params.subscribe((param) => {
    this.appName = param.appName
		});
    this.route.queryParams.subscribe((param) => {
      // this.storeId = param.store_Id
      if(param.referalCode) {
        this.referalCode = param.referalCode
        this.getReferalCodeURls(this.appName);
      } else {
        if(param.productId) {
          this.productId = param.productId
        }
        
        // this.deeplink(this.productId)
        this.getAppURls(this.appName);
      }
     
    });
  }

  ngOnInit() {

  }

  deeplink() {
    let os = this.getOS()
    if (os === 'macos') {
      window.location.replace(this.iosAppUrl);
    } else if (os === 'ios') {
      window.location.replace(this.iosAppUrl);
    } else if (os === 'windows') {
      window.location.replace(this.windowsAppUrl);
    } else if (os === 'android') {
      window.location.replace(this.androidAppUrl);
    } 
    // else if (os === 'linux') {
    //   window.location.replace("https://play.google.com/store/apps/details?id=com.enviospet&hl=en&gl=US");
    // }
  }
  getOS() {
    let userAgent = window.navigator.userAgent.toLowerCase(),
      macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
      windowsPlatforms = /(win32|win64|windows|wince)/i,
      iosPlatforms = /(iphone|ipad|ipod)/i,
      os = null;

    if (macosPlatforms.test(userAgent)) {
      os = "macos";
    } else if (iosPlatforms.test(userAgent)) {
      os = "ios";
    } else if (windowsPlatforms.test(userAgent)) {
      os = "windows";
    } else if (/android/.test(userAgent)) {
      os = "android";
    } else if (!os && /linux/.test(userAgent)) {
      os = "linux";
    }
    return os;
  }

  getAppURls(appName) {
    this.appUrls.forEach((app) => {
      if(appName === app.appName) {
        this.androidAppUrl = app.android;
        this.iosAppUrl = app.ios;
        this.windowsAppUrl = app.windows;
        this.deeplink()
      }
    })

  }
  getReferalCodeURls(appName) {
    this.appReferalCodeUrls.forEach((app) => {
      if(appName === app.appName) {
        this.androidAppUrl = app.android + this.referalCode;
        this.iosAppUrl = app.ios + this.referalCode;
        this.windowsAppUrl = app.windows + this.referalCode;
        this.deeplink()
      }
    })

  }
}
