import {Component, Inject, InjectionToken, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Group} from "@app/marketplace/merchants/models/group.model";
import {ActivatedRoute, Navigation, Router} from "@angular/router";
import {EddressFacadeService} from "@core/services/eddressFacade.service";
import {MerchantService} from "@app/marketplace/merchants/services/merchant.service";
import {take} from "rxjs/operators";
import swal from "sweetalert2";
import {User} from "@core/models/user.model";
import {Product} from "@app/marketplace/merchants/models/product.model";
import * as _ from 'lodash';
import {MerchantProductsState} from "@app/marketplace/merchants/state/merchantProductsState";
import {_countGroupLabelsBeforeOption} from "@angular/material/core";
import {NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {SelectCollectionsComponent} from "@app/marketplace/home-page-items/components/select-collections/select-collections.component";
import {AppRoutesEnum} from "@core/models/appRoutes.enum";
import * as moment from 'moment';
import 'moment-timezone';
import {TextEditorModalComponent} from "@shared/text-editor-modal/text-editor-modal.component";
import {Location} from "@angular/common";
export const portalUrl = new InjectionToken<string>('portalUrl');

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddProductComponent implements OnInit, OnDestroy {
  @ViewChild('addToCustomizationModal', {static: false})
  private addToCustomizationModal: TemplateRef<any>;

  @ViewChild('scheduleModal', {static: false})
  private scheduleModal: TemplateRef<any>;
  isTimeZoneEmpty = false
  startTime = '12:00 am';
  endTime = '11:59 pm';
  product_form: FormGroup;
  isDataLoaded = false;
  isFormLoaded = false;
  isReadOnly = true;
  merchantId = "";
  merchantObj: any = {}
  productObj;
  isEditable = false;
  products = [];
  clonedProducts = [];
  configuration
  langs = [];
  selectedLanguage;
  updatedSelectedLanguage;
  defaultLanguage;

  isDelete = false;
  imageIsDeleted = false;
  bannerIsDeleted = false;
  isBannerDelete = false;
  imageIsBlank = false;
  imageIsEdited = false;
  bannerIsBlank = false;
  bannerIsEdited = false;
  defaultPosition = 0;
  collectionId = "";
  collection;
  type = '';
  sectionIdex;
  selectedSection;
  selectedImage

  user: User;
  data: any = {
    schedule: [],
    imageRatioRestricted: true,
    translatedProd: {},
    translatedCust: {},
    customizations: [],
    isEdit: false
  };
  days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  isEditSchedule = false
  reccurence = 'true'
  schedule_form: FormGroup;
  dateStart;
  dateEnd;
  product: any = {};
  customizationSections = [];
  recommendations = [];
  units = [
    {label: 'unit', value: 'unit'},
    {label: 'g', value: 'g'},
    {label: 'kg', value: 'kg'},
    {label: 'l', value: 'l'},
    {label: 'ml', value: 'ml'},
  ]
  productSizes = [
    {label: 'Extra Small', value: 0},
    {label: 'Small', value: 1},
    {label: 'Medium', value: 2},
    {label: 'Large', value: 3},
    {label: 'Extra Large', value: 4},
  ]
  sectionGroupTypes = [
    {label: 'Single Selection', value: 'RADIO'},
    {label: 'Multi Selection', value: 'CHECKBOX'},
    {label: 'Items with Quantity', value: 'QUANTITY'},
  ]

  showTextEditor = true;
  enableTextEditor = false;
  appDeepLink = '';

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private _location: Location,
    public eddress: EddressFacadeService,
    private fb: FormBuilder,
    private merchantService: MerchantService,
    private merchantProductsState: MerchantProductsState,
    @Inject(portalUrl) private _portalUrl: string
  ) {
    this.eddress.getCurrentUser().subscribe((data) => {
      this.user = data;
      this.enableTextEditor = this.user.enableTextEditor || false;
    });
    this.route.params.pipe(take(1)).subscribe((param) => {
      const navigationObj = JSON.parse(param.id);
      if (navigationObj.merchant) {
        this.merchantObj = JSON.parse(navigationObj.merchant);
        this.merchantId = this.merchantObj.id;
      } else {
        this.merchantId = navigationObj.id;
        this.merchantObj = navigationObj
      }
      // let nav: Navigation = this.router.getCurrentNavigation();
      if (navigationObj.product) {
        this.productObj = JSON.parse(navigationObj.product);
        this.product = this.productObj.productItemBean;
        this.configuration = this.productObj.configuration
        if (this.configuration.country.timezone != null) {
          this.isTimeZoneEmpty = false
        } else {
          this.isTimeZoneEmpty = true
        }
        if (this.product.hasSchedules) {
          this.intilalizeSchedules(this.product.schedules)
        }
        this.data.isEdit = this.productObj.isEdit;
        this.data.serviceType = this.productObj.serviceType;
        localStorage.setItem('productInformation', JSON.stringify(this.productObj))
      } else {
        if (localStorage.getItem('productInformation')) {
          const state = JSON.parse(localStorage.getItem('productInformation'))
          this.product = state.productItemBean;
          this.configuration = state.configuration
          if (this.configuration.country.timezone != null) {
            this.isTimeZoneEmpty = false
          } else {
            this.isTimeZoneEmpty = true
          }
          if (this.product.hasSchedules) {
            this.intilalizeSchedules(this.product.schedules)
          }
          this.data.isEdit = state.isEdit;
          this.data.serviceType = state.serviceType;
        }
      }

      this.langs = this.eddress.utilityService.getLocalesDropDownList(this.user);
      this.defaultLanguage = this.eddress.utilityService.getLocaleValue(this.user.defaultLanguage);
      this.selectedLanguage = this.langs[0] || this.defaultLanguage;
      this.updatedSelectedLanguage = _.cloneDeep(this.selectedLanguage);
      this.data.locales = this.eddress.utilityService.getUserLocales(this.user);

      this.initializeTranslatedProducts();
      this.initializeTranslatedCustomizations();
      this.data.selectedLocale = this.user.defaultLanguage;
      if (this.data.isEdit) {
        // item = angular.fromJson($stateParams.data);
        this.route.snapshot.data['title'] = this.product.label;

        if (this.product.customizationItems) {
          this.customizationSections = this.product.customizationItems;
          // itemIndex = this.customizationSections.length;
        }

        if (this.product.price) {
          this.product.price = parseFloat(this.product.price);
          this.product.price = (this.product.price).toFixed(2);
        }
        if (!this.product.images) this.product.images = [];
        this.product.images[0] = {
          url: this.product.imageUrl,
          isBlank: this.product.imageIsBlank,
          imageIsEdited: false
        };
        if (this.product.images[0].url === undefined) {
          this.product.images[0] = this.getEmptyImage();
        }

        if (this.product.additionalImages && this.product.additionalImages.length > 0) {
          this.product.additionalImages.forEach(i => {
            if (i.normal === "" || i.normal == null) {
              i.normal = 'images/photo-camera.png';
              i.imageIsBlank = true;
            } else {
              i.imageIsBlank = false;
              i.imageIsEdited = false;
            }
            this.product.images.push({
              uid: i.uid,
              url: i.normal,
              isBlank: i.imageIsBlank,
              imageIsEdited: i.imageIsEdited
            })
          });
        }
        for (let i = (this.product.images.length + 1); i <= 4; i++) {
          this.product.images.push(this.getEmptyImage());
        }
        this.selectedImage = this.product.images[0];
        this.selectedImage.index = 0;


        this.data.translatedProd[this.data.selectedLocale] = _.cloneDeep(this.product);
        this.getProductTranslation(this.product.id);

        if (this.product.ratingEnabled) {
          this.getProductFeedback(this.product.id);
        }

        if (this.product.vatFree) {
          this.product.vatPercent = 0;
        }
        if (this.configuration.appUrl) {
          if(this.configuration.appName === 'kompremos') {
            this.product.deepLink = this.configuration.appUrl + '?productId=' + this.product.id;
          } else {
            this.product.deepLink = this.configuration.appUrl + '/product/' + this.product.id;
          }
        }
      } else {

        // this.product.pageTitle = "New product";
        this.getConfiguration()
        this.product.images = this.initializeImages();
        this.product.showWeight = true
        this.selectedImage = this.product.images[0];
        this.selectedImage.index = 0;

        // if(configuration && configuration.productsConfig){
        //   $scope.product.hasSpecialInstructions = configuration.productsConfig.defaultHasSpecialInstructions;
        // }
      }
    });

    this.eddress.getMarketPlaceConfigurations().subscribe((data) => {
      if (data.data) { 
        this.data.productAdditives = data.data.productAdditives || [];
        this.data.productAllergens = data.data.productAllergens || [];
      }

    });
  }

  ngOnInit() {
    this.schedule_form = this.fb.group({
      startDate: [null],
      endDate: [null]
    });
    this.getBrands();
    this.getCategories();
    this.getMerchantStores();
    this.getRecommendationTags();
    this._loadProductForm();
    this.getCustomization();
    this.getCustomizationTranslation();

  }

  onChangeFreeVat(val) {
    if (val) {
      this.product_form.controls['vatPercent'].disable();
      this.product_form.get('vatPercent').setValue(0);
    } else {
      this.product_form.controls['vatPercent'].enable();
      this.product_form.get('vatPercent').setValue(this.product.vatPercent);
    }

  }

  getConfiguration(): void {
    this.merchantService.getMarketConfig().subscribe((data) => {
      this.configuration = data.data
    })
  }

  setImage(event) {
    this.isDelete = true;

    const index = this.selectedImage.index || 0;
    const uid = this.selectedImage.uid || "";
    this.product.images[index].url = event;
    this.selectedImage.url = event;
    this.selectedImage.index = index;
    this.selectedImage.uid = uid;
    this.selectedImage.isBlank = false;
    this.selectedImage.imageIsEdited = true;
  }

  selectImage(index, image) {
    if (!image) {
      image = {
        imageUrl: "images/photo-camera.png",
        imageIsBlank: true
      }
      this.isDelete = true;
    }

    this.selectedImage = image;
    this.selectedImage.index = index;
  };

  initializeImages() {
    const images = [];
    for (let i = 0; i < 4; i++) {
      images.push(this.getEmptyImage());
    }
    return images;
  }

  intilalizeSchedules(data) {
    this.data.schedule = Object.values(data);
    for (var i = 0; i < this.data.schedule.length; i++) {
      this.data.schedule[i].id = i + 1
      this.data.schedule[i].name = this.days[i]
      if (this.data.schedule[i].start != null) {
        this.data.schedule[i].start = moment(this.data.schedule[i].start).tz(this.configuration.country.timezone).format('YYYY-MM-DD')
      }
      if (this.data.schedule[i].end != null) {
        this.data.schedule[i].end = moment(this.data.schedule[i].end).tz(this.configuration.country.timezone).format('YYYY-MM-DD')
      }
      this.data.schedule[i].timeOpenUTC = this.data.schedule[i].timeOpen
      this.data.schedule[i].timeClosedUTC = this.data.schedule[i].timeClosed
      this.data.schedule[i].startTime = this.formatTime(this.data.schedule[i].timeOpen)
      this.data.schedule[i].endTime = this.formatTime(this.data.schedule[i].timeClosed)
      this.data.schedule[i].sTime = this.formatTime(this.data.schedule[i].timeOpen)
      this.data.schedule[i].eTime = this.formatTime(this.data.schedule[i].timeClosed)
      this.data.schedule[i].startTime = this.utcToLocalTime(this.data.schedule[i].startTime)
      this.data.schedule[i].endTime = this.utcToLocalTime(this.data.schedule[i].endTime)

    }
  }

  addNewSchedule() {
    this.isEditSchedule = false
    this.reccurence = 'true'
    this.data.officeHours = {
      'days': [],
      'isAllDaysSelected': false
    };
    this.eddress.modalService.open(this.scheduleModal, {
      backdrop: 'static',
      size: 'lg'
    });
    this.setOfficeHours()
  };

  editSchedule() {
    this.isEditSchedule = true
    this.reccurence = 'true'
    this.data.officeHours = {
      'days': [],
      'isAllDaysSelected': false
    };
    this.eddress.modalService.open(this.scheduleModal, {
      backdrop: 'static',
      size: 'lg'
    });
    this.setOfficeHours()
  }

  setOfficeHours() {
    if (this.isEditSchedule === false) {
      for (var i = 1; i <= 7; i++) {
        this.data.officeHours.days.push({
          "start": null,
          "name": this.days[i - 1],
          "end": null,
          "timeOpen": '12:00 am',
          "timeClosed": '11:59 pm',
          "startTime": '12:00 am',
          "endTime": '11:59 pm',
          "sTime": "12:00 am",
          "eTime": '11:59 pm',
          "isActive": false
        });
      }
    } else {
      for (var i = 1; i <= 7; i++) {
        for (var j = 0; j < this.data.schedule.length; j++) {
          if (this.data.schedule[j].id === i) {
            if (this.data.schedule[j].start) {
              let dateToObj = moment(this.data.schedule[j].start)
              let tomonth = dateToObj.month() + 1
              let today = dateToObj.date();
              let toyear = dateToObj.year();
              this.dateStart = toyear + '-' + tomonth + '-' + today;
              let dateToObject = {year: toyear, month: tomonth, day: today};

              this.schedule_form.controls['startDate'].setValue(dateToObject)
            }
            if (this.data.schedule[j].end) {
              let dateFromObj = moment(this.data.schedule[j].end)
              let frommonth = dateFromObj.month() + 1
              let fromday = dateFromObj.date();
              let fromyear = dateFromObj.year();
              this.dateEnd = fromyear + '-' + frommonth + '-' + fromday;
              let dateFromObject = {year: fromyear, month: frommonth, day: fromday};
              this.schedule_form.controls['endDate'].setValue(dateFromObject)
            }
            this.data.officeHours.days.push({
              "start": this.data.schedule[j].start,
              "name": this.days[i - 1],
              "end": this.data.schedule[j].end,
              "timeOpen": this.getDateObjectFromTime(this.data.schedule[j].timeOpenUTC),
              "timeClosed": this.getDateObjectFromTime(this.data.schedule[j].timeClosedUTC),
              "sTime": this.getDateObjectFromTime(this.data.schedule[j].timeOpenUTC),
              "eTime": this.getDateObjectFromTime(this.data.schedule[j].timeClosedUTC),
              "isActive": this.data.schedule[j].isActive
            });
          }
        }
      }
    }
  }

  getDateObjectFromTime(time) {
    let utcTime = this.formatTime(time)
    return this.utcToLocalTime(utcTime)
  }

  formatTime(dateTime) {
    let utcTime = moment.utc(dateTime);
    let displayTime = utcTime.format('HH:mm')
    return displayTime
  }

  utcToLocalTime(time) {
    let utcTime = moment.utc(time, 'HH:mm');
    let t = utcTime.clone().tz(this.configuration.country.timezone);
    let displayTime = t.format('hh:mm a')
    return displayTime;
  }

  // saveSchedule(){
  //   this.data.schedule = []
  //   if (this.reccurence === 'false'){
  //     for(var i = 0 ; i < this.data.officeHours.days.length; i++){
  //       if (this.dateStart != null){
  //         // this.dateStart = $filter('date')($scope.startDate , "yyyy-MM-dd");
  //         this.data.officeHours.days[i].start =  this.utcDateConverstion(this.dateStart, 'start')
  //       }else{
  //         this.data.officeHours.days[i].start = null
  //       }
  //       if(this.dateEnd != null){
  //         // $scope.endDate=$filter('date')($scope.endDate , "yyyy-MM-dd");
  //         this.data.officeHours.days[i].end =  this.utcDateConverstion(this.dateEnd, 'end')
  //       }else{
  //         this.data.officeHours.days[i].end = null
  //       }
  //       var specificTimeZoneStartTime = moment()
  //       var specificTimeZoneEndTime = moment()
  //       var tempStartTime = moment(this.startTime).format('hh:mm a')
  //       if(tempStartTime === "Invalid date"){
  //         tempStartTime = this.startTime
  //       }
  //       var tempEndTime = moment(this.endTime).format('hh:mm a')
  //       if(tempEndTime === "Invalid date"){
  //         tempEndTime = this.endTime
  //       }
  //       specificTimeZoneStartTime = moment.tz(tempStartTime, 'hh:mm a' , this.configuration.country.timezone)
  //       var displayStartTime = moment(specificTimeZoneStartTime).format('hh:mm a')
  //       specificTimeZoneStartTime.set({'year': 1970 , 'month': 0 , "date": 1 })
  //       var sDateFormat = moment.tz(specificTimeZoneStartTime, "UTC").format('YYYY/MM/DD HH:mm:ss');
  //       var sDateUTC = moment.tz(specificTimeZoneStartTime, "UTC").format();
  //       var sTime = moment(sDateFormat).format('hh:mm a')
  //       specificTimeZoneEndTime = moment.tz(tempEndTime, 'hh:mm a' , this.configuration.country.timezone)
  //       var displayEndTime = moment(specificTimeZoneEndTime).format('hh:mm a')
  //       specificTimeZoneEndTime.set({'year': 1970 , 'month': 0 , "date": 1 })
  //       var eDateFormat = moment.tz(specificTimeZoneEndTime, "UTC").format('YYYY/MM/DD HH:mm:ss');
  //       var eDateUTC = moment.tz(specificTimeZoneEndTime, "UTC").format();
  //       var eTime = moment(eDateFormat).format('hh:mm a')
  //       this.data.officeHours.days[i].isActive = true
  //       this.data.officeHours.days[i].timeOpen = sDateUTC
  //       this.data.officeHours.days[i].timeClosed = eDateUTC
  //       this.data.officeHours.days[i].timeOpenUTC = sDateUTC
  //       this.data.officeHours.days[i].timeClosedUTC = eDateUTC
  //       this.data.officeHours.days[i].startTime = displayStartTime
  //       this.data.officeHours.days[i].endTime = displayEndTime
  //       this.data.officeHours.days[i].id = i + 1
  //       this.data.schedule .push(this.data.officeHours.days[i])
  //     }
  //   }
  //   else{
  //     for( var i = 0 ; i < this.data.officeHours.days.length; i++){
  //       if (this.dateStart != null){
  //         // $scope.startDate=$filter('date')($scope.startDate , "yyyy-MM-dd");
  //         this.data.officeHours.days[i].start =  this.utcDateConverstion(this.dateStart, 'start')
  //       }
  //       if(this.dateEnd != null){
  //         // this.endDate=$filter('date')($scope.endDate , "yyyy-MM-dd");
  //         this.data.officeHours.days[i].end =  this.utcDateConverstion(this.dateEnd, 'end')
  //       }
  //       var specificTimeZoneStartTime = moment()
  //       var specificTimeZoneEndTime = moment()
  //       var tempStartTime = moment(this.data.officeHours.days[i].timeOpen).format('hh:mm a')
  //       if(tempStartTime === "Invalid date"){
  //         tempStartTime = this.data.officeHours.days[i].timeOpen
  //       }
  //       var tempEndTime = moment(this.data.officeHours.days[i].timeClosed).format('hh:mm a')
  //       if(tempEndTime === "Invalid date"){
  //         tempEndTime = this.data.officeHours.days[i].timeClosed
  //       }
  //       if(this.data.officeHours.days[i].isActive){
  //         specificTimeZoneStartTime = moment.tz(tempStartTime, 'hh:mm a' , this.configuration.country.timezone)
  //         specificTimeZoneEndTime = moment.tz(tempEndTime, 'hh:mm a' , this.configuration.country.timezone)
  //       }else{
  //         specificTimeZoneStartTime = moment.tz(this.data.officeHours.days[i].sTime, 'hh:mm a' , this.configuration.country.timezone)
  //         specificTimeZoneEndTime = moment.tz(this.data.officeHours.days[i].eTime, 'hh:mm a' , this.configuration.country.timezone)
  //       }
  //       var displayStartTime = moment(specificTimeZoneStartTime).format('hh:mm a')
  //       specificTimeZoneStartTime.set({'year': 1970 , 'month': 0, "date": 1})
  //       var sDateFormat = moment.tz(specificTimeZoneStartTime, "UTC").format('YYYY/MM/DD HH:mm:ss');
  //       var sDateUTC = moment.tz(specificTimeZoneStartTime, "UTC").format();
  //       var sTime = moment(sDateFormat).format('hh:mm a')
  //       var displayEndTime = moment(specificTimeZoneEndTime).format('hh:mm a')
  //       specificTimeZoneEndTime.set({'year': 1970 , 'month': 0, "date": 1})
  //       var eDateFormat = moment.tz(specificTimeZoneEndTime, "UTC").format('YYYY/MM/DD HH:mm:ss');
  //       var eDateUTC = moment.tz(specificTimeZoneEndTime, "UTC").format();
  //       var eTime = moment(eDateFormat).format('hh:mm a')
  //       this.data.officeHours.days[i].id = i + 1
  //       this.data.officeHours.days[i].timeOpen = sDateUTC
  //       this.data.officeHours.days[i].timeOpenUTC = sDateUTC
  //       this.data.officeHours.days[i].sTime = sTime
  //       this.data.officeHours.days[i].timeClosed = eDateUTC
  //       this.data.officeHours.days[i].timeClosedUTC = eDateUTC
  //       this.data.officeHours.days[i].eTime = eTime
  //       this.data.officeHours.days[i].startTime = displayStartTime
  //       this.data.officeHours.days[i].endTime = displayEndTime
  //       this.data.schedule .push(this.data.officeHours.days[i])
  //     }
  //   }
  //   let scheduleProduct = []
  //   let schedules = {
  //   }
  //   if(this.data.schedule.length > 0){
  //     for (var i = 0; i < this.data.schedule.length ; i++){
  //       scheduleProduct[i] = this.data.schedule[i]
  //     }
  //     const convertedObj =   _.cloneDeep(this.convertArrayToObject(this.data.schedule, 'id'))
  //     for (const item in convertedObj) {
  //       delete convertedObj[item].id
  //       delete convertedObj[item].name
  //       delete convertedObj[item].startTime
  //       delete convertedObj[item].endTime
  //       delete convertedObj[item].sTime
  //       delete convertedObj[item].eTime
  //     }
  //     schedules = convertedObj
  //   }
  //   var params = schedules
  //   this.merchantService.editProductSchedule(this.product.id, params).subscribe((data) =>{
  //     this.data.schedule.forEach((item) => {
  //       if(this.dateStart != null){
  //         item.start = this.dateStart
  //       }
  //       if(this.dateEnd != null){
  //         item.end = this.dateEnd
  //       }
  //     })
  //     this.eddress.modalService.dismissAll()
  //   })
  // }

  saveSchedule() {
    this.data.schedule = []
    this.data.scheduleDST = []
    if (this.reccurence === 'false') {
      for (let i = 0; i < this.data.officeHours.days.length; i++) {
        if (this.dateStart != null) {
          this.data.officeHours.days[i].start = this.utcDateConverstion(this.dateStart, 'start')
        } else {
          this.data.officeHours.days[i].start = null
        }
        if (this.dateEnd != null) {
          this.data.officeHours.days[i].end = this.utcDateConverstion(this.dateEnd, 'end')
        } else {
          this.data.officeHours.days[i].end = null
        }
        let specificTimeZoneStartTime = moment()
        let specificTimeZoneEndTime = moment()
        let specificTimeZoneStartTimeDST = moment()
        let specificTimeZoneEndTimeDST = moment()

        if (typeof this.startTime !== 'string') {
          this.startTime = moment(this.startTime).format('hh:mm a');
        }
        if (typeof this.endTime !== 'string') {
          this.endTime = moment(this.endTime).format('hh:mm a');
        }

        specificTimeZoneStartTime = moment.tz(this.startTime, 'hh:mm a', this.configuration.country.timezone)
        specificTimeZoneEndTime = moment.tz(this.endTime, 'hh:mm a', this.configuration.country.timezone)


        const isDTS = moment(specificTimeZoneStartTime).isDST();
        if (isDTS) {
          specificTimeZoneStartTimeDST = _.cloneDeep(moment(specificTimeZoneStartTime).subtract(1, 'hours'))
          specificTimeZoneEndTimeDST = _.cloneDeep(moment(specificTimeZoneEndTime).subtract(1, 'hours'))
        }

        const displayStartTime = moment(specificTimeZoneStartTime).format('hh:mm a')
        const sTimeUTC = moment.tz(specificTimeZoneStartTime, "UTC").format('HH:mm:ss');
        const sDateUTC = moment.tz('1970-01-01 ' + sTimeUTC, "UTC").format();

        const displayEndTime = moment(specificTimeZoneEndTime).format('hh:mm a')
        const eTimeUTC = moment.tz(specificTimeZoneEndTime, "UTC").format('HH:mm:ss');
        const eDateUTC = moment.tz('1970-01-01 ' + eTimeUTC, "UTC").format();

        this.data.officeHours.days[i].isActive = true
        this.data.officeHours.days[i].timeOpen = sDateUTC
        this.data.officeHours.days[i].timeClosed = eDateUTC
        this.data.officeHours.days[i].timeOpenUTC = sDateUTC
        this.data.officeHours.days[i].timeClosedUTC = eDateUTC
        this.data.officeHours.days[i].startTime = displayStartTime
        this.data.officeHours.days[i].endTime = displayEndTime
        this.data.officeHours.days[i].id = i + 1
        this.data.schedule.push(_.cloneDeep(this.data.officeHours.days[i]))

        // For DST
        const displayStartTimeDST = moment(specificTimeZoneStartTimeDST).format('hh:mm a')
        const sTimeUTCDST = moment.tz(specificTimeZoneStartTimeDST, "UTC").format('HH:mm:ss');
        const sDateUTCDST = moment.tz('1970-01-01 ' + sTimeUTCDST, "UTC").format();

        const displayEndTimeDST = moment(specificTimeZoneEndTimeDST).format('hh:mm a')
        const eTimeUTCDST = moment.tz(specificTimeZoneEndTimeDST, "UTC").format('HH:mm:ss');
        const eDateUTCDST = moment.tz('1970-01-01 ' + eTimeUTCDST, "UTC").format();

        this.data.officeHours.days[i].isActive = true
        this.data.officeHours.days[i].timeOpen = sDateUTCDST
        this.data.officeHours.days[i].timeClosed = eDateUTCDST
        this.data.officeHours.days[i].timeOpenUTC = sDateUTCDST
        this.data.officeHours.days[i].timeClosedUTC = eDateUTCDST
        this.data.officeHours.days[i].startTime = displayStartTimeDST
        this.data.officeHours.days[i].endTime = displayEndTimeDST
        this.data.officeHours.days[i].id = i + 1
        this.data.scheduleDST.push(_.cloneDeep(this.data.officeHours.days[i]));
      }
    } else {
      for (let i = 0; i < this.data.officeHours.days.length; i++) {
        if (this.dateStart != null) {
          this.data.officeHours.days[i].start = this.utcDateConverstion(this.dateStart, 'start')
        }
        if (this.dateEnd != null) {
          this.data.officeHours.days[i].end = this.utcDateConverstion(this.dateEnd, 'end')
        }
        let specificTimeZoneStartTime = moment()
        let specificTimeZoneEndTime = moment()
        let specificTimeZoneStartTimeDST = moment()
        let specificTimeZoneEndTimeDST = moment()

        let timeOpen;
        let timeClosed;
        if (typeof this.data.officeHours.days[i].timeOpen !== 'string') {
          timeOpen = moment(this.data.officeHours.days[i].timeOpen).format('hh:mm a');
        } else {
          timeOpen = this.data.officeHours.days[i].timeOpen
        }
        if (typeof this.data.officeHours.days[i].timeClosed !== 'string') {
          timeClosed = moment(this.data.officeHours.days[i].timeClosed).format('hh:mm a');
        } else {
          timeClosed = this.data.officeHours.days[i].timeClosed
        }

        specificTimeZoneStartTime = moment.tz(timeOpen, 'hh:mm a', this.configuration.country.timezone)
        specificTimeZoneEndTime = moment.tz(timeClosed, 'hh:mm a', this.configuration.country.timezone)

        var isDTS = moment(specificTimeZoneStartTime).isDST();
        if (isDTS) {
          specificTimeZoneStartTimeDST = _.cloneDeep(moment(specificTimeZoneStartTime).subtract(1, 'hours'))
          specificTimeZoneEndTimeDST = _.cloneDeep(moment(specificTimeZoneEndTime).subtract(1, 'hours'))
        }

        const displayStartTime = moment(specificTimeZoneStartTime).format('hh:mm a')
        const sTimeUTC = moment.tz(specificTimeZoneStartTime, "UTC").format('HH:mm:ss');
        const sDateUTC = moment.tz('1970-01-01 ' + sTimeUTC, "UTC").format();

        const displayEndTime = moment(specificTimeZoneEndTime).format('hh:mm a')
        const eTimeUTC = moment.tz(specificTimeZoneEndTime, "UTC").format('HH:mm:ss');
        const eDateUTC = moment.tz('1970-01-01 ' + eTimeUTC, "UTC").format();

        this.data.officeHours.days[i].id = i + 1
        this.data.officeHours.days[i].timeOpen = sDateUTC
        this.data.officeHours.days[i].timeOpenUTC = sDateUTC
        this.data.officeHours.days[i].timeClosed = eDateUTC
        this.data.officeHours.days[i].timeClosedUTC = eDateUTC
        this.data.officeHours.days[i].startTime = displayStartTime
        this.data.officeHours.days[i].endTime = displayEndTime
        this.data.schedule.push(_.cloneDeep(this.data.officeHours.days[i]))

        //For DST

        const displayStartTimeDST = moment(specificTimeZoneStartTimeDST).format('hh:mm a')
        const sTimeUTCDST = moment.tz(specificTimeZoneStartTimeDST, "UTC").format('HH:mm:ss');
        const sDateUTCDST = moment.tz('1970-01-01 ' + sTimeUTCDST, "UTC").format();

        const displayEndTimeDST = moment(specificTimeZoneEndTimeDST).format('hh:mm a')
        const eTimeUTCDST = moment.tz(specificTimeZoneEndTimeDST, "UTC").format('HH:mm:ss');
        const eDateUTCDST = moment.tz('1970-01-01 ' + eTimeUTCDST, "UTC").format();

        this.data.officeHours.days[i].id = i + 1
        this.data.officeHours.days[i].timeOpen = sDateUTCDST
        this.data.officeHours.days[i].timeOpenUTC = sDateUTCDST
        this.data.officeHours.days[i].timeClosed = eDateUTCDST
        this.data.officeHours.days[i].timeClosedUTC = eDateUTCDST
        this.data.officeHours.days[i].startTime = displayStartTimeDST
        this.data.officeHours.days[i].endTime = displayEndTimeDST
        this.data.scheduleDST.push(_.cloneDeep(this.data.officeHours.days[i]))
      }
    }
    let schedules = {}
    let convertedObj;
    if (isDTS) {
      convertedObj = _.cloneDeep(this.convertArrayToObject(this.data.scheduleDST, 'id'))
    } else {
      convertedObj = _.cloneDeep(this.convertArrayToObject(this.data.schedule, 'id'))
    }

    for (const item in convertedObj) {
      delete convertedObj[item].id
      delete convertedObj[item].name
      delete convertedObj[item].startTime
      delete convertedObj[item].endTime
      delete convertedObj[item].eTime
      delete convertedObj[item].sTime
    }
    schedules = convertedObj

    const params = schedules
    this.merchantService.editProductSchedule(this.product.id, params).subscribe((data) => {
      this.data.schedule.forEach((item) => {
        if (this.dateStart != null) {
          item.start = this.dateStart
        }
        if (this.dateEnd != null) {
          item.end = this.dateEnd
        }
      })
      this.eddress.modalService.dismissAll()
    })

  }

  convertArrayToObject(arr, key) {
    return arr.reduce((obj, item) => (obj[item[key]] = item, obj), {})
  };

  utcDateConverstion(date, type) {
    if (type === 'start') {
      let s = moment.tz(date, 'YYYY-MM-DD', this.configuration.country.timezone);
      let start = s.clone().startOf('day').utc();
      return moment(start).format()
    } else if (type === 'end') {
      let e = moment.tz(date, 'YYYY-MM-DD', this.configuration.country.timezone);
      let end = e.clone().endOf('day').utc();
      return moment(end).format()
    }

  }

  deleteSchedules() {
    swal({
      title: 'Are you sure you want to delete this schedule?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-red-popup',
      cancelButtonClass: 'btn btn-default',
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.data.schedule = []
        var params = {}
        this.merchantService.editProductSchedule(this.product.id, params).subscribe((data) => {
          this.eddress.successNotification('Schedule deleted successfully');
        })
      }
    });

  }

  setSelectedStartDate(value: any) {
    this.dateStart = value.year + '-' + value.month + '-' + value.day;
  }

  setSelectedEndDate(value: any) {
    this.dateEnd = value.year + '-' + value.month + '-' + value.day;
  }

  getEmptyImage() {
    return {
      url: 'assets/images/photo-camera.png',
      isBlank: true,
      imageIsEdited: false
    };
  }

  deleteImage() {
    swal({
      title: 'Are you sure you want to delete this picture?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-red-popup',
      cancelButtonClass: 'btn btn-default',
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        const emptyImg = this.getEmptyImage();
        const index = this.selectedImage.index || 0;
        const uid = this.selectedImage.uid || "";
        this.product.images[index] = emptyImg;
        this.selectedImage = emptyImg;
        this.selectedImage.index = index;
        this.selectedImage.uid = uid;
      }
    });
  }

  getBrands() {
    if (!this.merchantId) {
      this.merchantId = this.user.thirdPartyUid;
    }
    const param = {
      thirdPartyUid: this.merchantId
    }
    this.merchantService.getMarketBrands(param).subscribe(data => {
      this.data.brands = data.data;
      if (this.product) {
        if (this.product.brandName) {
          data.data.forEach(i => {
            if (i.name === this.product.brandName) {
              this.product.brand = i;
              this.product.brandId = i.id;
            }
          })
        } else {
          if (data.length > 0) {
            this.product.brand = data.data[0];
            this.product.brandId = data.data[0].id;
          }
        }
      }
      this.product_form.get('brandId').setValue(this.product.brandId)
    });
  }

  getProductTranslation(productId) {
    this.merchantService.getProductTranslation(productId).subscribe(data => {
      this.data.translations = data;
      data.forEach(d => {
        if (!Object.keys(this.data.translatedProd[d.locale]).length) {
          if (d.translations) {
            if (!d.translations["customizationItems"] || d.translations["customizationItems"] === '') d.translations["customizationItems"] = "[]";

            d.translations["customizationItems"] = JSON.parse(d.translations["customizationItems"]);
            this.data.translatedProd[d.locale] = d.translations;
          }
        }
      });
    });
  };

  getProductFeedback(productId) {
    this.merchantService.getProductFeedback(productId).subscribe(data => {
      this.data.feedbacks = data;
      this.data.feedbacks.forEach(f => {
        f.icon = f.rating ? "fa fa-thumbs-up" : "fa fa-thumbs-down";
        f.iconColor = f.rating ? "text-success" : "text-danger";
      })
    })
  };

  getCategories() {
    if (!this.merchantId) {
      this.merchantId = this.user.thirdPartyUid;
    }
    const param = {
      thirdPartyUid: this.merchantId
    }
    this.merchantService.getStoreProductCategories(param).subscribe((data) => {
      this.data.categories = data;
      if (this.product) {
        if (this.product.category) {
          data.forEach((i) => {
            if (i.name === this.product.category) {
              this.product.category = i;
              this.product.categoryId = i.id;
            }
          })
        } else {
          if (data.length > 0) {
            this.product.category = data[0];
            this.product.categoryId = data[0].id;
          }
        }
      }
      this.product_form.get('categoryId').setValue(this.product.categoryId)
    });
  }

  getMerchantStores(): void {
    const param = {
      "merchantUid": this.merchantId,
    }
    this.merchantService.getMerchantStores(param).subscribe((data) => {
      this.data.merchantStores = data;
      this.getProductDetails();
    })
  }

  getRecommendationTags() {
    this.merchantService.getMarketTags().subscribe(data => {
      if (data) {
        data.productTags = data.productTags.sort((a, b) => {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        const array = []
        data.productTags.forEach((v) => {
          array.push({name: v, id: v})
        })
        this.recommendations = array || [];
        if (this.product && this.product.recommendationTags) {
          this.product_form.get('recommendationTags').setValue(this.recommendations.filter(r => {
            return this.product.recommendationTags.includes(r.name);
          }))
        }
      }
    })
  }

  getCustomization() {
    this.merchantService.getStoreProductCustomizations(this.merchantId).subscribe((data) => {
      if (data.materialsList && data.materialsList.length) {
        this.data.customizations = data.materialsList || [];
        // this.allCustomization = data.materialsLicost || [];
        this.data.translatedCust[this.user.defaultLanguage] = this.data.customizations.map(c => {
          return {
            name: c.name,
            id: c.id
          }
        });
        this.data.customizationsDefault = _.cloneDeep(data.materialsList);
      }
    });
  };

  getCustomizationTranslation() {
    this.merchantService.getCustomizationTranslation(this.merchantId).subscribe(data => {
      this.data.customizationTranslations = data.translationList;
      this.data.customizationTranslations.forEach(t => {
        if (!this.data.translatedCust[t.locale]) this.data.translatedCust[t.locale] = [];
        if (t.translations) {
          this.data.translatedCust[t.locale].push({
            name: t.translations["name"],
            id: t.idEntity
          });
        }
      });
    })
  };

  checkCustomizationItems() {
    if (this.product_form.get('hasPriceRange').value) {
      this.customizationSections.length = 0;
      this.addNewSection();
    } else {
      this.removeSection(0)
    }
  }

  checkIfCustomizationSectionHasItems() {
    let isDisabled = false;
    this.customizationSections.forEach((item) => {
      if ((item.items && item.items.length === 0) || (item.name === ''))
        isDisabled = true;
    });
    return isDisabled;
  }

  getTranslation(selectedLang) {
    this.saveProductDataToTranslationData();
    this.selectedLanguage = selectedLang;
    this.saveTranslationDataToProductData();
  };

  saveProductDataToTranslationData() {
    let translatedProd = this.data.translatedProd[this.data.selectedLocale];
    if (!translatedProd) translatedProd = {};
    translatedProd["label"] = this.product.label;
    translatedProd["description"] = this.product.description;
    translatedProd["brandName"] = this.product.brandName;
    translatedProd["tags"] = this.product.tags;
    translatedProd["customizationItems"] = _.cloneDeep(this.customizationSections);
  }

  saveTranslationDataToProductData() {
    this.data.selectedLocale = this.eddress.utilityService.getLanguageCode(this.selectedLanguage);
    let translatedProd = this.data.translatedProd[this.data.selectedLocale];
    if (!translatedProd) translatedProd = {};
    this.product.label = translatedProd["label"] || "-";
    this.product.description = translatedProd["description"] || "-";
    this.product.brandName = translatedProd["brandName"] || "-";
    this.product.tags = translatedProd["tags"] || "-";

    this.product_form.patchValue({
      label: translatedProd["label"] || "-",
      description: translatedProd["description"] || "-",
      brandName: translatedProd["brandName"] || "-",
      tags: translatedProd["tags"] || "-",
    })

    this.product.customizationItems = translatedProd["customizationItems"] || [];

    if (!this.product.customizationItems.length && this.data.selectedLocale !== this.user.defaultLanguage) {
      const translatedProd = this.data.translatedProd[this.user.defaultLanguage];

      const customizations = _.cloneDeep(translatedProd["customizationItems"]) || [];
      const customizationsList = this.data.translatedCust[this.data.selectedLocale] || [];
      const customizationMap = {};
      customizationsList.forEach(c => {
        if (c["id"]) {
          customizationMap[c.id] = c.name;
        }
      });
      if (customizations.length > 0) {
        customizations.forEach(c => {
          if (c.items && c.items.length) {
            c.items.forEach(i => {
              if (i.materialId && customizationMap[i.materialId]) {
                i.label = customizationMap[i.materialId];
              }
            })
          }
        })
      }

      this.product.customizationItems = customizations || [];
    }
    this.customizationSections = this.product.customizationItems;
  }

  updatePrice() {
    const unit = this.product_form.get('unit').value
    let price;
    if (unit === 'g') {
      price = this.product_form.get('pricePerKilo').value * this.product_form.get('qtyPerUnit').value / 1000;
    }
    if (unit === 'kg') {
      price = this.product_form.get('pricePerKilo').value * this.product_form.get('qtyPerUnit').value;
    }
    if (unit === 'unit') {
      price = this.product_form.get('pricePerKilo').value * this.product_form.get('weight').value;
    }
    this.product_form.get('price').setValue(price);
  };

  updatePricePerKilo() {
    let p = 0, q = 0, w = 0;
    if (this.product_form.get('price').value) {
      p = parseFloat(this.product_form.get('price').value);
    }
    if (this.product_form.get('qtyPerUnit').value) {
      q = parseFloat(this.product_form.get('qtyPerUnit').value);
    }
    if (this.product_form.get('weight').value) {
      w = parseFloat(this.product_form.get('weight').value);

    }
    const unit = this.product_form.get('unit').value;
    let pricePerKilo;
    if (unit === 'g') {
      pricePerKilo = p * 1000 / q;
    }
    if (unit === 'kg') {
      pricePerKilo = p / q;
    }
    if (unit === 'unit') {
      pricePerKilo = p / w;
    }
    this.product_form.get('pricePerKilo').setValue(pricePerKilo);
    if (!this.product_form.get('pricePerKilo').value) this.product_form.get('pricePerKilo').setValue(0);
    this.product_form.get('pricePerKilo').setValue(parseFloat(this.product_form.get('pricePerKilo').value).toFixed(2));
  };

  updatePricePerKiloFraction() {
    if (!this.product_form.get('pricePerKilo').value) this.product_form.get('pricePerKilo').setValue(0);
    const pricePerKilo = parseFloat(this.product_form.get('pricePerKilo').value).toFixed(2);
    this.product_form.get('pricePerKilo').setValue(pricePerKilo)
    this.updatePriceFraction();
  }

  updatePriceFraction() {
    if (!this.product_form.get('price').value) this.product_form.get('price').setValue(0);
    const price = parseFloat(this.product_form.get('price').value).toFixed(2);
    this.product_form.get('price').setValue(price)
  }

  saveProduct() {
    if (this.product_form.invalid) {
      this.eddress.reactiveFormService.validateAllFormFields(this.product_form);
      return;
    }
    const product = this.product_form.getRawValue();

    if (product.brandId) {
      const brand = this.data.brands.filter(b => b.id === product.brandId);
      if (brand.length > 0) {
        product.brandName = brand[0].name;
      }
    }

    if (this.customizationSections) {
      this.customizationSections.forEach((section) => {
        section.isMultipleAllow = section.groupType === "CHECKBOX";
      })
    }
    this.data.selectedLocale = this.eddress.utilityService.getLanguageCode(this.selectedLanguage);
    if (this.data.translatedProd) {
      let translatedProd = this.data.translatedProd[this.data.selectedLocale];
      if (!translatedProd) translatedProd = {};
      translatedProd["label"] = product.label || null;
      translatedProd["description"] = product.description || null;
      translatedProd["brandName"] = product.brandName || null;
      translatedProd["tags"] = product.tags || null;
      translatedProd["customizationItems"] = this.customizationSections || null;
    }

    let isDuplicate = false;
    if (this.checkDuplicateInObject('label', this.customizationSections)) {
      isDuplicate = true;
    }

    this.customizationSections.forEach((section, key) => {
      if (this.checkDuplicateInObject('label', section.items)) {
        isDuplicate = true;
      }
    });

    if (isDuplicate) {
      this.duplicateError();
      return;
    }

    if (!isDuplicate) {

      let productId = " ";
      if (this.data.isEdit)
        productId = this.product.id;
      this.removeSectionIfNoProperty();

      const params = {
        "imageIsBlank": product.imageIsBlank,
        "imageIsEdited": product.imageIsEdited,
        "imageData": product.imageUrl,
        "unit": product.unit,
        "showWeight": product.showWeight,
        "hasPriceRange": product.hasPriceRange,
        "isGiftWrapProduct": product.isGiftWrapProduct,
        "qtyPerUnit": product.qtyPerUnit,
        "variablePrice": product.isVariablePrice,
        "requiresLegalAge": product.requiresLegalAge,
        "minQty": product.minQty,
        "maxQty": product.maxQty,
        "price": product.price,
        "commission": product.commission,
        "vat": product.vatPercent,
        "vatFree": product.vatFree,
        "sku": product.sku,
        "searchKeywords": product.searchKeywords,
        "recommendationLevel": product.recommendationLevel,
        "additionalInformation": product.additionalInformation,
        "withReminder": product.withReminder,
        "additives": product.additives,
        "allergens": product.allergens,
        "size": product.size,
        "barcodes": product.barcodes,
      };

      if (product.recommendationTags && product.recommendationTags.length > 0) {
        params["recommendationTags"] = product.recommendationTags.map((m) => m.name);
      }

      if (product.hasPriceRange) {
        params["customizationItems"] = this.customizationSections;
      }

      if (this.data.serviceType === 'organic') {
        params["temp"] = product.temp;
        params["origin"] = product.origin;
        params["calories"] = product.calories;
        params["shelfLife"] = product.shelfLife;
      }

      if (this.data.showSpecialInstructions) {
        params["hasSpecialInstructions"] = product.hasSpecialInstructions;
      }

      if (product.categoryId) {
        const category = this.data.categories.filter(function (c) {
          return c.id === product.categoryId;
        });
        if (category.length > 0)
          params['category'] = category[0].name;
      }

      if (product.brandId) {
        const brand = this.data.brands.filter(b => b.id === product.brandId);
        if (brand.length > 0) {
          params['brandId'] = brand[0].id;
          params['brandName'] = brand[0].name;
        }
      }
      if (product.unit === "unit") {
        params["weight"] = product.weight;
      }

      product.images = this.product.images;
      if (product.images) {
        const images: any = Array.from(product.images);
        if (images.length === 0) {
          params["imageIsBlank"] = true;
          params["imageIsEdited"] = true;
          params["imageData"] = null;
        } else {
          params["imageIsEdited"] = images[0].imageIsEdited;
          params["imageIsBlank"] = images[0].isBlank;
          params["imageData"] = images[0].url;
          images.splice(0, 1);

          if (images.length > 0) {
            const additionalImages = [];
            images.forEach(i => {
              if (!i.uid && i.isBlank) {

              } else {
                const image: any = {
                  uid: "",
                  imageData: i.url,
                  imageIsBlank: i.isBlank,
                  imageIsEdited: i.imageIsEdited
                }
                if (i.uid && i.uid !== "") {
                  image.uid = i.uid;
                }

                additionalImages.push(image);

              }
            });
            params['additionalImages'] = additionalImages;
          }
        }

      }

      params['stores'] = this.getStoreParam(product);
      params["thirdPartyUid"] = this.merchantId;



      if (this.merchantId != null) {
        if (this.data.isEdit) {
          this.data.isSaving = true;
          this.merchantService.editMarketProductItem(productId, params).subscribe((data) => {
            this.saveProductWithLocale();
            this.data.isSaving = false;
            this.eddress.successNotification('Product updated successfully');
            this.merchantProductsState.productIsLoadedState();
            this._location.back()
          })
        } else {
          params['currency'] = product.currency;
          params["label"] = product.label;
          params["description"] = product.description;
          params['customizationItems'] = this.customizationSections;
          params['tags'] = product.tags;

          this.merchantService.addMarketProductItem(params).subscribe((data) => {
            this.eddress.successNotification('Product added successfully');
            this._location.back()
          });
        }
      }
    } else {

    }
  };

  confirmDeleteProduct(): void {
    const title = 'Are you sure you want to delete ' + this.product.label + '?'
    swal({
      title: title,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-red-popup',
      cancelButtonClass: 'btn btn-default',
      buttonsStyling: false,
      customClass: 'swl-text-size'
    }).then((result) => {
      if (result.value) {
        this.deleteProduct();
      }
    });
  }

  deleteProduct() {
    const params = {
      "fields": this.eddress.utilityService.getUpdateFieldsFromObject({
        "isDeleted": true,
      })
    }
    this.merchantService.partialUpdateProduct(this.product.id, params).subscribe((d) => {
      this.eddress.successNotification('Product deleted successfully');
      this._location.back()
    })
  };

  checkDuplicateInObject(propertyName, inputArray) {
    let seenDuplicate = false;
    const testObject = {};
    inputArray.map((item) => {
      const itemPropertyName = item[propertyName];
      if (itemPropertyName in testObject) {
        testObject[itemPropertyName].duplicate = true;
        item.duplicate = true;
        seenDuplicate = true;
      } else {
        testObject[itemPropertyName] = item;
        delete item.duplicate;
      }
    });

    return seenDuplicate;
  }

  duplicateError() {
    swal({
      title: 'Duplicate Information',
      text: 'There is some Duplicate Data in your product Customization section. Please remove it.',
      type: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      confirmButtonClass: 'btn btn-red-popup',
      cancelButtonClass: 'btn btn-default',
      buttonsStyling: false
    }).then((result) => {
    });
  }

  removeSectionIfNoProperty() {
    this.customizationSections.forEach((section, key) => {
      if (section.items.length <= 0) {
        this.customizationSections.splice(key, 1);
      }
    });
  }

  getStoreParam(product) {
    if (!this.data.stores || !product.stores) return {};
    if (!product.id && this.data.stores.length === product.stores.length) return {};

    const selectedStoreIds = product.stores.map(s => s.id);
    const storeMap = {};
    this.data.stores.forEach(s => {
      let isPublished = false;
      if (selectedStoreIds.includes(s.id)) {
        isPublished = true;
      }
      storeMap[s.id] = {
        "isPublished": isPublished
      }
    });
    return storeMap;
  }

  saveProductWithLocale() {
    const products = {};

    Object.keys(this.data.translatedProd).forEach(t => {
      const product = _.cloneDeep(this.data.translatedProd[t]);
      Object.keys(product).forEach(p => {

        if (product[p] === '-' || !this.isTranslateAble(p)) delete product[p];
      });
      products[t] = product;
    });

    const param = {
      products: products
    };

    this.merchantService.editProductWithLocale(this.product.id, param).subscribe(data => {
    })

  }

  getProductDetails() {
    if (!this.product.id) return;

    this.merchantService.getProductDetails(this.product.id).subscribe(data => {
      const stores: any = Object.values(data.storeProducts)
      const storeIds = stores.map(s => s.storeId);
      this.product.stores = this.data.merchantStores.filter(s => storeIds.includes(s.id));
      this.product_form.get('stores').setValue(this.product.stores)
    })
  }

  isTranslateAble(key) {
    return ["label", "description", "brandName", "customizationItems", "tags"].includes(key);
  }

  resetCustomization(section, sectionIndex) {
    this.type = section.groupType
    section.canSelectAllItems = false;
    section.isMultipleAllow = section.groupType === "CHECKBOX";
    section.isMandatory = false;
    section.isFree = false;
    section.hasQuantity = false;
    section.minQuantity = 0
    section.maxQuantity = 0
    section.items.forEach(function (item) {
      item.hasQuantity = false;
      item.minQuantity = 0;
      item.maxQuantity = 0;

    });

    this.isSectionPropertyChanged("groupType", sectionIndex, 0)
  };

  isSectionPropertyChanged(property, sectionIndex, sectionItemIndex) {
    const selectedIndex = this.customizationSections[sectionIndex];
    Object.keys(this.data.translatedProd).forEach(t => {
      if (this.data.translatedProd[t]["customizationItems"] &&
        this.data.translatedProd[t]["customizationItems"][sectionIndex]) {
        let customizationItems = this.data.translatedProd[t]["customizationItems"][sectionIndex];
        customizationItems = this.resetSection(customizationItems, t, sectionIndex)

        if (property === 'isMultipleAllow') {
          customizationItems['isMultipleAllow'] = selectedIndex['isMultipleAllow'];
        }
        if (property === 'isFree') {
          customizationItems['isFree'] = selectedIndex['isFree'];
        }
        if (property === 'hasItemQuantity') {
          customizationItems['hasQuantity'] = selectedIndex['hasQuantity'];
          if (customizationItems['hasQuantity'] === false) {
            delete customizationItems['minQuantity']
            delete customizationItems['maxQuantity']
            delete selectedIndex['minQuantity']
            delete selectedIndex['maxQuantity']
          } else {
            customizationItems.items.forEach(function (item) {
              item['hasQuantity'] = false
              delete item['minQuantity']
              delete item['maxQuantity']
            })
            selectedIndex.items.forEach(function (item) {
              item['hasQuantity'] = false
              delete item['minQuantity']
              delete item['maxQuantity']
            })
          }
        }
        if (property === 'minItemMainSelection') {
          customizationItems['minQuantity'] = selectedIndex['minQuantity'];
        }
        if (property === 'maxItemMainSelection') {
          customizationItems['maxQuantity'] = selectedIndex['maxQuantity'];
        }
        if (property === 'isMandatory') {
          customizationItems['isMandatory'] = selectedIndex['isMandatory'];
        }
        if (property === 'minSelection') {
          customizationItems['minSelection'] = selectedIndex['minSelection'];
        }
        if (property === 'maxSelection') {
          customizationItems['maxSelection'] = selectedIndex['maxSelection'];
        }
        if (property === 'canSelectAllItems ') {
          customizationItems['canSelectAllItems'] = selectedIndex['canSelectAllItems'];
        }
        if (property === 'hasQuantity') {
          customizationItems.items[sectionItemIndex]['hasQuantity'] = selectedIndex.items[sectionItemIndex]['hasQuantity'] == true;
          if (customizationItems.items[sectionItemIndex]['hasQuantity'] === false) {
            delete customizationItems.items[sectionItemIndex]['minQuantity']
            delete customizationItems.items[sectionItemIndex]['maxQuantity']
            delete selectedIndex.items[sectionItemIndex]['minQuantity']
            delete selectedIndex.items[sectionItemIndex]['maxQuantity']
          } else {
            customizationItems['hasQuantity'] = false;
            selectedIndex ['hasQuantity'] = false;
            delete customizationItems['minQuantity']
            delete customizationItems['maxQuantity']
            delete selectedIndex['minQuantity']
            delete selectedIndex['maxQuantity']
          }
        }
        if (property === 'minQuantity') {
          customizationItems.items[sectionItemIndex]['minQuantity'] = selectedIndex.items[sectionItemIndex]['minQuantity'];
        }

        if (property === 'groupType') {
          customizationItems['groupType'] = selectedIndex['groupType'];
        }

        if (property === 'maxQuantity') {
          customizationItems.items[sectionItemIndex]['maxQuantity'] = selectedIndex.items[sectionItemIndex]['maxQuantity'];
        }
      }
    });
  };

  resetSection(customizationItems, index, selectedIndex) {
    if (this.type !== 'CHECKBOX') {
      customizationItems['maxSelection'] = null
      customizationItems['minSelection'] = null
      this.data.translatedProd[index]["customizationItems"][selectedIndex]['maxSelection'] = null
      this.data.translatedProd[index]["customizationItems"][selectedIndex]['minSelection'] = null
      this.customizationSections[selectedIndex]['maxSelection'] = null
      this.customizationSections[selectedIndex]['minSelection'] = null


    }
    return customizationItems
  }

  addNewSection() {

    if (!this.data.customizations || this.data.customizations.length === 0) {
      this.getCustomization();
      this.getCustomizationTranslation();
    }
    this.data.customizations.forEach((item) => {
      item.isAdded = false
    })
    const emptySection = {
      'label': '',
      'isMultipleAllow': false,
      'isMandatory': false,
      'isFree': false,
      'hasQuantity': false,
      'canSelectAllItems': false,
      'items': []
    };
    Object.keys(this.data.translatedProd).forEach(t => {
      if (!this.data.translatedProd[t]["customizationItems"])
        this.data.translatedProd[t]["customizationItems"] = [];
      this.data.translatedProd[t]["customizationItems"].push(emptySection);
    });
    this.customizationSections = this.data.translatedProd[this.data.selectedLocale]["customizationItems"];
  };

  addNewItem(customization) {
    Object.keys(this.data.translatedProd).forEach(t => {
      const itemObj = this.data.translatedProd[t];
      if (Object.keys(itemObj).length > 0) {
        const selectedSection = _.cloneDeep(itemObj["customizationItems"][this.sectionIdex]);

        let customizationName = customization.name;
        this.data.translatedCust[t].forEach(t => {
          if (t.id === customization.id) {
            customizationName = t["name"];
          }
        });
        selectedSection.items.push({
          'label': customizationName,
          'price': customization.price,
          'materialId': customization.id,
          'hasQuantity': false,
        });
        itemObj["customizationItems"][this.sectionIdex] = selectedSection;
      }

    });
    this.customizationSections = this.data.translatedProd[this.data.selectedLocale]["customizationItems"];
  };

  removeItem(section, index, sectionIndex) {
    Object.keys(this.data.translatedProd).forEach(t => {
      const itemObj = this.data.translatedProd[t];
      if (Object.keys(itemObj).length > 0) {
        const selectedSection = _.cloneDeep(itemObj["customizationItems"][sectionIndex]);
        if (selectedSection) {
          selectedSection.items.splice(index, 1);
          itemObj["customizationItems"][sectionIndex] = selectedSection;
        }
      }
    });
    this.customizationSections = this.data.translatedProd[this.data.selectedLocale]["customizationItems"];
  };

  removeSection(index) {
    this.customizationSections.splice(index, 1);
    Object.keys(this.data.translatedProd).forEach(t => {
      if (!this.data.translatedProd[t]["customizationItems"]) this.data.translatedProd[t]["customizationItems"] = [];
      this.data.translatedProd[t]["customizationItems"].splice(index, 1);
    });
  };

  customizationModal(sectionIndex, section) {
    this.sectionIdex = sectionIndex;
    this.selectedSection = section;

    const customizationList = _.cloneDeep(this.data.translatedCust[this.data.selectedLocale]) || [];
    const customizationMap = {};
    customizationList.forEach(c => {
      if (c["id"]) {
        customizationMap[c.id] = c.name;
      }
    });

    this.data.customizations.forEach(c => {
      c.name = customizationMap[c.id] || c.name;
    });

    if (this.customizationSections && this.customizationSections.length) {
      const materialIds = this.customizationSections[this.sectionIdex].items.map((item) => item.materialId);

      this.data.customizations.forEach((item) => {
        if (materialIds.includes(item.id)) {
          item.isAdded = true
        }
      });
    }
    this.eddress.modalService.open(this.addToCustomizationModal, {
      backdrop: 'static',
    });
  };


  initializeTranslatedProducts() {
    Object.keys(this.data.locales).forEach(l => {
      this.data.translatedProd[l] = {};
    })
  }

  initializeTranslatedCustomizations() {
    Object.keys(this.data.locales).forEach(l => {
      this.data.translatedCust[l] = [];
    })
  }

  assignCustomizationToProduct(customization) {
    customization.isAdded = true;
    // $scope.flag = false;
    this.addNewItem(customization)
  };

  removeCustomizationFromProduct(customization) {
    customization.isAdded = false;
    const item = this.customizationSections[this.sectionIdex].items.filter((item) => item.materialId === customization.id)[0]
    if (item) {
      const index = this.customizationSections[this.sectionIdex].items.indexOf(item)
      this.removeItem(this.selectedSection, index, this.sectionIdex)
    }
  };

  resetCustomizations() {
    this.data.customizations = _.cloneDeep(this.data.customizationsDefault) || [];
  };

  private _loadProductForm() {
    if (this.product.imageUrl) {
      this.isDelete = true;
    }
    if (this.product.tags && Array.isArray(this.product.tags)) {
      this.product.tags = this.product.tags.join(',');
    }
    if (this.product.barcodes && Array.isArray(this.product.barcodes)) {
      this.product.barcodes = this.product.barcodes.join(',');
    }
    this.product_form = this.fb.group({
      imageData: [null || this.product.imageUrl],
      banner: [null],

      label: [null || this.product.label, Validators.compose([Validators.required])],
      description: [null || this.product.description],
      sku: [null || this.product.sku],
      brandId: [null],
      categoryId: [null],
      commission: [null || this.product.commission],
      vatPercent: [null || this.product.vatPercent],
      vatFree: [false || this.product.vatFree],
      minQty: [1 || this.product.minQty],
      maxQty: [null || this.product.maxQty],
      isVariablePrice: [null || this.product.isVariablePrice],
      stores: [null],
      hasPriceRange: [false || this.product.hasPriceRange],
      qtyPerUnit: [1 || this.product.qtyPerUnit],
      unit: ['unit' || this.product.unit],
      pricePerKilo: [0],
      weight: [this.product.weight || 1],
      showWeight: [null || this.product.showWeight],
      price: [0 || this.product.price, Validators.compose([Validators.required])],
      isGiftWrapProduct: [null || this.product.isGiftWrapProduct],
      searchKeywords: [null || this.product.searchKeywords],
      tags: [null || this.product.tags],
      recommendationTags: [null],
      recommendationLevel: [null || this.product.recommendationLevel],
      requiresLegalAge: [null || this.product.requiresLegalAge],
      hasSpecialInstructions: [null || this.product.hasSpecialInstructions],
      withReminder: [null || this.product.withReminder],
      additionalInformation: [null || this.product.additionalInformation],
      additives: [null || this.product.additives],
      allergens: [null || this.product.allergens],
      size: [null || this.product.size],
      barcodes: [null || this.product.barcodes],
    });
    this.isFormLoaded = true;
    this.product_form.get('weight').setValue(this.product.weight || 1)
    this.updatePricePerKilo();
  }

  openDescModal() {
    this.showTextEditor = false;
    const modalRef = this.eddress.modalService.open(TextEditorModalComponent, {
      backdrop: 'static',
      size: 'lg'
    });
    modalRef.componentInstance.htmlContent = this.product_form.get('description').value;
    modalRef.componentInstance.result.subscribe((response) => {
      this.showTextEditor = true;
      if (response) {
        this.product_form.get('description').setValue(response)
      }
    });
  }

  ngOnDestroy() {
    localStorage.removeItem('productInformation');
  }
}
