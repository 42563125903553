import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
export const apiBaseUrl = new InjectionToken<string>('apiBaseUrl');
import {GenericManagementDto} from "@core/respository/genericManagementDto";
import { map } from 'rxjs/operators';
import { ApiService } from '@core/services/api.service';

@Injectable({
	providedIn: 'root'
})
export class GenericService{
  constructor(public api: ApiService ) {}

  // public getPostData(URL: string, params): Observable<GenericManagementDto> {
  //   return this.api.post(URL, params).pipe(
  //     map((data) => {
  //       return data;
  //     })
  //   );
  // }
  // public getData(URL: string, params): Observable<GenericManagementDto> {
  //   return this.api.get(URL, params).pipe(
  //     map((data) => {
  //       return data;
  //     })
  //   );
  // }
}
