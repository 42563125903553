export const APPURLS = [

  {
    appName: 'afc',
    android: 'https://play.google.com/store/apps/details?id=com.arabianfoodcorp&hl=en&gl=US',
    windows: 'https://play.google.com/store/apps/details?id=com.arabianfoodcorp',
    ios: 'https://apps.apple.com/us/app/afc-gourmet/id1502899378'
  },
  {
    appName: 'barista',
    android: 'https://play.google.com/store/apps/details?id=com.tedmob.barista&hl=en&gl=US',
    windows: 'https://play.google.com/store/apps/details?id=com.tedmob.barista',
    ios: 'https://apps.apple.com/us/app/my-barista/id1367861444'
  },
  {
    appName: 'chew',
    android: 'https://play.google.com/store/apps/details?id=com.eddress.chew&hl=en&gl=US',
    windows: 'https://play.google.com/store/apps/details?id=com.eddress.chew',
    ios: ''
  },
  {
    appName: 'enviospet',
    android: 'https://play.google.com/store/apps/details?id=com.enviospet&hl=en&gl=US',
    windows: 'https://play.google.com/store/apps/details?id=com.enviospet',
    ios: 'https://apps.apple.com/us/app/envios-pet-petshop-delivery/id1494697865'
  },
  {
    appName: 'kompremos',
    android: 'https://play.google.com/store/apps/details?id=com.enviospet&hl=en&gl=US',
    windows: 'https://play.google.com/store/apps/details?id=com.enviospet',
    ios: 'https://apps.apple.com/us/app/envios-pet-petshop-delivery/id1494697865'
  },
  {
    appName: 'yolla',
    android: 'https://play.google.com/store/apps/details?id=com.goyolla&pli=1',
    windows: 'https://play.google.com/store/apps/details?id=com.goyolla&pli=1',
    ios: 'https://apps.apple.com/pk/app/yolla-halal-supermarkt/id1634377704'
  },
  {
    appName: 'jet',
    android: 'https://play.google.com/store/apps/details?id=co.tapcart.app.id_DW6ER87KFq&',
    windows: 'https://play.google.com/store/apps/details?id=co.tapcart.app.id_DW6ER87KFq',
    ios: 'https://apps.apple.com/us/app/jet-groceries/id1472247279'
  },
  {
    appName: 'beepbeep',
    android: 'https://play.google.com/store/apps/details?id=com.beepbeepmart',
    windows: 'https://play.google.com/store/apps/details?id=com.beepbeepmart',
    ios: 'https://apps.apple.com/us/app/beepers/id1626046775'
  },
  {
    appName: 'koalo',
    android: 'https://play.google.com/store/apps/details?id=com.eddress.koalo',
    windows: 'https://play.google.com/store/apps/details?id=com.eddress.koalo',
    ios: ''
  },
  {
    appName: 'oyanow',
    android: 'https://play.google.com/store/apps/details?id=com.g360synergy.oyanow',
    windows: 'https://play.google.com/store/apps/details?id=com.g360synergy.oyanow',
    ios: 'https://apps.apple.com/us/app/oyanow/id1248784384'
  },
  {
    appName: 'pronto',
    android: 'https://play.google.com/store/apps/details?id=bm.pronto',
    windows: 'https://play.google.com/store/apps/details?id=bm.pronto',
    ios: 'https://apps.apple.com/us/app/pronto-shopping/id1621448175'
  },

];
export const APPREFERALCODEURLS = [

  {
    appName: 'afc',
    android: 'https://play.google.com/store/apps/details?id=com.arabianfoodcorp&referrer=utm_source%3Drefer%26utm_content%3D',
    windows: 'https://play.google.com/store/apps/details?id=com.arabianfoodcorp&referrer=utm_source%3Drefer%26utm_content%3D',
    ios: 'https://apps.apple.com/us/app/afc-gourmet/id1502899378'
  },
  {
    appName: 'barista',
    android: 'https://play.google.com/store/apps/details?id=com.tedmob.barista&referrer=utm_source%3Drefer%26utm_content%3D',
    windows: 'https://play.google.com/store/apps/details?id=com.tedmob.barista&referrer=utm_source%3Drefer%26utm_content%3D',
    ios: 'https://apps.apple.com/us/app/my-barista/id1367861444'
  },
  {
    appName: 'chew',
    android: 'https://play.google.com/store/apps/details?id=com.eddress.chew&referrer=utm_source%3Drefer%26utm_content%3D',
    windows: 'https://play.google.com/store/apps/details?id=com.eddress.chew&referrer=utm_source%3Drefer%26utm_content%3D',
    ios: ''
  },
  {
    appName: 'enviospet',
    android: 'https://play.google.com/store/apps/details?id=com.enviospet&referrer=utm_source%3Drefer%26utm_content%3D',
    windows: 'https://play.google.com/store/apps/details?id=com.enviospet&referrer=utm_source%3Drefer%26utm_content%3D',
    ios: 'https://apps.apple.com/us/app/envios-pet-petshop-delivery/id1494697865'
  },
  {
    appName: 'kompremos',
    android: 'https://play.google.com/store/apps/details?id=com.enviospet&referrer=utm_source%3Drefer%26utm_content%3D',
    windows: 'https://play.google.com/store/apps/details?id=com.enviospet&referrer=utm_source%3Drefer%26utm_content%3D',
    ios: 'https://apps.apple.com/us/app/envios-pet-petshop-delivery/id1494697865'
  },
  {
    appName: 'yolla',
    android: 'https://play.google.com/store/apps/details?id=com.goyolla&referrer=utm_source%3Drefer%26utm_content%3D',
    windows: 'https://play.google.com/store/apps/details?id=com.goyolla&referrer=utm_source%3Drefer%26utm_content%3D',
    ios: 'https://apps.apple.com/pk/app/yolla-halal-supermarkt/id1634377704'
  },
  {
    appName: 'jet',
    android: 'https://play.google.com/store/apps/details?id=co.tapcart.app.id_DW6ER87KFq&referrer=utm_source%3Drefer%26utm_content%3D',
    windows: 'https://play.google.com/store/apps/details?id=co.tapcart.app.id_DW6ER87KFq&referrer=utm_source%3Drefer%26utm_content%3D',
    ios: 'https://apps.apple.com/us/app/jet-groceries/id1472247279'
  },
  {
    appName: 'beepbeep',
    android: 'https://play.google.com/store/apps/details?id=com.beepbeepmart&referrer=utm_source%3Drefer%26utm_content%3D',
    windows: 'https://play.google.com/store/apps/details?id=com.beepbeepmart&referrer=utm_source%3Drefer%26utm_content%3D',
    ios: 'https://apps.apple.com/us/app/beepers/id1626046775'
  },
  {
    appName: 'koalo',
    android: 'https://play.google.com/store/apps/details?id=com.eddress.koalo&referrer=utm_source%3Drefer%26utm_content%3D',
    windows: 'https://play.google.com/store/apps/details?id=com.eddress.koalo&referrer=utm_source%3Drefer%26utm_content%3D',
    ios: ''
  },
  {
    appName: 'oyanow',
    android: 'https://play.google.com/store/apps/details?id=com.g360synergy.oyanow&referrer=utm_source%3Drefer%26utm_content%3D',
    windows: 'https://play.google.com/store/apps/details?id=com.g360synergy.oyanow&referrer=utm_source%3Drefer%26utm_content%3D',
    ios: 'https://apps.apple.com/us/app/oyanow/id1248784384'
  },
  {
    appName: 'pronto',
    android: 'https://play.google.com/store/apps/details?id=bm.pronto&referrer=utm_source%3Drefer%26utm_content%3D',
    windows: 'https://play.google.com/store/apps/details?id=bm.pronto&referrer=utm_source%3Drefer%26utm_content%3D',
    ios: 'https://apps.apple.com/us/app/pronto-shopping/id1621448175'
  },

];