import { Component, OnInit } from '@angular/core';
import { EddressFacadeService } from '@core/services/eddressFacade.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
	title = 'Eddress';

	constructor(
		public eddress: EddressFacadeService,
    private translate: TranslateService
	) {
		this.eddress.populateUser();
	}

	ngOnInit(): void {
    	this.translate.setDefaultLang("en");
    	this.translate.use("en");
   }
}
