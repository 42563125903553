import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Worker} from '@core/models/worker.model';
import {OrderData} from '../models/orderData.model';
import {AuthService} from '@core/services/auth.service';
import * as moment from 'moment';
import {RedirectRoutes} from '@core/models/userRoles.enum';
import {saveAs} from 'file-saver';
import {DateFilter} from "@core/models/dateFilter.model";

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  user;

  constructor(private authService: AuthService) {
    this.authService.currentUser.subscribe((user) => {
      this.user = user;
    });
  }

  trackByFn(index, item) {
    if (item) {
      return item.id;
    }
  }

  trimFirstString(s) {
    if (s.includes(',')) {
      let res = s.split(',');
      return res[0];
    } else if (s.includes('-')) {
      let res = s.split('-');
      return res[0];
    } else {
      return s;
    }
  }

  getInitials(string) {
    const names = string.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  mapInitilize(map) {
    // Create a new StyledMapType object, passing it an array of styles,
    // and the name to be displayed on the map type control.
    const styledMapType = new google.maps.StyledMapType(
      [
        {
          featureType: 'administrative',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'landscape',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified'
            },
            {
              hue: '#0066ff'
            },
            {
              saturation: 74
            },
            {
              lightness: 100
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.government',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.business',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            },
            {
              weight: 0.6
            },
            {
              saturation: -85
            },
            {
              lightness: 61
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'transit',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified'
            },
            {
              color: '#5f94ff'
            },
            {
              lightness: 26
            },
            {
              gamma: 5.86
            }
          ]
        }
      ],
      {name: 'Styled Map'}
    );

    // Associate the styled map with the MapTypeId and set it to display.
    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');
  }

  orderDetailMapInitialize(map) {
    // Create a new StyledMapType object, passing it an array of styles,
    // and the name to be displayed on the map type control.
    const styledMapType = new google.maps.StyledMapType(
      [
        {
          featureType: 'administrative',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'landscape',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified'
            },
            {
              hue: '#0066ff'
            },
            {
              saturation: 74
            },
            {
              lightness: 100
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'poi.government',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'poi.business',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            },
            {
              weight: 0.6
            },
            {
              saturation: -85
            },
            {
              lightness: 61
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'transit',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified'
            },
            {
              color: '#5f94ff'
            },
            {
              lightness: 26
            },
            {
              gamma: 5.86
            }
          ]
        }
      ],
      {name: 'Styled Map'}
    );

    // Associate the styled map with the MapTypeId and set it to display.
    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');
  }

  trackingMapInitilize(map) {
    // Create a new StyledMapType object, passing it an array of styles,
    // and the name to be displayed on the map type control.
    const styledMapType = new google.maps.StyledMapType(
      [
        {
          featureType: 'administrative',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'landscape',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified'
            },
            {
              hue: '#0066ff'
            },
            {
              saturation: 74
            },
            {
              lightness: 100
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'poi.government',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'poi.business',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            },
            {
              weight: 0.6
            },
            {
              saturation: -85
            },
            {
              lightness: 61
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'transit',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified'
            },
            {
              color: '#5f94ff'
            },
            {
              lightness: 26
            },
            {
              gamma: 5.86
            }
          ]
        }
      ],
      {name: 'Styled Map'}
    );

    // Associate the styled map with the MapTypeId and set it to display.
    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');
  }

  courierMapInitilize(map) {
    // Create a new StyledMapType object, passing it an array of styles,
    // and the name to be displayed on the map type control.
    const styledMapType = new google.maps.StyledMapType(
      [
        {
          featureType: 'administrative',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'landscape',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified'
            },
            {
              hue: '#0066ff'
            },
            {
              saturation: 74
            },
            {
              lightness: 100
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.government',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.business',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            },
            {
              weight: 0.6
            },
            {
              saturation: -85
            },
            {
              lightness: 61
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'transit',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified'
            },
            {
              color: '#5f94ff'
            },
            {
              lightness: 26
            },
            {
              gamma: 5.86
            }
          ]
        }
      ],
      {name: 'Styled Map'}
    );

    // Associate the styled map with the MapTypeId and set it to display.
    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');
  }

  fetchAddress(geoCoder, marker): Observable<any> {
    return new Observable((observer) => {
      geoCoder.geocode(
        {
          location: {
            lat: marker.position.lat,
            lng: marker.position.lng
          }
        },
        (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              observer.next(results);
            }
          } else {
            console.log('Geocoder failed due to: ' + status);
          }
        }
      );
    });
  }

  findStreet(r) {
    for (var i = 0; i < r.length; i++) {
      var types = r[i].types;

      for (var j = 0; j < types.length; j++) {
        var type = types[j];
        if (type === 'street_address') {
          return this.trimFirstString(r[i].formatted_address);
        }
        if (type === 'route') {
          return this.trimFirstString(r[i].formatted_address);
        }
        if (type === 'intersection') {
          return this.trimFirstString(r[i].formatted_address);
        }
      }
    }
  }

  findBuilding(r) {
    for (var i = 0; i < r.length; i++) {
      var types = r[i].types;
      for (var j = 0; j < types.length; j++) {
        var type = types[j];
        if (type === 'subpremise') {
          return this.trimFirstString(r[i].formatted_address);
        }
        if (type === 'premise') {
          return this.trimFirstString(r[i].formatted_address);
        }
      }
    }
  }

  findArea(r) {
    for (var i = 0; i < r.length; i++) {
      var types = r[i].types;

      for (var j = 0; j < types.length; j++) {
        var type = types[j];
        if (type === 'neighborhood') {
          return this.trimFirstString(r[i].formatted_address);
        }
        if (type === 'sublocality') {
          return this.trimFirstString(r[i].formatted_address);
        }
        if (type === 'administrative_area_level_3') {
          return this.trimFirstString(r[i].formatted_address);
        }
        if (type === 'administrative_area_level_1') {
          return this.trimFirstString(r[i].formatted_address);
        }
        if (type === 'political') {
          return this.trimFirstString(r[i].formatted_address);
        }
        if (type === 'country') {
          return this.trimFirstString(r[i].formatted_address);
        }
      }
    }
  }

  setMapCenter(mapConfig, lat, lon) {
    return (mapConfig = {
      disableDefaultUI: false, // a way to quickly hide all controls
      mapTypeControl: false,
      scaleControl: true,
      zoomControl: true,
      controlSize: 24,
      zoom: 15.1,
      center: {
        lat: lat,
        lng: lon
      }
    });
  }

  removeCountryCode(phoneNumber, countryCode: string) {
    if (phoneNumber && countryCode) {
      const result = phoneNumber.match(countryCode);
      if (result && result.index === 1) {
        const length = countryCode.toString().length + 1;
        return phoneNumber.substring(length);
      } else {
        return phoneNumber
      }
    }

  }

  addCountryCode(phoneNumber, countryCode) {
    if (!countryCode) {
      return;
    }
    if (phoneNumber) {
      return "+" + countryCode + phoneNumber;
    } else {
      return phoneNumber;
    }
  }

  getSecondsPassed(dateTime) {
    const today: any = new Date();
    const fromDate: any = new Date(dateTime);
    const diffMs = today - fromDate;
    return Math.abs(diffMs / 1000);
  }

  getSecondsRemaining(dateTime) {
    const today: any = new Date();
    const fromDate: any = new Date(dateTime);
    const diffMs = fromDate - today;
    return Math.abs(diffMs / 1000);
  }

  secondsToDateObj(seconds: number) {
    const time = new Date();
    time.setHours(0, 0, seconds, 0);
    return time;
  }

  insideZone(lat, lng, vs) {
    const x = lat,
      y = lng;

    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      let xi = vs[i]['lat'],
        yi = vs[i]['lon'];
      let xj = vs[j]['lat'],
        yj = vs[j]['lon'];

      let intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  }

  adoptObject(address) {
    return {
      address: {
        locality: address.locality,
        notes: address.notes,
        name: address.name,
        building: address.building,
        buildingBlock: address.buildingBlock,
        unit: address.unit,
        street: address.street,
        coordinates: {
          lat: address.coordinates.lat,
          lon: address.coordinates.lon
        }
      }
    };
  }

  buildAddressDetailInfo(addressList) {
    addressList.forEach((address) => {
      if (!address.getAddressDetailInfo) {
        address.getAddressDetailInfo =
          address.name ||
          '' + ', ' + address.locality ||
          '' + ', ' + address.street ||
          '' + address.building ||
          '' + ', ' + address.buildingBlock ||
          '' + address.unit ||
          '' + ', ' + address.notes ||
          '';
      }
    });
    return addressList;
  }

  buildAddressDetails(address) {
    return address.locality + ', ' + address.notes || '';
  }

  getFilterWorkers(workersList: Worker[], order: OrderData): Worker[] {
    let filteredDrivers = [];
    workersList.forEach((element) => {
      if (order.dispatch.activeWorker && order.dispatch.activeWorker) {
        element.assigned = element.uid === order.dispatch.activeWorker.uid;
      }
      const pinLat = order.dispatch.pickup.address.coordinates.lat
        ? order.dispatch.pickup.address.coordinates.lat
        : order.dispatch.dropOff.address.coordinates.lat;
      const pinLon = order.dispatch.pickup.address.coordinates.lon
        ? order.dispatch.pickup.address.coordinates.lon
        : order.dispatch.dropOff.address.coordinates.lon;

      element.dist = this.getDistance(element.lat, element.lon, pinLat, pinLon);
      if (order.idTeam && order.idTeam !== 'No Team') {
        if (
          element.assigned ||
          (element.idTeam === order.idTeam &&
            element.isVerified &&
            element.isActive &&
            (element.onDuty || !this.user.hideOffDutyDrivers))
        ) {
          filteredDrivers.push(element);
        }
      } else if (!element.idTeam) {
        if (
          element.assigned ||
          (element.isVerified &&
            element.isActive &&
            (element.onDuty || !this.user.hideOffDutyDrivers))
        ) {
          filteredDrivers.push(element);
        }
      }
    });
    filteredDrivers = filteredDrivers.sort(function (a, b) {
      return a.dist - b.dist;
    });
    return filteredDrivers;
  }

  getMarketPlaceFilterWorkers(
    workersList: Worker[],
    order: OrderData
  ): Worker[] {
    let filteredDrivers = [];
    workersList.forEach((element) => {
      if (order.dispatch.activeWorker && order.dispatch.activeWorker) {
        element.assigned = element.uid === order.dispatch.activeWorker.uid;
      }
      const pinLat = order.dispatch.pickup.address.coordinates.lat
        ? order.dispatch.pickup.address.coordinates.lat
        : order.dispatch.dropOff.address.coordinates.lat;
      const pinLon = order.dispatch.pickup.address.coordinates.lon
        ? order.dispatch.pickup.address.coordinates.lon
        : order.dispatch.dropOff.address.coordinates.lon;

      element.dist = this.getDistance(element.lat, element.lon, pinLat, pinLon);
      if (order.idTeam && order.idTeam !== 'No Team') {
        if (
          element.assigned ||
          (element.idTeam === order.idTeam &&
            element.operationUid === order.store.operationUid &&
            element.isVerified &&
            element.isActive &&
            (element.onDuty || !this.user.hideOffDutyDrivers))
        ) {
          filteredDrivers.push(element);
        }
      } else if (!element.idTeam) {
        if (
          element.assigned ||
          (element.isVerified &&
            element.isActive &&
            (element.onDuty || !this.user.hideOffDutyDrivers))
        ) {
          filteredDrivers.push(element);
        }
      }
    });
    filteredDrivers = filteredDrivers.sort(function (a, b) {
      return a.dist - b.dist;
    });
    return filteredDrivers;
  }

  getCourierFilterWorkers(
    workersList: Worker[],
    order: OrderData
  ): Worker[] {
    let filteredDrivers = [];
    workersList.forEach((element) => {
      if (order.workerUid) {
        element.assigned = element.uid === order.workerUid;
      }
      const pinLat = order.pickupLat
        ? order.pickupLat
        : order.deliveryLat;
      const pinLon = order.pickupLon
        ? order.pickupLon
        : order.deliveryLon;

      element.dist = this.getDistance(element.lat, element.lon, pinLat, pinLon);
      if (order.idTeam && order.idTeam !== 'No Team') {
        if (
          (element.idTeam === order.idTeam &&
            element.isVerified &&
            element.isActive) &&
          (element.onDuty || !this.user.hideOffDutyDrivers)
        ) {
          filteredDrivers.push(element);
        }
      } else if (!element.idTeam) {
        if (
          element.assigned ||
          (element.isVerified &&
            element.isActive) &&
          (element.onDuty || !this.user.hideOffDutyDrivers)
        ) {
          filteredDrivers.push(element);
        }
      }
    });
    filteredDrivers = filteredDrivers.sort(function (a, b) {
      return a.dist - b.dist;
    });
    return filteredDrivers;
  }

  getVerifiedWorkers(
    workersList: Worker[],
    order: OrderData
  ): Worker[] {
    let filteredDrivers = [];
    workersList.forEach((element) => {
      if (order.workerUid) {
        element.assigned = element.uid === order.workerUid;
      }
      const pinLat = order.pickupLat
        ? order.pickupLat
        : order.deliveryLat;
      const pinLon = order.pickupLon
        ? order.pickupLon
        : order.deliveryLon;

      element.dist = this.getDistance(element.lat, element.lon, pinLat, pinLon);
      if (element.isVerified && element.isActive) {
        filteredDrivers.push(element);
      }

    });
    filteredDrivers = filteredDrivers.sort(function (a, b) {
      return a.dist - b.dist;
    });
    return filteredDrivers;
  }

  getDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // km (change this constant to get miles)
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  transform(key: string = '', date: number, agoLabel: string) {
    let now = moment(new Date());
    let targetDate = moment(date);
    let elapsedTime = moment.duration(now.diff(targetDate));
    let seconds = elapsedTime['_data'].seconds;
    let minutes = elapsedTime['_data'].minutes;
    let hours = elapsedTime['_data'].hours;
    let days = elapsedTime['_data'].days;

    if (days > 0) {
      hours = hours + days * 24;
    }

    if (hours > 0) {
      if (minutes > 0) {
        if (hours > 1) {
          if (minutes > 1) {
            key = hours + ' hours ' + minutes + ' minutes ' + agoLabel;
          } else {
            key = hours + ' hours ' + minutes + ' minute' + agoLabel;
          }
        } else {
          if (minutes > 1) {
            key = hours + ' hour ' + minutes + ' minutes ' + agoLabel;
          } else {
            key = hours + ' hour ' + minutes + ' minute ' + agoLabel;
          }
        }
      } else {
        if (hours > 1) {
          key = hours + ' hours ' + agoLabel;
        } else {
          key = hours + ' hour ' + agoLabel;
        }
      }
    } else {
      if (minutes > 0) {
        if (seconds > 1 && minutes > 1) {
          key = minutes + ' minutes ' + seconds + ' seconds ' + agoLabel;
        } else if (seconds === 1 && minutes > 1) {
          key = minutes + ' minutes ' + seconds + ' second ' + agoLabel;
        } else if (seconds > 1 && minutes === 1) {
          key = minutes + ' minute ' + seconds + ' seconds ' + agoLabel;
        } else if (seconds === 1 && minutes === 1) {
          key = minutes + ' minute ' + seconds + ' second ' + agoLabel;
        }
      } else {
        if (seconds > 1) {
          key = seconds + ' seconds ' + agoLabel;
        } else {
          key = seconds + ' second ' + agoLabel;
        }
      }
    }

    return key;
  }

  transformDate(currentDate, previousDate) {
    let key = ''
    const now = moment(new Date(currentDate));
    const targetDate = moment(previousDate);
    const elapsedTime = moment.duration(now.diff(targetDate));
    const seconds = elapsedTime['_data'].seconds;
    const minutes = elapsedTime['_data'].minutes;
    const hours = elapsedTime['_data'].hours;
    let days = elapsedTime['_data'].days;
    let months = elapsedTime['_data'].months;
    const years = elapsedTime['_data'].years;
    if (years > 0) {
      months = months + (years * 12)
    }
    if (months > 0) {
      days = days + (months * 30)
    }
    if (days > 0) {
      if (hours > 0) {
        key = days + 'd ' + hours + 'h ' + minutes + 'm ';
      } else {
        key = days + 'd ' + minutes + 'm ';
      }
    } else if (hours > 0) {
      if (minutes > 0) {
        key = hours + 'h ' + minutes + 'm ';
      } else {
        key = hours + 'h ';
      }
    } else if (minutes > 0) {
      key = minutes + 'm ';
    } else if (seconds > 0) {
      key = seconds + 's ';
    } else {
      key = 'Now'
    }
    return key;
  }

  transformDateWithFullNames(currentDate, previousDate) {
    let key = ''
    const now = moment(new Date(currentDate));
    const targetDate = moment(previousDate);
    const elapsedTime = moment.duration(now.diff(targetDate));
    const seconds = elapsedTime['_data'].seconds;
    const minutes = elapsedTime['_data'].minutes;
    const hours = elapsedTime['_data'].hours;
    let days = elapsedTime['_data'].days;
    let months = elapsedTime['_data'].months;
    const years = elapsedTime['_data'].years;

    if (years > 1) {
      months += years * 12
    }
    if (months > 1) {
      days += months * 30
    }
    if (days > 0) {
      // hours = hours + days * 24;
      if (hours > 0) {
        if (days > 1) {
          if (hours > 1) {
            // key = days + ' days ' + hours + ' hours ';
            if (minutes > 1) {
              key = days + ' days ' + hours + ' hours ' + minutes + ' minutes ';
            } else {
              key = days + ' days ' + hours + ' hours ' + minutes + ' minute';
            }
          } else {
            // key = days + ' days ' + hours + ' hours';

            if (minutes > 1) {
              key = days + ' days ' + hours + ' hour ' + minutes + ' minutes ';
            } else {
              key = days + ' days ' + hours + ' hour' + minutes + ' minute';
            }
          }
        } else {
          if (hours > 1) {
            key = days + ' day ' + hours + ' hours ';
          } else {
            key = days + ' day ' + hours + ' hour ';
          }
        }
      } else {
        // if (hours > 1) {
        //   key = days + ' day ' + hours + ' hours ';
        // } else {
        //   key = days + ' day ' + hours + ' hour ';
        // }
        if (days > 1) {
          if (hours > 1) {
            // key = days + ' days ' + hours + ' hours ';
            if (minutes > 1) {
              key = days + ' days ' + hours + ' hours ' + minutes + ' minutes ';
            } else {
              key = days + ' days ' + hours + ' hours ' + minutes + ' minute';
            }
          } else {
            // key = days + ' days ' + hours + ' hours';

            if (minutes > 1) {
              key = days + ' days ' + minutes + ' minutes ';
            } else {
              key = days + ' days ' + minutes + ' minute';
            }
          }
        }


      }

    } else if (hours > 0) {
      if (minutes > 0) {
        if (hours > 1) {
          if (minutes > 1) {
            key = hours + ' hours ' + minutes + ' minutes ';
          } else {
            key = hours + ' hours ' + minutes + ' minute';
          }
        } else {
          if (minutes > 1) {
            key = hours + ' hour ' + minutes + ' minutes ';
          } else {
            key = hours + ' hour ' + minutes + ' minute ';
          }
        }
      } else {
        if (minutes > 1) {
          key = minutes + ' minutes ';
        } else {
          key = minutes + ' minute ';
        }
      }
    } else {
      if (minutes > 0) {
        if (seconds > 1 && minutes > 1) {
          key = minutes + ' minutes ' + seconds + ' seconds ';
        } else if (seconds === 1 && minutes > 1) {
          key = minutes + ' minutes ' + seconds + ' second ';
        } else if (seconds > 1 && minutes === 1) {
          key = minutes + ' minute ' + seconds + ' seconds ';
        } else if (seconds === 1 && minutes === 1) {
          key = minutes + ' minute ' + seconds + ' second ';
        }
      } else {
        if (seconds > 1) {
          key = seconds + ' seconds ';
        } else {
          key = seconds + ' second ';
        }
      }
    }

    return key;
  }

  transformToMin(key: string = '', date: number, createdDate: number) {
    let now;
    let targetDate;
    if (createdDate) {
      now = moment(date);
      targetDate = moment(createdDate);
    } else {
      now = moment(new Date());
      targetDate = moment(date);
    }

    let elapsedTime = moment.duration(now.diff(targetDate));
    let minutes = elapsedTime['_data'].minutes;
    let hours = elapsedTime['_data'].hours;
    let days = elapsedTime['_data'].days;
    if (days > 0) {
      hours = hours + days * 24;
      minutes = minutes + hours * 60;
    } else if (hours > 0) {
      minutes = minutes + hours * 60;
    }

    if (minutes > 0) {
      key = minutes + ' min';
    } else {
      if (!createdDate) {
        key = 'Now';
      }
    }

    return key;
  }

  transformToDays(key: string = '', date: number, createdDate: number) {
    let now;
    let targetDate;
    if (createdDate) {
      now = moment(date);
      targetDate = moment(createdDate);
    } else {
      now = moment(new Date());
      targetDate = moment(date);
    }

    let elapsedTime = moment.duration(now.diff(targetDate));
    let minutes = elapsedTime['_data'].minutes;
    let hours = elapsedTime['_data'].hours;
    let days = elapsedTime['_data'].days;
    if (days > 0) {
      key = days > 1 ? days + ' days' : days + ' day';
    } else if (hours > 0) {
      key = hours > 1 ? hours + ' hrs' : hours + ' hr';
    } else if (minutes > 0) {
      key = minutes > 1 ? minutes + ' mins' : minutes + ' min';
    } else {
      if (!createdDate) {
        key = '-';
      }
    }

    return key;
  }

  openNewTab(url) {
    window.open('#' + url, '_blank');
  }

  getISOData(data) {
    return moment(
      data.getTime() - data.getTimezoneOffset() * 60000
    ).toISOString();
  }

  getRedirectUrl(permissions) {
    const userRolesKeys = Object.keys(permissions);
    const matchedRoutes = [];
    for (const redirectRoutesKey in RedirectRoutes) {
      if (userRolesKeys.includes(redirectRoutesKey)) {
        matchedRoutes.push(RedirectRoutes[redirectRoutesKey]);
      }
    }
    if (matchedRoutes.length > 0) {
      matchedRoutes.sort((a, b) => {
        return a.redirectPriority - b.redirectPriority;
      });
      return matchedRoutes[0].url;
    }
  }

  getUpdateFieldsFromObject(params) {
    const keys = Object.keys(params);
    const _params = [];
    if (keys.length > 0) {
      keys.forEach(function (k) {
        _params.push({
          path: k,
          value: params[k]
        })
      })
    }
    return _params;
  }

  getLanguageCode(selectedLang) {
    if (selectedLang === "Arabic") {
      return "AR";
    } else if (selectedLang === "French") {
      return "FR";
    } else if (selectedLang === "Spanish") {
      return "ES";
    }
    if (selectedLang === "English") {
      return "EN";
    }
    if (selectedLang === "German") {
      return "DE";
    }

    if (selectedLang === "Dutch") {
      return "NL";
    }

    if (selectedLang === "Portuguese") {
      return "PT";
    }

    if (selectedLang === "Turkish") {
      return "TR";
    }
    if (selectedLang === "Japanese") {
      return "JA";
    }

    return "EN";
  }

  getLocaleValue(selectedLocaleKey) {
    if (selectedLocaleKey === "AR") {
      return "Arabic";
    } else if (selectedLocaleKey === "FR") {
      return "French";
    }
    if (selectedLocaleKey === "EN") {
      return "English";
    }
    if (selectedLocaleKey === "ES") {
      return "Spanish";
    }
    if (selectedLocaleKey === "DE") {
      return "German";
    }

    if (selectedLocaleKey === "NL") {
      return "Dutch";
    }

    if (selectedLocaleKey === "PT") {
      return "Portuguese";
    }
    if (selectedLocaleKey === "TR") {
      return "Turkish";
    }
    if (selectedLocaleKey === "JA") {
      return "Japanese";
    }
    return "English";
  }

  getVehicleTypeIcon(vehicleType) {
    if (vehicleType === 'CAR') {
      return 'fa fa-car';
    }
    if (vehicleType === 'MOTORCYCLE') {
      return 'fa fa-motorcycle';
    }
    if (vehicleType === 'TRUCK') {
      return 'fa fa-truck';
    }
    if (vehicleType === 'BICYCLE') {
      return 'fa fa-bicycle';
    }
    if (vehicleType === 'RUNNER') {
      return 'fa fa-user';
    }
    return;
  };

  floorFigure(figure, decimals) {
    if (figure) {
      if (!decimals) decimals = 2;
      var d = Math.pow(10, decimals);
      return (Number(figure * d) / d).toFixed(decimals);
    }

  };

  getLocalesDropDownList(user) {
    const langs = [];
    const locales = user.locales;

    const defaultLanguage = this.getLocaleValue(user.defaultLanguage);
    if (defaultLanguage != null && Object.keys(locales).length) {
      Object.keys(locales).forEach(function (key) {
        if (locales[key] !== defaultLanguage) {
          langs.push(locales[key]);
        }
      });
      if (langs.length === 0) {
        langs.push(defaultLanguage);
      } else {
        langs.unshift(defaultLanguage);
      }
    }

    return langs;
  };

  getUserLocales(user) {
    const langs = [];
    const locales = user.locales;
    const defaultLanguage = this.getLocaleValue(user.defaultLanguage);
    langs[user.defaultLanguage] = defaultLanguage;
    if (defaultLanguage != null && Object.keys(locales).length) {
      Object.keys(locales).forEach((key) => {
        if (locales[key] !== defaultLanguage) {
          langs[key] = locales[key];
        }
      });
    }
    return langs;
  };

  excelDownLoadAll(saveFileName, data) {
    const file = new Blob([data], {type: 'application/vnd.ms-excel'});
    saveAs(file, saveFileName);
  };

  guid() {
    return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' +
      this.s4() + '-' + this.s4() + this.s4() + this.s4();
  }

  s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  getZoneList(addDefaultZone) {
    const zoneList = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    if (addDefaultZone) {
      zoneList.unshift('Zone')
    }
    return zoneList;
  }

  convertDateTo12HoursFomat(dateToConvert) {
    const date = new Date(dateToConvert);
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    const timeString = date.toLocaleString('en-US', options);
    return timeString;
  }

  getDateRange(selectedDateFilter) {
    var params = {}
    const dateFilter = new DateFilter();
    switch (selectedDateFilter) {
      case  'TODAY' :
        params['dateBefore'] = dateFilter.fixDate(new Date());
        params['dateAfter'] = dateFilter.fixDate(new Date());
        break;
      case  'WEEK':
        params['dateBefore'] = dateFilter.fixDate(new Date());
        params['dateAfter'] = dateFilter.fixDate(dateFilter.firstDayOfWeek());
        break;
      case 'MONTH' :
        params['dateBefore'] = dateFilter.fixDate(new Date());
        params['dateAfter'] = dateFilter.fixDate(dateFilter.firstDayOfMonth());
        break;
      case 'YEAR':
        params['dateBefore'] = dateFilter.fixDate(new Date());
        params['dateAfter'] = dateFilter.fixDate(dateFilter.firstDayOfYear());
        break;
    }
    return params;
  }

  isToday(d) {
    var today = new Date();
    var inputDate = new Date(d);
    if (today.setHours(0, 0, 0, 0) === inputDate.setHours(0, 0, 0, 0)) {
      return true;
    }
    return false;
  }

  drawCircle(point, radius, dir) {
    radius = radius * 0.621371 // conver to miles
    var d2r = Math.PI / 180;   // degrees to radians
    var r2d = 180 / Math.PI;   // radians to degrees
    var earthsradius = 3963; // 3963 is the radius of the earth in miles

    var points = 32;

    // find the raidus in lat/lon
    var rlat = (radius / earthsradius) * r2d;
    var rlng = rlat / Math.cos(point.lat() * d2r);

    var extp = new Array();
    if (dir === 1) {
      var start = 0;
      var end = points + 1; // one extra here makes sure we connect the path
    } else {
      var start = points + 1;
      var end = 0;
    }
    for (var i = start; (dir === 1 ? i < end : i > end); i = i + dir) {
      var theta = Math.PI * (i / (points / 2));
      var ey = point.lng() + (rlng * Math.cos(theta)); // center a + radius x * cos(theta)
      var ex = point.lat() + (rlat * Math.sin(theta)); // center b + radius y * sin(theta)
      extp.push({lat: ex, lng: ey});
    }
    return extp;
  }

  format12To24Hours(dateTime) {
    if (!dateTime) {
      return null;
    }
    const time = dateTime.split(' ')
    const modifier = time[1]
    const hourMinutes = time[0].split(':')
    let hours = hourMinutes[0]
    const minutes = hourMinutes[1]
    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }
    return hours + ":" + minutes;
    // if (!time) {
    //   return null;
    // }
    //
    // const timeFormat = time.slice(-2);
    // let minutes = time.slice(-5, -3);
    // let hours = parseInt(time.slice(0, -5));
    // if (hours == 12) {
    //   hours = 0;
    // }
    // if (timeFormat === "PM") {
    //   hours += 12;
    // }
    // return hours + ':' + minutes;
  }

  convert24To12Hours(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  saveAs(file, saveFileName) {
    saveAs(file, saveFileName);
  };
}
