import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Merchant } from '@app/core/models/merchant.model';
import { MerchantConfig } from '@app/marketplace/merchants/models/merchantConfig.model';
import { Cities } from '@app/marketplace/merchants/models/cities.model';
import { MerchantProperties } from '@app/marketplace/merchants/models/merchantProperties.model';
import { StoreTags } from '@app/marketplace/merchants/models/storeTags.model';
import { Store } from '@app/marketplace/merchants/models/store.model';
import { Areas } from '@app/marketplace/merchants/models/areas.model';
import { Schedule } from '@app/core/models/schedule.model';
import { Collection } from '@app/core/models/collection.model';
import { MerchantCollection } from '@app/marketplace/merchants/models/merchantCollection.model';
import { StoreCapLimit } from '@app/marketplace/merchants/models/storeCapLimit.model';
import { SearchProducts } from '@app/marketplace/merchants/models/searchProducts.model';
import { Group } from '@app/marketplace/merchants/models/group.model';
import { Brand, Brands } from '@app/marketplace/merchants/models/brands.model';
import {MerchantProductsDto} from "@app/marketplace/merchants/models/product.model";
import {EddressFacadeService} from "@core/services/eddressFacade.service";
import {HttpHeaders} from "@angular/common/http";
const merchantControllerBaseUrl = 'market/admin/';
const merchantControllerBaseUrl2 = 'v2/merchant/';
const merchantControllerBaseUrl3 = 'v1/merchant/';
const merchantControllerBaseUrl4 = 'v1/area/';
const productsApiBaseUrl = "market/admin/products/";
const storesApiBaseUrl = "market/admin/stores/";
const productsExportApiBaseUrl = "v1/excelReport/market/admin/products/";
const merchantsApiBaseUrl = "market/admin/merchants/";
const collectionApiBaseUrl = "market/admin/collections/"
@Injectable({
  providedIn: 'root'
})
export class MerchantService {
  storeName = "Test";

  constructor(private api: ApiService, public eddress: EddressFacadeService) { }

  setStoreName(name) {
    this.storeName = name;
  }

  getStoreName() {
    return this.storeName;
  }

  getAllMerchants(params): Observable<Merchant[]> {
    return this.api
      .get(merchantControllerBaseUrl + 'stores/all', params)
      .pipe(map((data) => {
        return data;
      }));
  }

  saveMerchant(params): Observable<Merchant> {
    return this.api
      .post(merchantControllerBaseUrl3 + 'addNew', params)
      .pipe(map((data) => data));
  }

  editMerchantInformation(data): Observable<Merchant> {
    return this.api
      .put(merchantControllerBaseUrl + 'stores/', data)
      .pipe(map((data) => data));
  }
  upsertStoreTranslation(storeId, param): Observable<Merchant> {
    return this.api
      .post(merchantControllerBaseUrl + 'stores/' + storeId + '/translations', param)
      .pipe(map((data) => data));
  }

  getAllCities(): Observable<Cities[]> {
    return this.api
      .get(merchantControllerBaseUrl4 + 'cities')
      .pipe(map((data) => {
        return data;
      }));
  }

  getConfig(uid): Observable<MerchantConfig> {
    return this.api
      .get(merchantControllerBaseUrl + 'config/' + uid)
      .pipe(map((data) => {
        return data;
      }));
  }
   getMarketConfig(){
     return this.api
       .get('v1/marketplace/configurations')
       .pipe(map((data) => {
         return data;
       }));
   }
  changeMerchantsConfig(uid, params): Observable<MerchantConfig> {
    return this.api
      .patch(merchantControllerBaseUrl + 'config/' + uid, params)
      .pipe(map((data) => {
        return data;
      }));
  }

  changeMerchantStatus(id, params): Observable<MerchantConfig> {
    return this.api
      .patch(merchantControllerBaseUrl + 'stores/' + id, params)
      .pipe(map((data) => {
        return data;
      }));
  }

  getMerchant(id): Observable<Merchant> {
    return this.api
      .get(merchantControllerBaseUrl + 'stores/' + id + '/one')
      .pipe(map((data) => {
        return data;
      }));
  }

  getMerchantProperties(id): Observable<MerchantProperties> {
    return this.api
      .get(merchantControllerBaseUrl2 + 'properties/' + id)
      .pipe(map((data) => {
        return data;
      }));
  }

  getStoresTags(): Observable<StoreTags> {
    return this.api
      .get(merchantControllerBaseUrl + 'tags/store?loadTranslations=true')
      .pipe(map((data) =>  {
        return data;
      }));
  }

  getMerchantStores(param): Observable<Store[]> {
    return this.api
      .get(merchantControllerBaseUrl + 'stores/', param)
      .pipe(map((data) => {
        return data;
      }));
  }

  getAreas(param): Observable<Areas[]> {
    return this.api
      .get(merchantControllerBaseUrl + 'areas', param)
      .pipe(map((data) => {
        return data;
      }))
  }

  saveStore(params): Observable<Store> {
    return this.api
      .post(merchantControllerBaseUrl + "stores/", params)
      .pipe(map((data) => data));
  }

  changeStoreStatus(id, params): Observable<Store> {
    return this.api
      .put(merchantControllerBaseUrl + 'stores/' + id + '/status', params)
      .pipe(map((data) => data));
  }

  deleteStore(id): Observable<Store> {
    return this.api
    .delete(merchantControllerBaseUrl + 'stores/' + id)
    .pipe(map((data) => data));
  }

  getStoreByMerchantIdAndStoreId(merchantId, storeId): Observable<Store> {
    return this.api
      .get(merchantControllerBaseUrl + 'stores/' + merchantId + '/' + storeId)
      .pipe(map((data) => {
        return data;
      }))
  }
  getStoreById(storeId): Observable<Store> {
    return this.api
      .get(merchantControllerBaseUrl + 'stores/' + storeId)
      .pipe(map((data) => {
        return data;
      }))
  }

  editStore(id, params): Observable<Store> {
    return this.api
      .put(merchantControllerBaseUrl + 'stores/' + id, params)
      .pipe(map((data) => data));
  }

  updateSchedules(id, params): Observable<Schedule[]> {
    return this.api
      .put(merchantControllerBaseUrl + 'stores/' + id + '/schedule', params)
      .pipe(map((data) => data));
  }

  storePublishOnApp(id, params): Observable<Store> {
    return this.api
      .patch(merchantControllerBaseUrl + 'stores/' + id + '/isPublished', params)
      .pipe(map((data) => {
        return data;
      }))
  }

  isStoreClosed(id, params): Observable<Store> {
    return this.api
      .put(merchantControllerBaseUrl + 'stores/' + id + '/closeStore', params)
      .pipe(map((data) => data));
  }

  getCollectionsByThirdPartyUid(params): Observable<Collection[]> {
    return this.api
      .get(merchantControllerBaseUrl + 'collections', params)
      .pipe(map((data) => {
        return data;
      }));
  }

  isCapLimitApplied(id, params): Observable<Store> {
    return this.api
      .patch(merchantControllerBaseUrl + 'stores/' + id + '/enableCapLimit', params)
      .pipe(map((data)=> data));
  }

  uploadCapLimitByStore(params): Observable<StoreCapLimit> {
    return this.api
      .post(merchantControllerBaseUrl + 'stores/storecaplimit', params)
      .pipe(map((data) => data));
  }

  updateStoreCollection(id, params): Observable<any> {
    return this.api
      .put(merchantControllerBaseUrl + 'stores/' + id + '/collections', params)
      .pipe(map((data) => data));
  }

  deleteCollection(id): Observable<Collection> {
    return this.api
      .delete(merchantControllerBaseUrl + 'collections/' + id)
      .pipe(map((data) => data));
  }

  updateMerchantCollection(id, params): Observable<Collection> {
    return this.api
      .patch(merchantControllerBaseUrl + 'collections/' + id, params)
      .pipe(map((data) => data));
  }

  searchProducts(params): Observable<SearchProducts> {
    return this.api
      .get(merchantControllerBaseUrl + 'products/', params)
      .pipe(map((data) => {
        return data;
      }));
  }

  saveCollection(params): Observable<MerchantCollection> {
    return this.api
      .post(merchantControllerBaseUrl + 'collections', params)
      .pipe(map((data) => data));
  }

  updateCollection(id, params): Observable<MerchantCollection> {
    return this.api
      .put(merchantControllerBaseUrl + 'collections/' + id, params)
      .pipe(take(1), map((data) => data));
  }

  getCollectionById(id): Observable<Collection> {
    return this.api
      .get(merchantControllerBaseUrl + 'collections/' + id)
      .pipe(map((data) => {
        return data;
      }));
  }

  getCollectionGroups(params): Observable<Group[]> {
    return this.api
      .get(merchantControllerBaseUrl + 'collections/groups', params)
      .pipe(take(1), map((data) => {
        return data;
      }))
  }

  saveCollectionGroup(params): Observable<Group> {
    return this.api
      .post(merchantControllerBaseUrl + 'collections/groups', params)
      .pipe(take(1), map((data) => data));
  }

  updateCollectionGroup(id, params): Observable<Group> {
    return this.api
      .put(merchantControllerBaseUrl + 'collections/groups/' + id, params)
      .pipe(take(1), map((data) => data));
  }

  deleteCollectionGroup(id): Observable<Group> {
    return this.api
      .delete(merchantControllerBaseUrl + 'collections/groups/' + id)
      .pipe(take(1), map((data) => data));
  }

  getBrands(params): Observable<Brands> {
    return this.api
      .get(merchantControllerBaseUrl + 'brands', params)
      .pipe(take(1), map((data) => {
        return data;
      }));
  }

  saveBrand(params): Observable<Brand> {
    return this.api
      .post(merchantControllerBaseUrl + 'brands', params)
      .pipe(take(1), map((data) => data.data));
  }

  deleteBrand(id): Observable<Brand> {
    return this.api
      .delete(merchantControllerBaseUrl + 'brands/' + id)
      .pipe(take(1), map((data) => data));
  }

  editBrand(id, params): Observable<Brand> {
    return this.api
      .put(merchantControllerBaseUrl + 'brands/' + id, params)
      .pipe(take(1), map((data) => data.data));
  }

  getContacts(merchantUid): Observable<any> {
    return this.api
      .get('v1/merchants/' + merchantUid + '/contacts')
      .pipe(take(1), map((data) => data));
  }
  getStoresFeedback(thirdPartyUid): Observable<any> {
    return this.api
      .get(merchantControllerBaseUrl + 'stores/' + thirdPartyUid + '/feedbacks')
      .pipe(take(1), map((data) => data));
  }
  deleteFeedback(id): Observable<Brand> {
    return this.api
      .delete('v1/marketplace/rating/' + id)
      .pipe(take(1), map((data) => data));
  }
  saveContact(params): Observable<any> {
    return this.api
      .post('v1/merchants/' +   params.thirdPartyUid + '/contacts', params)
      .pipe(take(1), map((data) => data.data));
  }
  editContact(params): Observable<any> {
    return this.api
      .put('v1/merchants/' +   params.thirdPartyUid + '/contacts/' + params.uid, params)
      .pipe(take(1), map((data) => data.data));
  }
  deleteContact(merchantUid, contactUid): Observable<any> {
    return this.api
      .put('v1/merchants/' +  merchantUid + '/contacts/' + contactUid + '/delete')
      .pipe(take(1), map((data) => data));
  }
  getMerchantStoresByThirdPartyUid(params): Observable<Store[]> {
    return this.api
      .get(merchantControllerBaseUrl + 'stores/', params)
      .pipe(take(1), map((data) => {
        return data;
      }));
  }
  resendRegistrationEmail(params): Observable<any> {
    return this.api
      .post('v1/settings/resendRegistrationEmail', params)
      .pipe(take(1), map((data) => data));
  }
  getCities(thirdPartyUid) {
    return this.api
      .get(merchantControllerBaseUrl + 'merchants/' + thirdPartyUid + '/cities')
      .pipe(take(1), map((data) => {
        return data;
      }));
  }
  addCity(params, thirdPartyUid) {
    return this.api
      .post( merchantControllerBaseUrl + 'merchants/' + thirdPartyUid + '/cities', params)
      .pipe(take(1), map((data) => data));
  }

  editCity(params, thirdPartyUid) {
    return this.api
      .put( merchantControllerBaseUrl + 'merchants/' + thirdPartyUid + '/cities/' + params.id, params)
      .pipe(take(1), map((data) => data));
  }

  deleteCity(thirdPartyUid, cityId): Observable<any> {
    return this.api
      .delete(merchantControllerBaseUrl + 'merchants/' + thirdPartyUid + '/cities/' + cityId)
      .pipe(take(1), map((data) => data));
  }

  addMarketProductCategory(params) {
    return this.api
      .post( productsApiBaseUrl + 'categories', params)
      .pipe(take(1), map((data) => data));
  }
  editMarketProductCategory(categoryId, params) {
    return this.api
      .put( productsApiBaseUrl + 'categories/' + categoryId, params)
      .pipe(take(1), map((data) => data));
  }
  deleteMarketProductCategory(categoryId): Observable<any> {
    return this.api
      .delete(productsApiBaseUrl + 'categories/' + categoryId)
      .pipe(take(1), map((data) => data));
  }

  getStoreTranslation(storeId) {
    return this.api
      .get(merchantControllerBaseUrl + 'stores/' + storeId + '/translations')
      .pipe(take(1), map((data) => {
        return data;
      }));
  }
  editStoreByLocale(storeId, param) {
    return this.api
      .patch(merchantControllerBaseUrl + 'stores/' + storeId + '/locale', param)
      .pipe(take(1), map((data) => {
        return data;
      }));
  }
  editStoreByGiftWrap(storeId, params) {
    return this.api
      .put( merchantControllerBaseUrl + 'stores/' + storeId + '/giftWrapItems', params)
      .pipe(take(1), map((data) => data));
  }
  getMerchantTranslation(storeId) {
    return this.api
      .get(merchantControllerBaseUrl + 'merchants/' + storeId + '/translations')
      .pipe(take(1), map((data) => {
        return data;
      }));
  }
  editMerchantByLocale(thirdPartyUid, param) {
    return this.api
      .patch(merchantControllerBaseUrl + 'merchants/' + thirdPartyUid + '/locale', param)
      .pipe(take(1), map((data) => {
        return data;
      }));
  }
  editMerchantStores(thirdPartyUid, params) {
    return this.api
      .patch( merchantControllerBaseUrl + 'merchants/' + thirdPartyUid, params)
      .pipe(take(1), map((data) => data));
  }

  // Products Module
  getStoreProductCategories(param): Observable<any> {
    return this.api
      .get(productsApiBaseUrl + 'categories', param)
      .pipe(take(1), map((data) => data));
  }
  getStoreProductCustomizations(merchantUid): Observable<any> {
    return this.api
      .get(merchantsApiBaseUrl + merchantUid + '/materials')
      .pipe(take(1), map((data) => data));
  }
  getStoreProducts(storeId, params): Observable<MerchantProductsDto> {
    params.thirdPartyUid = storeId;

    if (params.searchString){
      //Todo, create a filterString method and call it.
      params.searchString = params.searchString.split("\\").join("");
    }
    return this.api
      .get(productsApiBaseUrl, params)
      .pipe(take(1), map((data) => {
        data.items = data.productsList;
        data.totalPages = data.pages;
        data.totalRecords = data.recordCount;
        return data;
      } ));
  };

  getStoresPriceList(productId): Observable<any> {
    return this.api
      .get(productsApiBaseUrl + productId + "/prices")
      .pipe(take(1), map((data) => data));
  }

  partialUpdateProduct(productId, params) {

    return this.api
      .patch( productsApiBaseUrl + productId, params)
      .pipe(take(1), map((data) => data));
  }
  partialUpdateStoreProduct(storeId, productId, params) {
    return this.api
      .patch( storesApiBaseUrl + storeId + "/products/" + productId, params)
      .pipe(take(1), map((data) => data));
  }

  deleteBulkProducts(productIds, params) {
    const _params = {
      "fields": this.eddress.utilityService.getUpdateFieldsFromObject(params),
      "productIds": productIds
    }
    return this.api
      .patch( productsApiBaseUrl + 'delete', _params)
      .pipe(take(1), map((data) => data));
  }
  partialUpdateBulkProducts(productIds, params) {
    const _params = {
      "fields": this.eddress.utilityService.getUpdateFieldsFromObject(params),
      "productIds": productIds
    }
    return this.api
      .patch( productsApiBaseUrl + 'bulk', _params)
      .pipe(take(1), map((data) => data));
  }
  updateBulkCollectionProducts(collectionIds, productIds) {
    const param = {
      "collectionIds": collectionIds,
      "productIds": productIds
    }
    return this.api
      .patch( merchantControllerBaseUrl + 'collections/bulk', param)
      .pipe(take(1), map((data) => data));
  }
  exportExcelStoreProducts(storeId, params) {
    params.thirdPartyUid = storeId;

    if (params.searchString){
      //Todo, create a filterString method and call it.
      params.searchString = params.searchString.split("\\").join("");
    }
    return this.api
      .getBlob(productsExportApiBaseUrl, params)
      .pipe(map((data) => data));
  };
  editMarketProductCustomization(merchantUid, customizationId, params) {
    return this.api
      .put( merchantsApiBaseUrl + merchantUid + '/materials/' + customizationId, params)
      .pipe(take(1), map((data) => data));
  }
  deleteMarketProductCustomization(merchantUid, customizationId) {
    return this.api
      .delete( merchantsApiBaseUrl + merchantUid + '/materials/' + customizationId)
      .pipe(take(1), map((data) => data));
  }
  addMarketProductCustomization(merchantUid, params) {
    return this.api
      .post( merchantsApiBaseUrl + merchantUid + '/materials', params)
      .pipe(take(1), map((data) => data));
  }
  getCustomizationTranslation(merchantUid): Observable<any> {
    return this.api
      .get(merchantsApiBaseUrl + merchantUid + '/materials/translations')
      .pipe(take(1), map((data) => data));
  }
  upsertCustomizationTranslation(merchantUid, customizationId, param) {
    return this.api
      .post( merchantsApiBaseUrl + merchantUid + '/materials/' + customizationId + '/translations', param)
      .pipe(take(1), map((data) => data));
  }
  bulkImportMarketProductItem(param, page) {
    let headers = new HttpHeaders();
    headers = headers.append('Cache-Control' , 'no-cache, no-store');
    headers = headers.append('Expires' , '-1');
    headers = headers.append('Pragma' , 'no-cache');
    return this.api
      .bulkPost( productsApiBaseUrl  + 'bulk?page=' + page, param , {headers: headers})
      .pipe(take(1), map((data) => data));
  }
  bulkUpdateMarketProductItemTranslation(param, page) {
    let headers = new HttpHeaders();
    headers = headers.append('Cache-Control' , 'no-cache, no-store');
    headers = headers.append('Expires' , '-1');
    headers = headers.append('Pragma' , 'no-cache');
    return this.api
      .bulkPost( productsApiBaseUrl  + 'bulk/updateTranslation?page=' + page, param , {headers: headers})
      .pipe(take(1), map((data) => data));
  }
  bulkUpdateMarketProductItem(param, page) {
    let headers = new HttpHeaders();
    headers = headers.append('Cache-Control' , 'no-cache, no-store');
    headers = headers.append('Expires' , '-1');
    headers = headers.append('Pragma' , 'no-cache');
    return this.api
      .bulkPut( productsApiBaseUrl  + 'bulk?page=' + page, param , {headers: headers})
      .pipe(take(1), map((data) => data));
  }
  getMarketBrands(param): Observable<any> {
    return this.api
      .get(merchantControllerBaseUrl + 'brands', param)
      .pipe(take(1), map((data) => data));
  }
  getMarketTags(): Observable<any> {
    return this.api
      .get(merchantControllerBaseUrl + 'recommendations/tags')
      .pipe(take(1), map((data) => data));
  }
  editMarketProductItem(productId, param) {
    return this.api
      .put( productsApiBaseUrl + productId, param)
      .pipe(take(1), map((data) => data));
  }
  editProductWithLocale(productId, params) {
    return this.api
      .put( productsApiBaseUrl + productId + '/locale', params)
      .pipe(take(1), map((data) => data));
  }
  addMarketProductItem(params) {
    return this.api
      .post( productsApiBaseUrl, params)
      .pipe(take(1), map((data) => data));
  }
  getProductDetails(productId) {
    return this.api
      .get(productsApiBaseUrl + productId + "/details")
      .pipe(take(1), map((data) => data));
  }
  getProductTranslation(productId) {
    return this.api
      .get(productsApiBaseUrl + productId + '/translations')
      .pipe(take(1), map((data) => data));
  }
  getProductFeedback(productId) {
    return this.api
      .get(productsApiBaseUrl + productId + '/feedbacks')
      .pipe(take(1), map((data) => data));
  };
  addClosingDates(id , params){
    return this.api
      .put(storesApiBaseUrl + id + '/schedule', params)
      .pipe(map((data) => data));
  }
  editProductSchedule(id, params){
    return this.api
      .put(productsApiBaseUrl + id + '/schedules', params)
      .pipe(map((data) => data));
  }
  editCollectionSchedule(id, params){
    return this.api
      .put(collectionApiBaseUrl + id + '/schedules', params)
      .pipe(map((data) => data));
  }
  editCollectionGroupSchedule(id, params){
    return this.api
      .put(merchantControllerBaseUrl + 'collections/groups/'  + id + '/schedules', params)
      .pipe(map((data) => data));
  }
  saveDeliveryScheduleSlots(storeId, params){
    return this.api
      .put(merchantControllerBaseUrl + 'stores/' + storeId + '/deliveryTimeSlots', params)
      .pipe(map((data) => data));
  }
  getStoreDeliveryTimeSlots(storeId) {
    return this.api
      .get(merchantControllerBaseUrl + 'stores/' + storeId + '/deliveryTimeSlots')
      .pipe(take(1), map((data) => data));
  };
}
