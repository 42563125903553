import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';
import { map, tap } from 'rxjs/operators';
import {GenericState, StateManagement} from "@core/respository/genericState";
import {GenericService} from "@core/services/generics.service";
import {Product} from "@app/marketplace/merchants/models/product.model";
import {MerchantService} from "@app/marketplace/merchants/services/merchant.service";

export interface FilterState {
  searchString: string;
  category: string;
  selectOptions: [];
  recordsPerPage: number
}

export const FILTER_STATE: FilterState = {
  searchString: '',
  category: '',
  selectOptions: [],
  recordsPerPage: 50
};

@Injectable({
	providedIn: 'root'
})
export class MerchantProductsState extends GenericState<Product> {
  public _uiFilterState: BehaviorSubject<FilterState> = <
    BehaviorSubject<FilterState>
    >new BehaviorSubject(FILTER_STATE);

	constructor( private merchantService: MerchantService, public genericService : GenericService) {
    super()
	  this._data.next(this.initialState());
	}
	public loadProducts(merchantId, params): void {

    if (params.isFilter) {
      let filterState: FilterState = this._uiFilterState.value;
      filterState = {
        ...filterState,
        searchString: params.searchString || '',
        selectOptions: params.selectOptions || [],
        category: params.category || '',
        recordsPerPage: params.recordsPerPage || ''
      };
      this._uiFilterState.next(filterState);
    }

		let state: StateManagement<Product> = this._data.value;
		if (state.isLoaded) {
			return;
		}
		this.merchantService
			.getStoreProducts(merchantId, params)
			.pipe(
				map((data) => {
					state = {
						...state,
						list: data.items,
            recordsPerPage: params.recordsPerPage,
            totalPages: data.totalPages,
            totalRecords: data.totalRecords,
            pageNumber: params.pageNumber,
						isLoaded: true
					};
					state.list = _.sortBy(state.list,  ['category', 'label']);
				}),
				tap(() => {
          this.setState(state)
				})
			)
			.subscribe();
	}

	public removeExistingProducts(alretIds) {
	  const state: StateManagement<Product> = this.getValue();
    this.removeExistingData(state, alretIds)
	}

	public updateProductState(products) {
	  const state: StateManagement<Product> = this.getValue();
    this.updateState(state, products, "label", false)
	}

	public getProductState(): Observable<any> {
    return this.getState()
	}

  public getUiFilterState(): Observable<any> {
    return this._uiFilterState.asObservable();
  }

	public productIsLoadedState(): void {
    const state: StateManagement<Product> = this.getValue();
    this.IsLoadedState(state)
	}

	public _addToProductState(product: Product) {
	  this.addToState(product, "label" , false)
	}

	public _removeFromProductState(product) {
	  const state: StateManagement<Product> = this.getValue();
    this.removeFromState(state, product)
	}

	private _removeFromProductStateBottom() {
	  this.removeFromStateBottom()
	}

	public resetProductState() {
    this.resetState()
	}

  public resetFilterState() {
    this._uiFilterState.next(_.cloneDeep(FILTER_STATE));
  }
}
