export class DateFilter {
	dateBefore: Date;
	dateAfter: Date;
	filterFrom: string;

	fixDate(date) {
		date = date.setHours(12);
		date = new Date(date);
		return date;
	}

	firstDayOfMonth() {
		const date = new Date();
		return new Date(date.getFullYear(), date.getMonth(), 1);
	}

	firstDayOfYear() {
		const date = new Date();
		return new Date(date.getFullYear(), 0, 1);
	}

	firstDayOfWeek() {
		let date = new Date();
		date = new Date(date);
		let day = date.getDay(),
			diff = date.getDate() - day + (day == 0 ? -6 : 1);
		return new Date(date.setDate(diff));
	}
}
