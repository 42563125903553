import { Injectable, Injector } from '@angular/core';
import {
	HTTP_INTERCEPTORS,
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { SweetAlertService } from '../services/sweetalert.service';
import { NotificationService } from '../services/notification.service';
import { MessagesService } from '../services/messages.service';
import 'rxjs/add/operator/do';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as _ from "lodash";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(
		private injector: Injector,
		private loaderService: LoadingBarService
	) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		this.showLoader();
		const authService = this.injector.get(AuthService);
		const router = this.injector.get(Router);
		const sweetAlert = this.injector.get(SweetAlertService);
		const message = this.injector.get(MessagesService);
		const notify = this.injector.get(NotificationService);
		const headerSettings: { [name: string]: string | string[] } = {};
		for (const key of req.headers.keys()) {
			headerSettings[key] = req.headers.getAll(key);
		}

		const token = authService.getBearerToken();
		if (token) {
			headerSettings['Authorization'] = 'Bearer ' + token;
			headerSettings['Content-Type'] = 'application/json';
		}
    if (req.body instanceof FormData) {
      // we are sending a file here
      headerSettings['Content-Type'] = 'multipart/form-data';
      delete headerSettings['Content-Type'];
    }
		const customHeaders = new HttpHeaders(headerSettings);

		const authReq = req.clone({
			headers: customHeaders
		});

		return next.handle(authReq).do(
			(event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					this.onEnd();
				}
			},
			(err: any) => {
				if (err instanceof HttpErrorResponse) {
					let msg;
					if (err.status === 401) {
						router.navigateByUrl('/portal/login');
					} else if (err.status === 500) {
						message.changeServerErrorMessage(err.error.message);
						router.navigateByUrl('/server-error/');
					} else if (err.status === 403) {
						router.navigateByUrl('/access-denied/');
					} else if (err.status === 400) {
						if(err.error.description.includes("Refund Declined") === false) {
							if (err.error instanceof Blob) {
								let reader = new FileReader();
								let errorStr;
								reader.onload = function() {
									errorStr = reader.result;
									let errorJSON = JSON.parse(errorStr);
									msg = errorJSON.message;
									if (errorJSON.description) {
										msg = errorJSON.description;
									}
									notify.errorNotification(msg);
								};
								reader.readAsText(err.error);
							} else {
								msg = err.error.message;
								if ('User already registered using this link' === msg) {
									router.navigateByUrl('/portal/login');
								}
								if (err.error.description) {
									msg = err.error.description;
								}
								notify.errorNotification(msg);
							}
						}

						// sweetAlert.sweetWarningMessage(err.error.message)
					}
					this.onEnd();
				}
			}
		);
	}
	private onEnd(): void {
		this.hideLoader();
	}
	private showLoader(): void {
		this.loaderService.start();
	}
	private hideLoader(): void {
		this.loaderService.complete();
	}
}

export const TokenInterceptorProvider = {
	provide: HTTP_INTERCEPTORS,
	useClass: TokenInterceptor,
	multi: true
};
