import {Inject, Injectable, Injector} from '@angular/core';
import {AuthService} from './auth.service';
import {UtilityService} from './utility.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {UIStateService} from './uiState.service';
import {Router} from '@angular/router';
import {map} from 'rxjs/internal/operators';
import {ApiService} from './api.service';
import {User} from '../models/user.model';
import {NotificationService} from './notification.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Worker} from '@core/models/worker.model';
import {Team, TeamsDto} from '@core/models/team.model';
import {Store, Warehouse, Zone} from '@core/models/orderData.model';
import {DOCUMENT} from '@angular/common';
import {Title} from '@angular/platform-browser';
import {Category} from '@core/models/category.model';
import {ReactiveFormService} from '@core/services/reactiveForm.service';
import {AppRoutesEnum} from '@core/models/appRoutes.enum';
import {UserRolesEnum} from '@core/models/userRoles.enum';
import {Cities} from '@app/marketplace/merchants/models/cities.model';
import {Country} from '@app/core/models/country.model';

@Injectable({
  providedIn: 'root'
})
export class EddressFacadeService {
  private _authService: AuthService;
  private description: string;
  private companyLogo: string;
  private logoIcon: string;
  private backgroundImage: string;
  private backgroundColor: string;
  appURLEnum = AppRoutesEnum;
  private user;
  private configuration;
  private configurations;
  userRolesEnum = UserRolesEnum;

  public get authService(): AuthService {
    if (!this._authService) {
      this._authService = this.injector.get(AuthService);
    }
    return this._authService;
  }

  private _api: ApiService;
  public get api(): ApiService {
    if (!this._api) {
      this._api = this.injector.get(ApiService);
    }
    return this._api;
  }

  private _utilityService: UtilityService;
  public get utilityService(): UtilityService {
    if (!this._utilityService) {
      this._utilityService = this.injector.get(UtilityService);
    }
    return this._utilityService;
  }

  private _uiStateService: UIStateService;
  public get uiStateService(): UIStateService {
    if (!this._uiStateService) {
      this._uiStateService = this.injector.get(UIStateService);
    }
    return this._uiStateService;
  }

  private _reactiveFormService: ReactiveFormService;
  public get reactiveFormService(): ReactiveFormService {
    if (!this._reactiveFormService) {
      this._reactiveFormService = this.injector.get(ReactiveFormService);
    }
    return this._reactiveFormService;
  }

  private _modalService: NgbModal;
  public get modalService(): NgbModal {
    if (!this._modalService) {
      this._modalService = this.injector.get(NgbModal);
    }
    return this._modalService;
  }

  private _notify: NotificationService;
  public get notify(): NotificationService {
    if (!this._notify) {
      this._notify = this.injector.get(NotificationService);
    }
    return this._notify;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public setCompanyLogo(logo: string) {
    this.companyLogo = logo;
  }

  public setLogoIcon(logo: string) {
    this.logoIcon = logo;
  }

  public setBackgroundImage(image: string) {
    this.backgroundImage = image;
  }

  public setBackgroundColor(color: string) {
    this.backgroundColor = color;
  }

  setAppFavicon(icon: string) {
    this._document.getElementById('appFavicon').setAttribute('href', icon);
  }

  public getDescription(): string {
    return this.description;
  }

  public getCompanyLogo(): string {
    if (!this.companyLogo) {
      this.companyLogo = localStorage.getItem('companyLogo');
    }
    return this.companyLogo;
  }

  public getLogoIcon(): string {
    return this.logoIcon;
  }

  public getBackgroundImage(): string {
    return this.backgroundImage;
  }

  public getBackgroundColor(): string {
    return this.backgroundColor;
  }

  public getCurrentUser(): Observable<any> {
    return this.authService.currentUser;
  }

  public getIsMenuCollapsed(): Observable<any> {
    return this.uiStateService.isMenuCollapsed;
  }

  public attemptAuth(loginForm): Observable<User> {
    return this.authService.attemptAuth(loginForm);
  }

  public companyConfiq(companyName: string): Observable<any> {
    return this.api
      .get('portal/fieldforce/common/companyInfo/' + companyName)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  public forgotPassword(username: string): Observable<any> {
    return this.api
      .post('portal/common/user/forgotPassword', {userName: username})
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  public verifyUser(token): Observable<any> {
    return this.api.get('portal/common/user/register/' + token).pipe(
      map((data) => {
        return data;
      })
    );
  }

  public changePassword(token, password): Observable<any> {
    return this.api
      .post('portal/common/user/changePassword', {
        token: token,
        password: password
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  public populateUser() {
    return this.authService.populateUser();
  }

  public getCities(): Observable<any> {
    return this.api.get('v1/area/cities').pipe(
      map((data) => {
        return data;
      })
    );
  }

  getCitiesFromMongo(): Observable<any> {
    return this.api
      .get('portal/common/constants/cities')
      .pipe(map((data) => data));
  }

  setCompanyInfo(data) {
    const companyIfo = data.companyInfo;
    this.setDescription(companyIfo.description);
    this.setCompanyLogo(companyIfo.companyLogo);
    this.setLogoIcon(companyIfo.logoIcon);
    this.setBackgroundImage(companyIfo.backgroundImage);
    this.setBackgroundColor(companyIfo.backgroundColor);
    if (companyIfo.favIcon) {
      this.setAppFavicon(companyIfo.favIcon);
      localStorage.setItem('favIcon', companyIfo.favIcon);
    }
    if (companyIfo.title) {
      this.titleService.setTitle(companyIfo.title);
      localStorage.setItem('title', companyIfo.title);
    }
    localStorage.setItem('companyLogo', companyIfo.companyLogo);
    localStorage.setItem('logoIcon', companyIfo.logoIcon);
  }

  public errorNotification(warning) {
    return this.notify.errorNotification(warning);
  }

  public successNotification(warning) {
    return this.notify.successNotification(warning);
  }

  public infoNotification(warning) {
    return this.notify.infoNotification(warning);
  }

  public warningNotification(warning) {
    return this.notify.warningNotification(warning);
  }

  public waitNotification(warning) {
    return this.notify.waitNotification(warning);
  }

  public announcementNotification(warning) {
    return this.notify.announcementNotification(warning);
  }

  getWorkers(): Observable<Worker[]> {
    return this.api.get('portal/fieldforce/common/workers').pipe(
      map((data) => {
        return data;
      })
    );
  }

  getCourierWorkers(): Observable<any> {
    const params: any = {
      'workerList': true
    }
    return this.api.get('v1/workers', params).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getCustomers(): Observable<any[]> {
    return this.api.get('portal/fieldforce/common/customers').pipe(
      map((data) => {
        return data;
      })
    );
  }

  getTeams(): Observable<TeamsDto> {
    return this.api.get('portal/fieldforce/common/team').pipe(
      map((data) => {
        return data;
      })
    );
  }

  getZones(): Observable<Zone[]> {
    return this.api.get('portal/fieldforce/common/zoneTurf').pipe(
      map((data) => {
        return data;
      })
    );
  }

  getWarehoues(): Observable<Warehouse[]> {
    return this.api.get('portal/fieldforce/common/warehouses').pipe(
      map((data) => {
        return data;
      })
    );
  }

  getVehicleTypes(): Observable<any> {
    return this.api
      .get('portal/clientView/thirdPartyVehicleTypes')
      .pipe(map((data) => data));
  }

  cancelOrders(params): Observable<any> {
    return this.api
      .put('portal/fieldforce/lastmile/orders/cancelOrders', params)
      .pipe(map((data) => data));
  }

  getCancelReasons(): Observable<any> {
    return this.api
      .get('market/orders/cancellationReasons')
      .pipe(map((data) => data));
  }

  getStores(): Observable<Store[]> {
    return this.api.get('market/orders/stores').pipe(
      map((data) => {
        return data;
      })
    );
  }

  getCountries(): Observable<Country[]> {
    return this.api.get('portal/common/constants/countries').pipe(
      map((data) => {
        return data;
      })
    );
  }

  getCategories(companyUid): Observable<Category[]> {
    return this.api
      .get('market/products/categories?thirdPartyUid=' + companyUid)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getAllMarketCollections() {
    return this.api.get('market/admin/collections/all').pipe(
      map((data) => {
        return data;
      })
    );
  }

  getMarketPlaceConfigurations(): Observable<any> {
    return this.api.get('v1/marketplace/configurations').pipe(
      map((data) => {
        return data;
      })
    );
  }

  getMerchantConfigurations(): Observable<any> {
    return this.api.get('v1/merchants/configurations').pipe(
      map((data) => {
        return data;
      })
    );
  }

  getLocalStorage(item) {
    return JSON.parse(localStorage.getItem(item));
  }

  getAllCities(): Observable<Cities[]> {
    return this.api
      .get('v1/area/cities')
      .pipe(map((data) => {
        return data;
      }));
  }

  getSettingCountries(): Observable<Country[]> {
    return this.api.get('v1/settings/getCountries').pipe(
      map((data) => {
        return data;
      })
    );
  }

  saveLoginHistory(params): Observable<any> {
    return this.api
      .post('portal/common/user/loginHistory', params)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getIpAddress(): Observable<any> {
    return this.http
      .get('https://api.ipify.org/?format=json&callback').pipe(
        map((data) => {
          return data;
        })
      );
  }


  getCurrency() {
    let currency = {
      iso: this.user.currencyIso,
      symbol: this.user.currencySymbol
    };
    const currencies = this.user.currencyBeanList.filter(c => c.iso === currency.iso);
    if (currencies.length > 0) {
      currency = currencies[0];
    }
    if (this.configuration && this.configuration.currency) {
      currency = this.configuration.currency;
    }
    return currency;
  }

  getCountry() {
    let country = {
      countryCode: this.user.countryCode,
      iso: this.user.countryIso,
      name: this.user.countryName
    };
    if (this.configuration && this.configuration.country) {
      country = this.configuration.country;
    }
    return country;
  }

  _setConfiguration(configurations) {
    this.configurations = configurations;
  }

  _getConfigurations() {
    return this.configurations;
  }

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private injector: Injector,
    public router: Router,
    private titleService: Title,
    private http: HttpClient,
  ) {
    this.authService.currentUser.subscribe((user) => {
      this.user = user;
      if (!localStorage.getItem('configurations')) {
        if (user.userType === UserRolesEnum.MARKETPLACE) {
          this.getMarketPlaceConfigurations().subscribe((response) => {
            this._setConfiguration(response.data);
            if (!localStorage.getItem('configurations')) {
              localStorage.setItem('configurations', JSON.stringify(response.data));
            }
          })
        } else if (user.userType === UserRolesEnum.MERCHANT) {
          this.getMerchantConfigurations().subscribe((response) => {
            this._setConfiguration(response.data);
            if (!localStorage.getItem('configurations')) {
              localStorage.setItem('configurations', JSON.stringify(response.data));
            }
          })
        }
      } else {
        this._setConfiguration(JSON.parse(localStorage.getItem('configurations')));
      }

    });

  }
}
