export interface TeamsDto {
	items: Team[];
	recordsPerPage: number;
	totalPages: number;
	totalRecords: number;
}
export class Team {
	id: string;
	uid: string;
	name: string;
	// please do not remove this isSelected property
	isSelected: boolean;

	priority: number;
	maxCapacity: number;
	groupedOrdersRadius: number;
	enableAssignToNearestWorker: boolean;
	autoDispatchTime: number;
	isActive: boolean;
	pickupZoneId: string;
	deliveryZoneId: string;
	pickupZone: Zone;
	deliveryZone: Zone;

	thirdPartyUid: string;
	merchantList: [];
	branchesList: [];
	workerBeans: [];
	driverCanBeSelected: [];
	branchesCanBeSelectedList: [];
  branchesCanBeSelectedListFiltered: any;
  selectedItemOutput: any;
	'merchants': [];
	branches: [];
	workers: [];
	canWorkInAllZones: boolean;
	teamName: string;
	teamUid: string;
	idTeam: string;
	storeIds: string[];
	capacity: number;
}
export interface Zone {
	id: string;
	thirdPartyUid: string;
	label: string;
	points: Points;
	isDeleted: boolean;
}
export interface Points {
	lat: number;
	lon: number;
	empty: boolean;
}
