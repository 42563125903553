<div class="app" [ngClass]="
    {
        'is-collapsed': app.layout.isMenuCollapsed,
        'is-opened': app.layout.isMenuOpened,
        'rtl' : app.layout.rtlActived
    }">
  <div class="layout" [ngClass]="headerSelected">

    <div [ngClass]="sidenavSelected">
      <!-- Side Nav START -->
      <div class="side-nav" sideBar>
        <div class="pointer" [ngClass]="[subDomain == 'swiffi' ? 'side-nav-inner-swiffi' : 'side-nav-inner',
         subDomain == 'gorillas' ? 'side-nav-inner-gorillas' : 'side-nav-inner']">
          <div class="side-nav-logo">
            <a id="home" class="home" (click)="goToMain()">
              <!--<img [hidden]="eddress.getCompanyLogo()" class="logo" src='assets/images/logo/logo.svg'>-->
              <!--<img [hidden]="!eddress.getCompanyLogo()" class="logo" [src]='eddress.getCompanyLogo()'>-->
              <div [hidden]="eddress.getCompanyLogo()" class="logo logo-dark"
                   style="background-image: url('assets/images/logo/logo.svg')"></div>
              <div [hidden]="!eddress.getCompanyLogo()" class="logo logo-dark"
                   [ngStyle]="{'background-image': 'url(' + eddress.getCompanyLogo() + ')'}">
              </div>

            </a>
            <div class="mobile-toggle side-nav-toggle">
              <a href="javascript:void(0);" (click)="app.layout.isMenuCollapsed = !app.layout.isMenuCollapsed">
                <i class="ti-arrow-circle-left"></i>
              </a>
            </div>
          </div>
          <ul class="side-nav-menu scrollable">

            <li clas="nav-item">
              <div class="mrg-top-30"></div>
            </li>

            <!--  MarketPlace Portal Menus Start         -->
            <li class="nav-item dropdown" [hidden]="!showOrderSection">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder"><i class="fa fa-cube"></i></span>
                <span id="marketPlaceOrders" class="title">Orders</span>
                <span class="arrow">
                                    <i class="ti-angle-right"></i>
                                </span>
              </a>
              <ul class="dropdown-menu">
                <li routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.ORDER_LIST]">
                  <a href="" [routerLink]="[ eddress.appURLEnum.MARKETPLACE_ORDERS_URL]">
                    <span class="title">List</span>
                  </a>
                </li>
                <li routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.MAP_VIEW_ORDERS]">
                  <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_ORDER_MAP_URL]">
                    <span class="title">Map</span>
                  </a>
                </li>
              </ul>
            </li>
                             <!--   Hide Teams Menu will enable it in future...          -->
            <!--            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.TEAM_LIST]">-->
            <!--              <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_TEAMS_URL]">-->
            <!--                <span class="icon-holder"> <fa-icon [icon]="faBiking"></fa-icon> </span>-->

            <!--                <span class="title">Teams</span>-->
            <!--              </a>-->
            <!--            </li>-->
            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.INVENTORY_LIST]">
              <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_INVENTORY_URL]">
                <span class="icon-holder"> <fa-icon [icon]="faHome"></fa-icon> </span>

                <span class="title">Inventory</span>
              </a>
            </li>
            <!--            <li class="nav-item dropdown" [hidden]="!showStoreSection">-->
            <!--              <a class="dropdown-toggle" href="javascript:void(0);">-->
            <!--                                <span class="icon-holder">-->
            <!--                                  <i class="ti-home"></i>-->
            <!--                                </span>-->
            <!--                <span class="title">Store</span>-->
            <!--                <span class="arrow">-->
            <!--                                    <i class="ti-angle-right"></i>-->
            <!--                                </span>-->
            <!--              </a>-->
            <!--              <ul class="dropdown-menu">-->
            <!--                <li routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.INVENTORY_LIST]">-->
            <!--                  <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_INVENTORY_URL]">-->
            <!--                    &lt;!&ndash;                    <span class="icon-holder"><i class="fa fa-ticket"></i></span>&ndash;&gt;-->
            <!--                    <span class="title">Inventory</span>-->
            <!--                  </a>-->
            <!--                </li>-->

            <!--              </ul>-->
            <!--            </li>-->
<!--            <li class="nav-item dropdown" [hidden]="!showSupportSection">-->
<!--              <a class="dropdown-toggle" href="javascript:void(0);">-->
<!--                                <span class="icon-holder">-->
<!--                                  <fa-icon [icon]="faHeadset"></fa-icon>-->
<!--                                </span>-->
<!--                <span class="title">Support</span>-->
<!--                <span class="arrow">-->
<!--                                    <i class="ti-angle-right"></i>-->
<!--                                </span>-->
<!--              </a>-->
<!--              <ul class="dropdown-menu">-->
<!--                <li routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.TICKET_LIST]">-->
<!--                  <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_INCIDENTS_URL]">-->
<!--                    &lt;!&ndash;                    <span class="icon-holder"><i class="fa fa-ticket"></i></span>&ndash;&gt;-->
<!--                    <span class="title">Incidents</span>-->
<!--                  </a>-->
<!--                </li>-->

<!--              </ul>-->
<!--            </li>-->
            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.TICKET_LIST]">
              <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_INCIDENTS_URL]">
                <span class="icon-holder">  <fa-icon [icon]="faHeadset"></fa-icon></span>
                <span class="title">Incidents</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.RATING_LIST]">
              <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_RATING_URL]">
                <span class="icon-holder"><i class="far fa-smile"></i></span>
                <span class="title">Rating</span>
                <div class="badge-red menu-text mrg-left-10 ">
                  Beta
                </div>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.TAGS_LIST]">
              <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_TAGS_URL]">
                <span class="icon-holder"><i class="fas fa-tag"></i></span>
                <span class="title">Tags</span>
              </a>
            </li>
<!--            <li class="nav-item dropdown" [hidden]="!showMarketingSection">-->
<!--              <a class="dropdown-toggle" href="javascript:void(0);">-->
<!--                                <span class="icon-holder">-->
<!--                                    <i class="fa fa-bullhorn"></i>-->
<!--                                </span>-->
<!--                <span class="title">Marketing</span>-->
<!--                <span class="arrow">-->
<!--                                    <i class="ti-angle-right"></i>-->
<!--                                </span>-->
<!--              </a>-->
<!--              <ul class="dropdown-menu">-->
<!--                <li routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.REFERRALS_VIEW]">-->
<!--                  <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_REFERRALS_OVERVIEW_URL]">-->
<!--                    &lt;!&ndash;                    <span class="icon-holder"><i class="fa fa-bullhorn"></i></span>&ndash;&gt;-->
<!--                    <span class="title">Referrals</span>-->
<!--                  </a>-->
<!--                </li>-->

<!--              </ul>-->
<!--            </li>-->
            <li clas="nav-item" routerLinkActive="active"
                *ngxPermissionsOnly="[eddress.userRolesEnum.MERCHANT_LIST_VIEW]">
              <a href=""
                 [routerLink]="[eddress.appURLEnum.MARKETPLACE_MERCHANTS_URL]">
                <span class="icon-holder"> <i class="fas fa-house-user"></i> </span>
                <span class="title">Merchants</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active"
                *ngxPermissionsOnly="[eddress.userRolesEnum.HOME_PAGES_VIEW]">
              <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_HOME_PAGE_ITEMS_URL]">
                <span class="icon-holder"><i class="fa fa-wrench"></i></span>
                <span class="title">Home Page Items</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active"
                *ngxPermissionsOnly="[eddress.userRolesEnum.TAGS_PRODUCTS_VIEW, eddress.userRolesEnum.TAGS_STORES_VIEW]">
              <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_TAGS_URL]">
                <span class="icon-holder"><i class="fas fa-tag"></i></span>
                <span class="title">Tags</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active"
                *ngxPermissionsOnly="[eddress.userRolesEnum.CUSTOMERS]">
              <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_CUSTOMERS_URL]">
                <span class="icon-holder"> <i class="ti-user"></i> </span>
                <span id="marketPlaceCustomers" class="title">Customers</span>
              </a>
            </li>
            <li class="nav-item dropdown" [hidden]="!showPromotionsSection">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder"><i class="far fa-money-bill-alt"></i></span>
                <span class="title">Promotions</span>
                <span class="arrow">
                                    <i class="ti-angle-right"></i>
                                </span>
              </a>
              <ul class="dropdown-menu">
                <li routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.PROMOTIONS_PROMO_LIST_VIEW]">
                  <a href="" [routerLink]="[ eddress.appURLEnum.MARKETPLACE_PROMO_CODE_URL]">
                    <span class="title">Promo Codes</span>
                  </a>
                </li>
                <li routerLinkActive="active"
                    *ngxPermissionsOnly="[eddress.userRolesEnum.PROMOTIONS_CAMPAIGN_LIST_VIEW]">
                  <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_CAMPAIGN_URL]">
                    <span class="title">Campaigns</span>
                  </a>
                </li>
                <li routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.PROMOTIONS_BANNER_LIST_VIEW]">
                  <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_BANNER_URL]">
                    <span class="title">Banners</span>
                  </a>
                </li>
                <li routerLinkActive="active"
                    *ngxPermissionsOnly="[eddress.userRolesEnum.PROMOTIONS_CASHBACK_LIST_VIEW]">
                  <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_CASH_BACK_URL]">
                    <span class="title">Cashback</span>
                  </a>
                </li>
              </ul>
            </li>
            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.AREA_VIEW]">
              <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_AREA_URL]">
                <span class="icon-holder"><i class="fa fa-arrows-alt"></i></span>
                <span class="title">Area</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active"
                *ngxPermissionsOnly="[eddress.userRolesEnum.RATINGS_VIEW]">
              <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_RATING_URL]">
                <span class="icon-holder"><i class="far fa-smile"></i></span>
                <span class="title">Rating</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.ALERTS]">
              <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_ALERT_URL]">
                <span class="icon-holder"> <i class="fa fa-exclamation-circle"></i> </span>

                <span class="title">Alerts</span>
              </a>
            </li>
            <!--  MarketPlace Portal Menus End         -->


            <!--  Courier Portal Menus Start         -->
            <li clas="nav-item" routerLinkActive="active"
                *ngxPermissionsOnly="[eddress.userRolesEnum.INCOMING_ORDERS_LIST]">
              <a href="" [routerLink]="[eddress.appURLEnum.COURIER_INCOMING_ORDERS_URL]">
                <span class="icon-holder"> <i class="fa fa-cube"></i> </span>
                <span class="title">Incoming Orders</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.RETURNS_VIEW]">
              <a href="" [routerLink]="[eddress.appURLEnum.COURIER_RETURN_URL]">
                <span class="icon-holder"> <i class="fa fa-undo"></i> </span>
                <span class="title">Returns</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.MAP_VIEW]">
              <a href="" [routerLink]="[eddress.appURLEnum.COURIER_MAP_URL]">
                <span class="icon-holder"><i class="fa fa-globe"></i></span>
                <span class="title">Map</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.AGENCY_LIST]">
              <a href="" [routerLink]="[eddress.appURLEnum.COURIER_AGENCY_URL]">
                <span class="icon-holder"><i class="fa fa-home"></i></span>
                <span class="title">Agency</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.CUSTOMERS_LIST]">
              <a href="" [routerLink]="[eddress.appURLEnum.COURIER_CUSTOMERS_URL]">
                <span class="icon-holder"><i class="ti-user"></i></span>
                <span class="title">Customers</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active"
                *ngxPermissionsOnly="[eddress.userRolesEnum.MY_LOCATIONS_VIEW]">
              <a href="" [routerLink]="[eddress.appURLEnum.COURIER_LOCATION_URL]">
                <span class="icon-holder"><i class="fa fa-location-arrow"></i></span>
                <span class="title">My Locations</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.WORKERS]">
              <a href="" [routerLink]="[eddress.appURLEnum.COURIER_WORKER_URL]">
                <span class="icon-holder"><i class="fas fa-truck"></i></span>
                <span class="title">Workers</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[
            eddress.userRolesEnum.REPORT_APP_ORDERS_VIEW,
            eddress.userRolesEnum.REPORT_REFERRAL_VIEW,
            eddress.userRolesEnum.REPORT_INCOMING_ORDERS_VIEW,
            eddress.userRolesEnum.REPORT_ORDERS_VIEW,
            eddress.userRolesEnum.REPORT_TASKS_VIEW,
            eddress.userRolesEnum.REPORT_WORKERS_CASH_VIEW,
            eddress.userRolesEnum.REPORT_WORKERS_TASK_VIEW,
            eddress.userRolesEnum.REPORT_WORKERS_TIME_SHEET_VIEW,
            eddress.userRolesEnum.REPORT_WORKERS_FEEDBACK_VIEW,
            eddress.userRolesEnum.REPORT_CUSTOMER_ORDERS_VIEW,
            eddress.userRolesEnum.REPORT_CUSTOMER_CASH_VIEW,
            eddress.userRolesEnum.REPORT_CUSTOMER_ORDER_TREND_VIEW,
            eddress.userRolesEnum.REPORT_WORKERS_TASKS_TREND_VIEW]">
              <a href="" [routerLink]="[eddress.appURLEnum.BOARD_AGENCY_REPORTS_URL]">
                <span class="icon-holder"><i class="fas fa-chart-bar"></i></span>
                <span class="title">Reports</span>
              </a>
            </li>
            <!--  Courier Portal Menus End         -->

            <!--    General Menus Start        -->
            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.MERCHANT]">
              <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_WAREHOUSE_DASHBOARD_URL]">
                <span class="icon-holder"><i class="ti-pie-chart"></i></span>
                <span class="title">Dashboard</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active"
                *ngxPermissionsOnly="[eddress.userRolesEnum.CLIENT_EXPRESS_VIEW]">
              <a href="" [routerLink]="[eddress.appURLEnum.CLIENT_EXPRESS_VIEW_BRANCHES_URL]">
                <span class="icon-holder"><i class="ti-package"></i></span>
                <span class="title">Branches</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active"
                *ngxPermissionsOnly="[eddress.userRolesEnum.DISPATCHER, eddress.userRolesEnum.CLIENT_EXPRESS_VIEW]">
              <a href="" [routerLink]="[eddress.appURLEnum.DISPATCHER_TEAMS_URL]">
                <span class="icon-holder"><i class="ti-package"></i></span>
                <span class="title">Dispatcher</span>
              </a>
            </li>
            <li class="nav-item dropdown" [hidden]="!showLastMileSection">
              <a class="dropdown-toggle" href="javascript:void(0);">
                                <span class="icon-holder">
                                    <i class="ei-truck"></i>
                                </span>
                <span class="title">Last Mile</span>
                <span class="arrow">
                                    <i class="ti-angle-right"></i>
                                </span>
              </a>
              <ul class="dropdown-menu">
                <li routerLinkActive="active">
                  <a *ngxPermissionsOnly="[eddress.userRolesEnum.LAST_MILE]"
                     [routerLink]="[eddress.appURLEnum.LAST_MILE_WAREHOUSE_OVERVIEW_URL]">Orders</a>

                </li>
                <li *ngxPermissionsOnly="[eddress.userRolesEnum.LAST_MILE_TEAM]" routerLinkActive="active">
                  <a [routerLink]="[eddress.appURLEnum.LAST_MILE_TEAMS_OVERVIEW_URL]">Teams</a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown" [hidden]="!showBillingSection">
              <a class="dropdown-toggle" href="javascript:void(0);">
                                <span class="icon-holder">
                                    <i class="ti-receipt"></i>
                                </span>
                <span class="title">Billing</span>
                <span class="arrow">
                                    <i class="ti-angle-right"></i>
                                </span>
              </a>
              <ul class="dropdown-menu">
                <li routerLinkActive="active">
                  <a *ngxPermissionsOnly="[eddress.userRolesEnum.CUSTOMER_BILLING]"
                     [routerLink]="[eddress.appURLEnum.BILLING_CUSTOMER_URL]">Customer</a>
                </li>
                <li *ngxPermissionsOnly="[eddress.userRolesEnum.AGENT_BILLING]" routerLinkActive="active">
                  <a [routerLink]="[eddress.appURLEnum.BILLING_AGENT_URL]">Agent</a>
                </li>
              </ul>
            </li>
            <li clas="nav-item" routerLinkActive="active"
                *ngxPermissionsOnly="[eddress.userRolesEnum.CLIENT_VIEW_ORDER]">
              <a href="" [routerLink]="[eddress.appURLEnum.CLIENT_VIEW_DASHBOARD_URL]" class="mrg-top-20">
                <span class="icon-holder"><i class="ti-pie-chart"></i></span>
                <span class="title">Dashboard</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active"
                *ngxPermissionsOnly="[eddress.userRolesEnum.CLIENT_VIEW_PROJECT]">
              <a href="" [routerLink]="[eddress.appURLEnum.CLIENT_VIEW_PROJECTS_URL]">
                <span class="icon-holder"><i class="fa fa-cube"></i></span>
                <span class="title">Projects</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active"
                *ngxPermissionsOnly="[eddress.userRolesEnum.CLIENT_VIEW_CUSTOMER]">
              <a href="" [routerLink]="[eddress.appURLEnum.CLIENT_VIEW_CUSTOMERS_URL]">
                <span class="icon-holder"><i class="ti-user"></i></span>
                <span class="title">Customers</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.INTERIM]">
              <a href="" [routerLink]="[eddress.appURLEnum.INTERIM_ORDERS_URL]">
                <span class="icon-holder"><i class="ti-pie-chart"></i></span>
                <span class="title">Interim Order</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active"
                *ngxPermissionsOnly="[eddress.userRolesEnum.MERCHANT_INCOMING_ORDERS_VIEW]">
              <a href="" [routerLink]="[eddress.appURLEnum.WAREHOUSE_INCOMING_ORDERS_VIEW_URL]">
                <span class="icon-holder"> <i class="fa fa-cube"></i> </span>
                <span class="title">Incoming orders</span>
              </a>
            </li>

            <!--Merchant Portal Menus-->
            <li clas="nav-item" routerLinkActive="active"
                *ngxPermissionsOnly="[eddress.userRolesEnum.MERCHANT_STORE_PRODUCTS_VIEW]">
              <a href="" [routerLink]="[eddress.appURLEnum.WAREHOUSE_PRODUCTS_VIEW_URL]">
                <span class="icon-holder"> <i class="fa fa-cubes"></i> </span>
                <span class="title">Products</span>
              </a>
            </li>
            <li clas="nav-item" routerLinkActive="active"
                *ngxPermissionsOnly="[eddress.userRolesEnum.MERCHANT_ORDER_REPORT_VIEW]">
              <a href="" [routerLink]="[eddress.appURLEnum.WAREHOUSE_REPORTS_VIEW_URL]">
                <span class="icon-holder"> <i class="fa fas fa-chart-bar"></i> </span>
                <span class="title">Reports</span>
              </a>
            </li>
            <!--    General Menus End        -->


            <!--    Settings and Roles        -->
            <li class="nav-item dropdown" [hidden]="!showSettingsSection">
              <a class="dropdown-toggle" href="javascript:void(0);">
                                <span class="icon-holder">
                                  <i class="ti-settings"></i>
                                </span>
                <span class="title">Settings</span>
                <span class="arrow">
                                    <i class="ti-angle-right"></i>
                                </span>
              </a>
              <ul class="dropdown-menu">
                <li routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.USER_LIST]">
                  <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_USERS_URL]">
                    <span class="title">Users</span>
                  </a>
                </li>
                <li routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.ROLE_LIST]">
                  <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_ROLES_URL]">
                    <span class="title">Roles</span>
                  </a>
                </li>
                <li routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.TICKET_CONFIG_EDIT]">
                  <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_INCIDENT_CONFIG_URL]">
                    <span class="title">Incident Configuration</span>
                  </a>
                </li>
                <li routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.APP_SETTING_VIEW]">
                  <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_APP_SETTING_URL]">
                    <span class="title">App Setting</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <!-- Side Nav END -->

      <!-- Page Container START -->
      <div class="page-container">

        <!-- Top Nav START -->
        <div class="header navbar">
          <div class="header-container flex-layout-mobile">
            <ul class="nav-left flex-layout-mobile">
              <li>
                <a *ngIf="app.layout.isMenuCollapsed" class="side-nav-toggle" href="javascript:void(0);"
                   (click)="getCollapsed(false)">
                  <i class="ti-view-grid"></i>
                </a>
                <a *ngIf="!app.layout.isMenuCollapsed" class="side-nav-toggle" href="javascript:void(0);"
                   (click)="getCollapsed(true)">
                  <i class="ti-view-grid"></i>
                </a>
              </li>
              <li class="white-space-nowrap">
                <span class="side-nav-toggle" style="line-height: 58px;">
                  <i class="page-title">{{pageTitle}}</i>
                </span>
              </li>
              <li>
                <!--<a class="btn btn-default btn-sm text-info no-background mrg-left-5 mrg-top-5">Back</a>-->
                <button [hidden]="!isBackBtn"
                        class="btn btn-info btn-inverse btn-rounded btn-sm text-info mrg-top-13 mrg-left-10 no-background-custom"
                        type="button" (click)="back()">
                  Back
                </button>
              </li>
            </ul>
            <ul class="nav-right flex-layout-mobile-right">
              <li class="mrg-top-4" [hidden]="!isMainPage"
                  *ngxPermissionsOnly="[eddress.userRolesEnum.DISPATCHER, eddress.userRolesEnum.INTERIM]">
                <button class="btn btn-info btn-inverse btn-rounded btn-sm text-info mrg-top-13 no-background-custom"
                        type="button" (click)="mainPortal()">
                  Main Portal
                </button>
              </li>
              <li class="mrg-top-4">
                <span
                  class="badge-red menu-text mrg-top-15 pull-right pdd-top-5 pdd-btm-5 pdd-left-7 pdd-right-7">Beta</span>
              </li>
              <li [hidden]="true  " *ngxPermissionsExcept="[eddress.userRolesEnum.MARKETPLACE]">
                <div class="form-group mrg-top-10">
                  <div class="input-icon  form-group mrg-left-10">
                    <i class="ti-search" style="top: 8px"></i>
                    <input type="text" class="form-control input-sm mrg-top-20" placeholder="Search">
                  </div>
                </div>
              </li>
              <li class="user-profile dropdown">
                <a class="dropdown-toggle mrg-right-15" data-toggle="dropdown" href="" aria-expanded="false">
                  <!--<img class="profile-img img-fluid" [src]="companyLogo">-->
                  <div class="user-info">
                    <span class="name pdd-right-5 hide-company-name">{{userData?.userName}}</span>
                    <i class="ti-angle-down font-size-10"></i>
                  </div>
                </a>
                <ul class="dropdown-menu">
                  <li *ngxPermissionsOnly="[eddress.userRolesEnum.DISPATCHER]">
                    <a href="" [routerLink]="[eddress.appURLEnum.ADMIN_SETTINGS]">
                  <span class="icon-holder">
                    <i class="ti-settings pdd-right-10"></i></span>
                      <span class="title">Setting</span>
                    </a>
                  </li>
                  <li
                    *ngxPermissionsOnly="[eddress.userRolesEnum.COURIER, eddress.userRolesEnum.MARKETPLACE, eddress.userRolesEnum.MERCHANT, eddress.userRolesEnum.CLIENT_VIEW]">
                    <a href="" [routerLink]="[eddress.appURLEnum.PORTAL_SETTINGS]">
                  <span class="icon-holder">
                    <i class="ti-settings pdd-right-10"></i></span>
                      <span class="title">Setting</span>
                    </a>
                  </li>
                  <li>
                    <a *ngIf="(isLoggedIn | async)" (click)="logout()">
                      <i class="ti-power-off pdd-right-10"></i>
                      <span class="pointer">Logout</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <!-- Top Nav END -->

        <!-- Side Panel START -->
        <div class="side-panel" [ngClass]="{'side-panel-open' : app.layout.sidePanelOpen}">
          <div class="side-panel-wrapper bg-white">
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item active">
                <a class="nav-link" href="#tab1" role="tab" data-toggle="tab">
                  <span>Tab 1</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#tab2" role="tab" data-toggle="tab">
                  <span>Tab 2</span>
                </a>
              </li>
              <li class="nav-item ">
                <a class="nav-link" href="#tab3" role="tab" data-toggle="tab">
                  <span>Tab 3</span>
                </a>
              </li>
              <li class="panel-close">
                <a class="side-panel-toggle" href="javascript:void(0);"
                   (click)="app.layout.sidePanelOpen = !app.layout.sidePanelOpen">
                  <i class="ti-close"></i>
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <div id="tab1" role="tabpanel" class="tab-pane fade in active">
              </div>
              <div id="tab2" role="tabpanel" class="tab-pane fade">
              </div>
              <div id="tab3" role="tabpanel" class="tab-pane fade">
              </div>
            </div>
          </div>
        </div>
        <!-- Side Panel END -->

        <!-- Content Wrapper START -->
        <div class="main-content">
          <router-outlet></router-outlet>
        </div>
        <!-- Content Wrapper END -->

        <!-- Footer START -->
        <!--<footer class="content-footer">-->
        <!--<div class="footer">-->
        <!--<div class="copyright">-->
        <!--<span>Copyright © 2017</span>-->
        <!--<span class="go-right">-->
        <!--<a href="" class="text-gray mrg-right-15">Link 1</a>-->
        <!--<a  href="" class="text-gray">Link 2</a>-->
        <!--</span>-->
        <!--</div>-->
        <!--</div>-->
        <!--</footer>-->
        <!-- Footer END -->

      </div>
      <!-- Page Container END -->

    </div>
  </div>
</div>
